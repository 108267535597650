import ObjectApi from '../../api/ObjectApi';
import { LIBRARY_TYPE } from './type';

export const getDataIcons = () => {
  return async (dispatch) => {
    try {
      const response = await ObjectApi.getIconsLibrary();

      await dispatch({
        type: LIBRARY_TYPE.SET_DATA_ICONS,
        data: response,
      });
      await dispatch({
        type: LIBRARY_TYPE.SET_LOADING_LOAD_DATA_ICONS_LIBRARY,
        status: false,
      });
    } catch (error) {
      await dispatch({
        type: LIBRARY_TYPE.SET_LOADING_LOAD_DATA_ICONS_LIBRARY,
        status: false,
      });
      console.log('🚀 ~ file: action.js:13 ~ return ~ error:', error);
    }
  };
};

export const updateDataIcons = (data) => {
  return async (dispatch) => {
    dispatch({
      type: LIBRARY_TYPE.SET_DATA_ICONS,
      data,
    });
  };
};

export const setCurrentIconSelect = (icon) => {
  return async (dispatch) => {
    dispatch({
      type: LIBRARY_TYPE.SET_CURRENT_ICON_SELECT,
      icon,
    });
  };
};

export const changeStatusShowModalIconsLibrary = (status) => {
  return async (dispatch) => {
    dispatch({
      type: LIBRARY_TYPE.CHANGE_STATUS_SHOW_MODAL_ICONS,
      status,
    });
  };
};

export const changeSessionIconsLibrary = (data) => {
  return async (dispatch) => {
    dispatch({
      type: LIBRARY_TYPE.CHANGE_SESSION_ICONS_LIBRARY,
      data,
    });
  };
};

export const changeActionAddResourceIconsLibrary = (status) => {
  return async (dispatch) => {
    dispatch({
      type: LIBRARY_TYPE.SET_ACTION_ADD_RESOURCE,
      status,
    });
  };
};

export const setCallbackFuncIconsLibrary = (callback) => {
  return async (dispatch) => {
    dispatch({
      type: LIBRARY_TYPE.SET_CALLBACK_FUN_SELECT_ICON,
      callback,
    });
  };
};

export const changeModeLibrary = (mode) => {
  return async (dispatch) => {
    dispatch({
      type: LIBRARY_TYPE.CHANGE_MODE_LIBRARY_ICON,
      mode,
    });
  };
};
