import { Center, Space } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseCircle } from '../../constants/icon';
import {
  onTogglePreviewSvgSketch,
  openObjectDetails,
} from '../../reducers/layoutReducer/action';
import {
  isShowPreviewSvgSketchSelector,
  objDetailsOpenSelector,
  previewSvgSketchDataSelector,
} from '../../reducers/layoutReducer/layoutSelector';
import { currentPaletteActiveSelector } from '../../reducers/themeReducers/themeSelector';
import {
  ActivityLogsTitle,
  WrapButtonCloseView,
} from '../ActivityLog/ActivityLogCSS';
import ResizablePanel from '../Resizable';
import ErrorBoundary from '../UI/ErrorBoundary/ErrorBoundary';
import SVGPolylineRenderer from '../UI/Objects/ObjFieldDrawingPad/SVGPolylineRenderer';
import SvgArrowLineRenderer from '../UI/Objects/ObjFieldDrawingPad/SvgArrowLineRenderer';
import SvgRenderer from '../UI/Objects/ObjFieldDrawingPad/SvgRenderer';
import SvgTextRenderer from '../UI/Objects/ObjFieldDrawingPad/SvgTextRenderer';
import { IconButtonObjForm } from '../UI/Objects/ObjForm/ObjFormCSS';
import ObjectActionCreators from '../../actions';

function PreviewSvgSketch() {
  const currentPaletteActive = useSelector(currentPaletteActiveSelector);
  const isShowPreviewSvgSketch = useSelector(isShowPreviewSvgSketchSelector);
  const previewSvgSketchData = useSelector(previewSvgSketchDataSelector);
  const objDetailsOpen = useSelector(objDetailsOpenSelector);
  const [ref, rect] = useResizeObserver();

  const dispatch = useDispatch();
  const handleCloseModalPreviewSketch = () => {
    dispatch(onTogglePreviewSvgSketch(false));
  };

  const scale = (rect.width - 40) / 800;

  const handleOnClickElement = (objId) => {
    if (objId) {
      dispatch(ObjectActionCreators.selectObjectAndStack(objId));
      onOpenObjectDetails();
    }
  };
  const onOpenObjectDetails = () => {
    if (objDetailsOpen) return;
    dispatch(openObjectDetails());
  };
  return (
    <ResizablePanel
      resizeType="activity_log_resize"
      width={850}
      id="activity_log_resize"
      isOpen={isShowPreviewSvgSketch}
      innerRef={ref}
    >
      <ErrorBoundary>
        <div
          style={{
            width: '100',
            height: '100%',
          }}
        >
          <div>
            <ActivityLogsTitle color={currentPaletteActive?.text?.highlight}>
              {/* {t('app.activity_log')} */}
              Preview sketch
            </ActivityLogsTitle>
            <WrapButtonCloseView>
              <IconButtonObjForm
                onClick={handleCloseModalPreviewSketch}
                onTouchEnd={handleCloseModalPreviewSketch}
              >
                <CloseCircle fill={currentPaletteActive?.text?.highlight} />
              </IconButtonObjForm>
            </WrapButtonCloseView>
          </div>
          <Space h={10} />
          <div
            style={{
              maxHeight: 'calc(100vh - 100px)',
              overflow: 'scroll',
              paddingBottom: 10,
            }}
          >
            <Center style={{ paddingRight: 40, height: 700 * scale }}>
              <div
                draggable={false}
                id="wrapSvgPadPreviewSketch"
                style={{
                  width: 800,
                  height: 640,
                  backgroundColor: 'white',
                  border: '0.5px solid #94A3B8',
                  borderRadius: 4,
                  transform: `scale(${scale})`,
                  overflow: 'hidden',
                }}
              >
                <svg draggable={false} width={'800'} height={'640'}>
                  {previewSvgSketchData?.map((lineSvg) => {
                    const onOpenObjForm = () => {
                      handleOnClickElement(lineSvg?.link);
                    };
                    if (lineSvg.type === 'svg' || lineSvg.type === 'image') {
                      return (
                        <SvgRenderer
                          prefix_key="preview_mode"
                          svg={lineSvg}
                          key={lineSvg.key}
                          isDisableAction={true}
                          onClickElement={onOpenObjForm}
                        />
                      );
                    }
                    if (lineSvg.type === 'text') {
                      return (
                        <SvgTextRenderer
                          prefix_key="preview_mode"
                          svg={lineSvg}
                          key={lineSvg.key}
                          isDisableAction={true}
                          onClickElement={onOpenObjForm}
                        />
                      );
                    }
                    if (lineSvg.type === 'arrow-line') {
                      return (
                        <SvgArrowLineRenderer
                          prefix_key="preview_mode"
                          key={lineSvg.key}
                          lineData={lineSvg}
                          isDisableAction={true}
                          onClickElement={onOpenObjForm}
                        />
                      );
                    }

                    if (
                      lineSvg.type === 'polyline' ||
                      lineSvg.type === 'line'
                    ) {
                      return (
                        <SVGPolylineRenderer
                          prefix_key="preview_mode"
                          key={lineSvg.key}
                          lineData={lineSvg}
                          isDisableAction={true}
                          onClickElement={onOpenObjForm}
                        />
                      );
                    }
                  })}
                </svg>
              </div>
            </Center>
          </div>
        </div>
      </ErrorBoundary>
    </ResizablePanel>
  );
}

export default memo(PreviewSvgSketch);
