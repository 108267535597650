import React from 'react';
import { ObjFieldDrawingPadContent } from './ObjFieldDrawingPadCSS';
import SvgRenderer from './SvgRenderer';
import SvgTextRenderer from './SvgTextRenderer';
import SvgArrowLineRenderer from './SvgArrowLineRenderer';
import SVGPolylineRenderer from './SVGPolylineRenderer';

export default function SvgPreviewContent({ lines, onClickElement }) {
  return (
    <ObjFieldDrawingPadContent>
      <svg
        id="svgRes"
        draggable={false}
        width={160}
        height={130}
        viewBox="0 0 790 640"
      >
        {lines.map((lineSvg) => {
          if (lineSvg?.type === 'svg' || lineSvg?.type === 'image') {
            return (
              <SvgRenderer
                svg={lineSvg}
                key={`preview_${lineSvg.key}`}
                isDisableAction={true}
                prefix_key={'preview_'}
                onClickElement={onClickElement}
              />
            );
          }
          if (lineSvg?.type === 'text') {
            return (
              <SvgTextRenderer
                svg={lineSvg}
                key={`preview_${lineSvg.key}`}
                isDisableAction={true}
                prefix_key={'preview_'}
                onClickElement={onClickElement}
              />
            );
          }
          if (['bidirectional-line', 'arrow-line'].includes(lineSvg?.type)) {
            return (
              <SvgArrowLineRenderer
                lineData={lineSvg}
                key={`preview_${lineSvg.key}`}
                isDisableAction={true}
                prefix_key={'preview_'}
              />
            );
          }
          if (lineSvg?.type === 'polyline' || lineSvg?.type === 'line') {
            return (
              <SVGPolylineRenderer
                lineData={lineSvg}
                key={`preview_${lineSvg.key}`}
                isDisableAction={true}
                prefix_key={'preview_'}
              />
            );
          }
        })}
      </svg>
    </ObjFieldDrawingPadContent>
  );
}
