export const RESPONSIVE_TYPE = {
  SET_DEVICE: 'SET_DEVICE',
  SET_SHOW_NODE_CONTENT: 'SET_SHOW_NODE_CONTENT',
  SET_SCROLL_HORIZONTAL: 'SET_SCROLL_HORIZONTAL',
  SET_SCROLL_DIRECTION: 'SET_SCROLL_DIRECTION',
  SET_NODE_FOCUS: 'SET_NODE_FOCUS',
  SET_CURRENT_COLUMN: 'SET_CURRENT_COLUMN',
  SET_DRAG_TABLET: 'SET_DRAG_TABLET',
  SET_CLASS_BAND_BREAK_POINT_TABLET: 'SET_CLASS_BAND_BREAK_POINT_TABLET',
  SET_POSITION_DRAG_TABLET: 'SET_POSITION_DRAG_TABLET',
  SET_CURRENT_BAND_ACTIVE: 'SET_CURRENT_BAND_ACTIVE',
  SET_OLD_POSITION_NODE_TABLET: 'SET_OLD_POSITION_NODE_TABLET',
  SET_PARENT_POSITION_NODE_TABLET: 'SET_PARENT_POSITION_NODE_TABLET',

  SET_LEVEL_NODE_AND_BAND_ACTIVE_TABLET:
    'SET_LEVEL_NODE_AND_BAND_ACTIVE_TABLET',
  SET_IS_RESIZE_PANE_TABLET: 'SET_IS_RESIZE_PANE_TABLET',
  SET_NODE_DRAG_TABLET: 'SET_NODE_DRAG_TABLET',
};
