const widthScreen =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const heightScreen =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export { widthScreen, heightScreen };
