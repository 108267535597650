import { useCallback, useEffect, useRef, useState } from 'react';
import { makeDraggable } from '../../../../utils/DragDropTouchTablet';
import {
  ObjFieldDrawingPadImageStyles,
  ObjFieldDrawingPadSvgStyles,
} from './ObjFieldDrawingPadCSS';
import { getHost } from '../../../../helper/getHost';

const ipServer = `https://${getHost()}`;
console.log('ipServer', ipServer);
const SvgRenderer = ({
  svg,
  setActionDragSvg,
  keySvgSelect,
  setSvgFocus,
  handleSaveSvgFileToDb,
  isDisableAction = false,
  prefix_key,
  action,
  onDeleteSvgWithId,
  sizeGrid,
  isShowGrids,
  onClickElement,
  idLineDrawing,
}) => {
  const [position, setPosition] = useState({
    x: svg.x,
    y: svg.y,
  });

  const renderSvgMarkup = () => {
    return { __html: replaceWidthHeight(svg?.points) }; // Convert SVG string to HTML
  };

  const centerSvg = { width: svg.width / 2, height: svg.height / 2 };

  const handleSelectSvg = useCallback(() => {
    if (idLineDrawing) return;
    if (isDisableAction) {
      if (svg?.link && typeof onClickElement === 'function')
        return onClickElement(svg.link);
    }
    if (action === 'delete') {
      return onDeleteSvgWithId(svg?.key);
    }
    if (typeof setSvgFocus === 'function') setSvgFocus(svg?.key);
  }, [
    svg?.key,
    svg?.link,
    keySvgSelect,
    isDisableAction,
    action,
    onDeleteSvgWithId,
    onClickElement,
    idLineDrawing,
  ]);

  const onMakeDraggable = (id) => {
    makeDraggable(
      document.getElementById(id),
      (e) => {
        if (action === 'delete') return;
        setActionDragSvg('move');
      },
      (position) => {
        // drag end
        const svgBox = document
          .getElementById('svgPad')
          ?.getBoundingClientRect();

        handleSaveSvgFileToDb(
          svg?.key,
          calculateSnapPosition(
            position,
            svgBox,
            centerSvg,
            sizeGrid,
            isShowGrids
          )
        );
      },
      (position) => {
        const svgBox = document
          .getElementById('svgPad')
          ?.getBoundingClientRect();

        // drag move

        setPosition(
          calculateSnapPosition(
            position,
            svgBox,
            centerSvg,
            sizeGrid,
            isShowGrids
          )
        );
      },
      (e) => {},
      15
    );
  };

  useEffect(() => {
    if (isDisableAction) return;
    if (svg?.key === keySvgSelect) {
      onMakeDraggable(`wrap-${svg?.key}`);
    }
  });

  return (
    <svg>
      {svg?.type === 'svg' && (
        <ObjFieldDrawingPadSvgStyles
          link={svg?.link}
          action={action}
          isDisableAction={isDisableAction}
          onClick={handleSelectSvg}
          id={prefix_key ? `${prefix_key + svg?.key}` : svg?.key}
          draggable={true}
          x={isDisableAction ? svg?.x : position.x}
          y={isDisableAction ? svg?.y : position.y}
          width={svg?.width}
          height={svg?.height}
          viewBox="0 0 100 100"
          dangerouslySetInnerHTML={renderSvgMarkup()}
        />
      )}
      {svg?.type === 'image' && (
        <ObjFieldDrawingPadImageStyles
          link={svg?.link}
          action={action}
          isDisableAction={isDisableAction}
          onClick={handleSelectSvg}
          id={prefix_key ? `${prefix_key + svg?.key}` : svg?.key}
          draggable={true}
          x={isDisableAction ? svg?.x : position.x}
          y={isDisableAction ? svg?.y : position.y}
          width={svg?.width}
          height={svg?.height}
          viewBox="0 0 100 100"
          dangerouslySetInnerHTML={renderSvgMarkup()}
          xlinkHref={`${ipServer + svg?.points}`}
        />
      )}

      {svg?.key === keySvgSelect && (
        <rect
          // data-html2canvas-ignore="true"
          className="data-html-to-image-ignore"
          style={{ cursor: 'pointer' }}
          id={`wrap-${svg?.key}`}
          x={position.x - 5}
          y={position.y - 5}
          width={svg?.width + 10}
          height={svg?.height + 10}
          fill="transparent"
          stroke="#D8D8D8"
          strokeWidth="4"
          strokeDasharray="8 4"
        />
      )}
    </svg>
  );
};
const replaceWidthHeight = (svgStr) => {
  const updatedSvgStr = svgStr.replace(/width=".*?"/, `width="100%"`);
  return updatedSvgStr.replace(/height=".*?"/, `height="100%"`);
};

export default SvgRenderer;

const calculateSnapPosition = (
  position,
  svgBox,
  centerSvg,
  sizeGrid,
  isShowGrids
) => {
  const x = position.x - svgBox?.x - centerSvg.width;
  const y = position.y - svgBox?.y - centerSvg.height;
  if (!isShowGrids) {
    return { x, y };
  }
  const xSnap = Math.round(x / sizeGrid) * sizeGrid;
  const ySnap = Math.round(y / sizeGrid) * sizeGrid;

  const positionSvg = {
    x: xSnap + 5,
    y: ySnap + 5,
  };
  return positionSvg;
};
