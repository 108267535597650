import { Button } from '@mantine/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { currentPaletteActiveSelector } from '../../reducers/themeReducers/themeSelector';

export default function ButtonIcon({
  onClick,
  leftIcon,
  label,
  isActive,
  disabled = false,
}) {
  const currentPaletteActive = useSelector(currentPaletteActiveSelector);
  const primaryButton = currentPaletteActive?.button?.primaryButton;
  const textPalette = currentPaletteActive?.text;

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      leftIcon={leftIcon || null}
      fullWidth
      styles={{
        ...buttonStyle,
        root: {
          ...buttonStyle.root,
          '&:hover': {
            backgroundColor: primaryButton?.backgroundColorHover,
          },
          backgroundColor: isActive
            ? primaryButton?.backgroundColorActive
            : primaryButton?.backgroundColor,
          '&:disabled': {
            backgroundColor: `${primaryButton?.backgroundColor} !important`,
          },
        },
        label: {
          color: textPalette?.secondary,
          fontWeight: 500,
          left: 36,
          position: 'absolute',
        },
      }}
    >
      {label}
    </Button>
  );
}

const buttonStyle = {
  root: {
    border: 0,
    height: 36,
    color: '#313131',
    '&:focus': {
      outline: 0,
    },
  },

  leftIcon: {
    left: 0,
    position: 'absolute',
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
