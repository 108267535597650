import { Group, Loader, useMantineTheme } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Album, Upload, X } from 'tabler-icons-react';
import ObjectApi from '../../../../api/ObjectApi';
import { GalleryIcon } from '../../../../constants/icon';

export default function DropFileObjDrawingPad({
  onAddFile,
  containerStyle,
  dropZoneStyle,
}) {
  const openRef = useRef();
  const [file, setFile] = useState(null);
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);

  const theme = useMantineTheme();
  const objId = useSelector((state) => state.objects.objId);
  const handleReadFile = async (e) => {
    const _file = file[0];
    if (_file?.type === MIME_TYPES.svg) {
      const reader = new FileReader();

      reader.readAsText(_file);
      reader.onload = (event) => {
        const content = event.target.result;
        onAddFile('svg', content);
      };
      return;
    }
    if ([MIME_TYPES.png, MIME_TYPES.jpeg].includes(_file?.type)) {
      try {
        setIsLoadingUploadFile(true);
        const keyImage = `illustration_file${new Date().getTime()}`;
        const res = await ObjectApi.postFile(
          objId,
          _file,
          _file.name,
          keyImage
        );

        onAddFile('image', res?.filepath, keyImage);
        setIsLoadingUploadFile(false);
      } catch (error) {
        setIsLoadingUploadFile(false);
      }
    }
  };
  useEffect(() => {
    if (file) handleReadFile();
  }, [file]);
  return (
    <div
      style={{
        width: '100%',
        height: 70,
        borderRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 12,
        ...containerStyle,
      }}
      id="drop-file-pad"
    >
      <Dropzone
        disabled={isLoadingUploadFile}
        onDrop={setFile}
        accept={[MIME_TYPES.svg, MIME_TYPES.png, MIME_TYPES.jpeg]}
        openRef={openRef}
        styles={{
          root: {
            border: '1px dashed #CBD5E1',
            backgroundColor: 'transparent',
            maxHeight: 70,
            maxWidth: 70,
            width: '100%',
            padding: 0,
            ':hover': {
              // backgroundColor: 'transparent',
              cursor: isLoadingUploadFile ? 'no-drop' : 'pointer',
              ':hover': {
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.blue_gray[8]
                    : theme.colors.blue_gray[2],
              },
            },
            ...dropZoneStyle,
          },
        }}
      >
        {(status) =>
          dropzoneChildren(status, theme, isLoadingUploadFile, dropZoneStyle)
        }
      </Dropzone>
    </div>
  );
}

export function getIconColor(status, theme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]
    : status.rejected
    ? theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
    : theme.colorScheme === 'dark'
    ? theme.colors.dark[0]
    : theme.colors.gray[7];
}
export const dropzoneChildren = (
  status,
  theme,
  isLoadingUploadFile,
  dropZoneStyle
) => (
  <Group
    position="center"
    spacing="xl"
    style={{
      minHeight: 70,
      pointerEvents: 'none',
      maxHeight: 70,
      maxWidth: 70,
      ...dropZoneStyle,
    }}
  >
    {isLoadingUploadFile ? (
      <Loader size="lg" variant="dots" />
    ) : (
      <ImageUploadIcon
        status={status}
        style={{ color: getIconColor(status, theme) }}
        size={32}
      />
    )}
  </Group>
);
function ImageUploadIcon({ status, ...props }) {
  if (status.accepted) {
    return <Upload {...props} />;
  }

  if (status.rejected) {
    return <X {...props} />;
  }

  return <GalleryIcon {...props} />;
}
