import {
  ActionIcon,
  SegmentedControl,
  Space,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ipServer } from '../../api/ObjectApi';
import { FolderIcon } from '../../constants/icon';
import {
  iconsLibraryAvailableSelector,
  isLoadingDataLibrarySelector,
  sessionModalLibrarySelector,
} from '../../reducers/iconsLibraryReducer/iconsLibrarySelector';
import DotLoader from '../DotLoader/DotLoader';
import {
  CategoriesLibraryCategory,
  CategoriesLibraryIconsContainer,
  WrapButtonCategory,
} from './ModalLIbraryIconCss';

export default function CategoriesLibraryIcons({
  currentSession,
  currentCategory,
  setCurrentSession,
  onChangeCategory,
}) {
  const theme = useMantineTheme();
  const iconsLibraryAvailable = useSelector(iconsLibraryAvailableSelector);
  const dataSessionLibraryIcons = useSelector(sessionModalLibrarySelector);
  const dataSessionLibraryIconsEditPicker = dataSessionLibraryIcons.filter(
    (category) => category.value !== 'svg'
  );

  const isLoadingDataLibrary = useSelector(isLoadingDataLibrarySelector);
  const firstRender = useRef(false);

  const categoriesBySession = useMemo(() => {
    if (isEmpty(iconsLibraryAvailable)) return null;
    const categories = Object.entries(
      iconsLibraryAvailable[currentSession].categories
    ).map(([key, value]) => {
      return { ...value, parentId: key };
    });

    return categories;
  }, [currentSession, iconsLibraryAvailable]);

  useEffect(() => {
    if (firstRender.current === false && categoriesBySession?.length > 0) {
      onChangeCategory(categoriesBySession[0]);
      firstRender.current = true;
    }
  }, [categoriesBySession]);

  const handleChangeSession = (session) => {
    const categories = Object.entries(
      iconsLibraryAvailable[session].categories
    ).map(([key, value]) => {
      return { ...value, parentId: key };
    });

    if (categories?.length > 0) {
      onChangeCategory(categories[0]);
    } else {
      onChangeCategory(null);
    }
    setCurrentSession(session);
  };

  return (
    <>
      <CategoriesLibraryIconsContainer
        background={
          theme.colorScheme === 'dark'
            ? theme.colors.cool_gray[8]
            : theme.colors.blue_gray[0]
        }
        border={`1px solid ${
          theme.colorScheme === 'dark'
            ? theme.colors.gray[6]
            : theme.colors.blue_gray[2]
        }`}
      >
        {isLoadingDataLibrary ? (
          <DotLoader noSpacing={true} />
        ) : (
          <>
            <SegmentedControl
              disabled={isLoadingDataLibrary}
              value={currentSession}
              onChange={handleChangeSession}
              styles={{
                root: {
                  width: '100%',
                },
              }}
              data={dataSessionLibraryIconsEditPicker}
            />

            <CategoriesLibraryCategory>
              <Space h="8px" />
              {categoriesBySession?.map((category) => {
                return (
                  <ButtonCategory
                    key={category?.id}
                    category={category}
                    currentCategory={currentCategory}
                    onClick={onChangeCategory}
                  />
                );
              })}
            </CategoriesLibraryCategory>
          </>
        )}
      </CategoriesLibraryIconsContainer>
    </>
  );
}
// type = 'add_category';

export const ButtonCategory = ({
  category,
  currentCategory,
  onClick,
  children,
}) => {
  const theme = useMantineTheme();

  const handleClick = () => {
    onClick(category);
  };

  return (
    <div
      style={{
        paddingLeft: category && category?.id === currentCategory?.id ? 12 : 0,
      }}
    >
      <WrapButtonCategory
        backgroundColor={
          category && category?.id === currentCategory?.id
            ? theme.colorScheme === 'dark'
              ? theme.colors.blue_gray[9]
              : theme.colors.blue_gray[2]
            : ''
        }
        backgroundColorHover={
          theme.colorScheme === 'dark'
            ? theme.colors.cool_gray[7]
            : theme.colors.blue_gray[1]
        }
        borderColor={
          theme.colorScheme === 'dark'
            ? theme.colors.blue_gray[6]
            : theme.colors.cool_gray[3]
        }
      >
        <ActionIcon
          onClick={handleClick}
          styles={{
            root: {
              width: '100%',
              ':hover': {
                backgroundColor: 'transparent',
              },
              justifyContent: 'flex-start',
            },
          }}
        >
          {children || (
            <>
              {category?.icon ? (
                <ImageIcon
                  radius="md"
                  src={`${ipServer + category?.icon}`}
                  alt=""
                />
              ) : (
                <FolderIcon size={20} />
              )}
              <Space w={4} />
              <Text size="md" weight={500}>
                {category?.name}
              </Text>
            </>
          )}
        </ActionIcon>
      </WrapButtonCategory>
    </div>
  );
};

const ImageIcon = styled.img({ width: 30, height: 30, borderRadius: 4 });
