import produce from 'immer';
import { RESPONSIVE_TYPE } from './type';

let res;

const responsiveReducer = (
  state = {
    isShowNodeContent: false,
    isMobile: false,
    isTablet: false,
    isScrollHorizontal: false,
    direction: '',
    nodeFocus: {},
    currentColumn: 1,
    isDragTablet: false,
    arrayClassifTablet: {},
    positionNodeDrag: { x: null, y: null },
    oldPositionNodeDrag: { x: null, y: null },
    parentNodeDragId: null,
    idOldNode: null,
    currentBandActive: null,
    levelNodeAndBandActive: {
      nodeLevel: null,
      bandLevel: null,
    },
    isResizePane: false,
    nodeDrag: null,
  },
  action
) => {
  switch (action.type) {
    case RESPONSIVE_TYPE.SET_PARENT_POSITION_NODE_TABLET:
      return produce(state, (draft) => {
        draft.parentNodeDragId = action.id;
      });
    case RESPONSIVE_TYPE.SET_NODE_DRAG_TABLET:
      return produce(state, (draft) => {
        draft.nodeDrag = action.node;
      });
    case RESPONSIVE_TYPE.SET_IS_RESIZE_PANE_TABLET:
      return produce(state, (draft) => {
        draft.isResizePane = action.status;
      });
    case RESPONSIVE_TYPE.SET_LEVEL_NODE_AND_BAND_ACTIVE_TABLET:
      return produce(state, (draft) => {
        draft.levelNodeAndBandActive.bandLevel =
          typeof action?.bandLevel !== 'undefined'
            ? action?.bandLevel
            : draft.levelNodeAndBandActive.bandLevel;

        draft.levelNodeAndBandActive.nodeLevel =
          typeof action?.nodeLevel !== 'undefined'
            ? action?.nodeLevel
            : draft.levelNodeAndBandActive.nodeLevel;
      });
    case RESPONSIVE_TYPE.SET_CURRENT_BAND_ACTIVE:
      return produce(state, (draft) => {
        draft.currentBandActive = action.status;
      });
    case RESPONSIVE_TYPE.SET_DRAG_TABLET:
      return produce(state, (draft) => {
        draft.isDragTablet = action.status;
      });
    case RESPONSIVE_TYPE.SET_POSITION_DRAG_TABLET:
      return produce(state, (draft) => {
        draft.positionNodeDrag = action.position;
      });
    case RESPONSIVE_TYPE.SET_OLD_POSITION_NODE_TABLET:
      return produce(state, (draft) => {
        draft.oldPositionNodeDrag = action.position;
        draft.idOldNode = action.nodeId;
      });
    case RESPONSIVE_TYPE.SET_CLASS_BAND_BREAK_POINT_TABLET:
      return produce(state, (draft) => {
        draft.arrayClassifTablet[action.index] = action.item;
      });
    case RESPONSIVE_TYPE.SET_DEVICE:
      return produce(state, (draft) => {
        draft[action.device] = action.status;
      });
    case RESPONSIVE_TYPE.SET_SCROLL_DIRECTION:
      return produce(state, (draft) => {
        draft.direction = action.status;
      });
    case RESPONSIVE_TYPE.SET_SHOW_NODE_CONTENT:
      return produce(state, (draft) => {
        draft.isShowNodeContent = action.status;
      });
    case RESPONSIVE_TYPE.SET_SCROLL_HORIZONTAL:
      return produce(state, (draft) => {
        draft.isScrollHorizontal = action.status;
      });
    case RESPONSIVE_TYPE.SET_NODE_FOCUS:
      return produce(state, (draft) => {
        draft.nodeFocus[action.level] = action.node;
      });

    case RESPONSIVE_TYPE.SET_CURRENT_COLUMN:
      return produce(state, (draft) => {
        draft.currentColumn = action.index;
      });

    default:
      return state;
  }
};
export default responsiveReducer;
