import {
  ActionIcon,
  Center,
  SimpleGrid,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { cloneDeep, isArray } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidV4 } from 'uuid';
import ObjectApi, { ipServer } from '../../api/ObjectApi';
import { EmptyDataIcon, SpinnerIcon } from '../../constants/icon';
import {
  setCallbackFuncIconsLibrary,
  setCurrentIconSelect,
  updateDataIcons,
} from '../../reducers/iconsLibraryReducer/action';
import {
  actionAddResourceLibrarySelector,
  callbackLibrarySelector,
  currentIconSelectLibrarySelector,
  iconsLibrarySelector,
  modeIconLibrarySelector,
} from '../../reducers/iconsLibraryReducer/iconsLibrarySelector';
import { MODE_LIBRARY_ICON } from '../../reducers/iconsLibraryReducer/type';
import { currentPaletteActiveSelector } from '../../reducers/themeReducers/themeSelector';
import { heightScreen } from '../../utils/windowSize';
import { WrapLoadingIcon } from '../ActivityLog/ActivityLogCSS';
import ModalAddResource from './ModalAddResource';

export default function ListIcons({
  data,
  fetchMoreData,
  hasMore,
  refList,
  currentCategory,
  currentSession,
  updateItemIcons,
}) {
  const heightContainerIcons = (heightScreen / 100) * 70;
  const currentPaletteActive = useSelector(currentPaletteActiveSelector);
  const theme = useMantineTheme();
  const iconsLibrary = useSelector(iconsLibrarySelector);
  const actionAddResource = useSelector(actionAddResourceLibrarySelector);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onAddIcons = async (data, callback) => {
    try {
      const newData = cloneDeep(iconsLibrary);

      const objIconUpdate = {};
      if (isArray(data?.urlsImage)) {
        data?.urlsImage?.map((url) => {
          const keyIcon = uuidV4();
          const newIcon = {
            name: url?.name,
            icon: url?.filepath,
            tags: url?.tags,
            file: url?.filepath,
          };
          objIconUpdate[keyIcon] = newIcon;

          newData[currentSession].categories[currentCategory.parentId].icons[
            keyIcon
          ] = newIcon;
        });
      }
      // delete newData.svg;

      await ObjectApi.saveLibrary(JSON.stringify(newData));

      setTimeout(() => {
        dispatch(updateDataIcons(newData));
        updateItemIcons(objIconUpdate);
        if (typeof callback === 'function') callback();
      }, 250);
    } catch (error) {
      console.log('🚀 ~ file: ListIcons.js:66 ~ onAddIcons ~ error:', error);
    }
  };

  const onChooseIcon = useCallback(
    (item) => {
      dispatch(
        setCurrentIconSelect({ ...item, currentSession, currentCategory })
      );
    },
    [currentSession, currentCategory]
  );
  return (
    <div
      id="library-container-icons"
      style={{
        border: `1px solid ${
          theme.colorScheme === 'dark'
            ? theme.colors.gray[7]
            : theme.colors.gray[2]
        }`,
        height: '100%',
        position: 'relative',
        width: 750,
        maxWidth: 750,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeftWidth: 0,
        minWidth: 750,
        overflow: 'hidden',
      }}
    >
      <InfiniteScroll
        scrollbarSize={8}
        offsetScrollbars={1}
        dataLength={Object.entries(data).length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <WrapLoadingIcon>
            <SpinnerIcon
              fill={{
                borderColor: currentPaletteActive.text.primary,
                borderTopColor: currentPaletteActive.text.primary1,
              }}
            />
          </WrapLoadingIcon>
        }
        height={heightContainerIcons - 50}
        endMessage={null}
      >
        <div ref={refList} />

        <SimpleGrid spacing="xs" style={{ margin: 0 }} cols={6}>
          {currentCategory && actionAddResource && currentSession !== 'svg' && (
            <ModalAddResource
              multiple={true}
              onSave={onAddIcons}
              type={'add_icon'}
            />
          )}
          {(!currentCategory || Object.entries(data).length === 0) && (
            <div
              style={{
                position: 'absolute',
                left: 'calc(50% - 150px)',
                top: 'calc(50% - 200px)',
              }}
            >
              <Center
                style={{ width: 300, height: 300, flexDirection: 'column' }}
              >
                <Text size="xl" weight={500}>
                  {t('empty_data')}
                </Text>
                <EmptyDataIcon />
              </Center>
            </div>
          )}
          {Object.entries(data).map(([key, itemIcon], index) => {
            return (
              <div
                key={`${index}_${itemIcon?.name}`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 6,
                  width: 112,
                  height: 112,
                  maxWidth: 112,
                  maxHeight: 112,
                }}
                span={1}
              >
                <RenderIcon
                  onChooseIcon={onChooseIcon}
                  key={itemIcon?.name}
                  item={{ ...itemIcon, key, currentSession }}
                />
              </div>
            );
          })}
        </SimpleGrid>
      </InfiniteScroll>
      {/* )} */}
    </div>
  );
}

const RenderIcon = ({ item, onChooseIcon }) => {
  const modeIconLibrary = useSelector(modeIconLibrarySelector);
  const dispatch = useDispatch();
  const theme = useMantineTheme();
  const currentIconSelectLibrary = useSelector(
    currentIconSelectLibrarySelector
  );
  const callBack = useSelector(callbackLibrarySelector);
  const onselectIcon = useCallback(() => {
    if (modeIconLibrary === MODE_LIBRARY_ICON.SELECT_ICON) {
      return callBack(item);
    }
    if (typeof callBack === 'function') {
      dispatch(setCallbackFuncIconsLibrary(null));
      callBack(item);
      return;
    }
    onChooseIcon(item);
  }, [item, callBack]);
  return (
    <ActionIcon
      onClick={onselectIcon}
      id={item?.key}
      style={{ width: '100%', height: '100%', padding: 2 }}
      styles={{
        hover: {
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'light'
                ? theme.colors.cool_gray[2]
                : theme.colors.cool_gray[6],
          },
        },
        root: {
          backgroundColor:
            theme.colorScheme === 'light'
              ? theme.colors.cool_gray[1]
              : theme.colors.cool_gray[8],
          border:
            item?.key === currentIconSelectLibrary?.key
              ? `1.5px solid ${theme.colors.cool_gray[3]}`
              : '',
        },
      }}
    >
      <div
        style={{
          padding: 4,
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          borderRadius: 2,
        }}
      >
        <ImageIcon
          style={{ backgroundImage: `url('${`${ipServer + item?.icon}`}')` }}
          radius="md"
          alt="Random unsplash image"
        />
      </div>
    </ActionIcon>
  );
};

export const ImageIcon = styled.div({
  width: '100%',
  height: '100%',
  objectFit: 'fill',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  borderRadius: 4,
  backgroundPosition: 'center',
  backgroundColor: 'white',
});
