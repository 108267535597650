import { isTablet } from '../../utils/detectDevices';
import {
  currentBandActiveSelector,
  isDragTabletSelector,
  isMobileSelector,
  levelNodeAndBandActiveSelector,
  parentNodeDragIdSelector,
} from './responsiveSelector';
import { RESPONSIVE_TYPE } from './type';

export const onSetDevice = ({ device, status }) => {
  return (dispatch) => {
    dispatch({
      type: RESPONSIVE_TYPE.SET_DEVICE,
      device,
      status,
    });
  };
};
export const onSetShowNodeContent = (status) => {
  return (dispatch, getState) => {
    const isMobile = isMobileSelector(getState());
    if (!isMobile) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_SHOW_NODE_CONTENT,
      status,
    });
  };
};
export const onSetHorizontalContentNode = (status) => {
  return (dispatch, getState) => {
    const isMobile = isMobileSelector(getState());

    if (!isMobile) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_SCROLL_HORIZONTAL,
      status,
    });
  };
};

export const onSetDirectionContentNode = (status) => {
  return (dispatch, getState) => {
    const isMobile = isMobileSelector(getState());
    if (!isMobile) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_SCROLL_DIRECTION,
      status,
    });
  };
};

export const onSetNodeNodeFocus = (level, node) => {
  return (dispatch, getState) => {
    if (isTablet && level === 'perspective') {
      return dispatch({
        type: RESPONSIVE_TYPE.SET_NODE_FOCUS,
        node,
        level: 'perspective',
      });
    }
    const isMobile = isMobileSelector(getState());
    if (!isMobile) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_NODE_FOCUS,
      node,
      level,
    });
  };
};

export const onSeCurrentColumn = (index) => {
  return (dispatch, getState) => {
    const isMobile = isMobileSelector(getState());
    if (!isMobile) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_CURRENT_COLUMN,
      index,
    });
  };
};
export const onSetDragTablet = (status) => {
  return (dispatch, getState) => {
    const isDragTablet = isDragTabletSelector(getState());

    if (isDragTablet === status || !isTablet) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_DRAG_TABLET,
      status,
    });
  };
};

export const onSetClassifTablet = (index, item) => {
  return (dispatch) => {
    if (!isTablet) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_CLASS_BAND_BREAK_POINT_TABLET,
      index,
      item,
    });
  };
};

export const onSetPositionDragNodeTablet = (position) => {
  return (dispatch) => {
    if (!isTablet) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_POSITION_DRAG_TABLET,
      position,
    });
  };
};

export const onSetOldPositionDragNodeTablet = (position, nodeId) => {
  return (dispatch) => {
    if (!isTablet) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_OLD_POSITION_NODE_TABLET,
      position,
      nodeId,
    });
  };
};

export const onSetCurrentBandTablet = (status) => {
  return (dispatch, getState) => {
    const currentBandActive = currentBandActiveSelector(getState());

    if (!isTablet || currentBandActive === status) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_CURRENT_BAND_ACTIVE,
      status,
    });
  };
};

export const onSetLevelNodeNadBandTablet = ({ bandLevel, nodeLevel }) => {
  return (dispatch, getState) => {
    const currentLevelNodeAnd = levelNodeAndBandActiveSelector(getState());

    if (
      !isTablet ||
      (currentLevelNodeAnd?.nodeLevel === nodeLevel &&
        currentLevelNodeAnd?.bandLevel === bandLevel)
    )
      return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_LEVEL_NODE_AND_BAND_ACTIVE_TABLET,
      bandLevel,
      nodeLevel,
    });
  };
};

export const onResizePaneTablet = (status) => {
  return (dispatch) => {
    if (!isTablet) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_IS_RESIZE_PANE_TABLET,
      status,
    });
  };
};

export const onSetNodeDragTablet = (node) => {
  return (dispatch) => {
    if (!isTablet) return;
    dispatch({
      type: RESPONSIVE_TYPE.SET_NODE_DRAG_TABLET,
      node,
    });
  };
};

export const onSetParentIdDragNodeTablet = (id) => {
  return (dispatch, getState) => {
    const parentNodeDragId = parentNodeDragIdSelector(getState());

    if (!isTablet || parentNodeDragId === id) return;

    dispatch({
      type: RESPONSIVE_TYPE.SET_PARENT_POSITION_NODE_TABLET,
      id,
    });
  };
};
