import styled from 'styled-components';
import { devices } from '../../constants/devices';

//-------------------------------------------------------------------------------------------------------
export const StyledResizePanel = styled.div({
  backgroundColor: (props) => props?.backgroundColor || 'white',
  border: (props) => `1px solid ${props?.borderColor || 'white'}`,
  borderRadius: '7px 0px 0px 7px',
  boxShadow: '-2px 2px 4px  #333',
  display: 'inline-block',
  height: (props) => (props.phoneModel ? '100%' : 'calc(100% - 29px)'),
  opacity: (props) => (props.isOpen ? 1 : 0),
  overflow: 'hidden',
  position: 'absolute',
  right: (props) => props.right || '5px',
  top: (props) => (props.phoneModel ? '-10px' : '16px'),
  transition: 'width 0.3s, opacity 0.3s',
  width: (props) => (props.isOpen ? `${props.width}px` : 0),
  zIndex: '11',
  [devices.tablet]: {
    width: (props) => (props.isOpen ? '70%' : 0),
    right: (props) => props.right || '0px',
  },
  [devices.mobileL]: {
    width: (props) => (props.isOpen ? '90%' : 0),
  },
});

export const stylesResizable = {
  resizeContentContainer: {
    backgroundColor: '',
    display: 'inline-block',
    position: 'absolute',
    minHeight: '100%',
    top: 0,
    width: '100%',
    minWidth: '10%',
  },
};
