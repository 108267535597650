import { Auth0Provider } from '@auth0/auth0-react';
import { MantineProvider } from '@mantine/core';
import i18next from 'i18next';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { Provider, useSelector } from 'react-redux';
import { NavigContainer } from './app';
import english from './language/english.json';
import french from './language/french.json';
import { MediaProvider } from './provider/MediaProvider';
import { ProfileProvider } from './provider/ProfileProvider';
import store from './store/configureStore.js';
import { isDarkModeSelector } from './reducers/themeReducers/themeSelector';
import { parseJson } from './utils/parseJson.js';

if (process.env.NODE_ENV !== 'development') {
  console.log = function () {};
  console.error = function () {};
  console.warn = function () {};
  console.info = function () {};
}

const localLanguage = parseJson(localStorage.getItem('localLanguage'));

i18next.use(initReactI18next).init({
  resources: {
    en: english,
    fr: french,
  },
  lng: localLanguage?.language?.value ?? 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
const container = document.getElementById('app');
const root = ReactDOMClient.createRoot(container);

root.render(
  <Auth0Provider
    domain="login.perspectives.pm"
    clientId="5JP42netbUXGaRXqoHsycr6WUg8Dk9j3"
    redirectUri={window.location.origin}
    useRefreshTokens="true"
    cacheLocation="localstorage"
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>
);

function App() {
  const isDarkMode = useSelector(isDarkModeSelector);
  // colors by      tailwindcolor.com/
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colorScheme: isDarkMode ? 'dark' : 'light',
        colors: {
          blue_gray: [
            '#F8FAFC',
            '#F1F5F9',
            '#E2E8F0',
            '#CBD5E1',
            '#94A3B8',
            '#64748B',
            '#475569',
            '#334155',
            '#1E293B',
            '#0F172A',
          ],
          cool_gray: [
            '#F9FAFB',
            '#F3F4F6',
            '#E5E7EB',
            '#D1D5DB',
            '#9CA3AF',
            '#6B7280',
            '#4B5563',
            '#374151',
            '#1F2937',
            '#111827',
          ],
          gray: [
            '#FAFAFA',
            '#F4F4F5',
            '#E4E4E7',
            '#D4D4D8',
            '#A1A1AA',
            '#71717A',
            '#52525B',
            '#3F3F46',
            '#27272A',
            '#18181B',
          ],
          true_gray: [
            '#FAFAFA',
            '#F5F5F5',
            '#E5E5E5',
            '#D4D4D4',
            '#A3A3A3',
            '#737373',
            '#525252',
            '#404040',
            '#262626',
            '#171717',
          ],
          warm_gray: [
            '#FAFAF9',
            '#F5F5F4',
            '#E7E5E4',
            '#D6D3D1',
            '#A8A29E',
            '#78716C',
            '#57534E',
            '#44403C',
            '#292524',
            '#1C1917',
          ],
          rose: [
            '#FFF1F2',
            '#FFE4E6',
            '#FECDD3',
            '#FDA4AF',
            '#F472B6',
            '#F43F5E',
            '#E11D48',
            '#BE123C',
            '#9F1239',
            '#881337',
            '',
          ],
          purple: [
            '#FAF5FF',
            '#F3E8FF',
            '#E9D5FF',
            '#D8B4FE',
            '#C084FC',
            '#A855F7',
            '#9333EA',
            '#7E22CE',
            '#6B21A8',
            '#581C87',
          ],
          violet: [
            '#F5F3FF',
            '#EDE9FE',
            '#DDD6FE',
            '#C4B5FD',
            '#A78BFA',
            '#8B5CF6',
            '#7C3AED',
            '#6D28D9',
            '#5B21B6',
            '#4C1D95',
          ],
          indigo: [
            '#EEF2FF',
            '#E0E7FF',
            '#C7D2FE',
            '#A5B4FC',
            '#818CF8',
            '#6366F1',
            '#4F46E5',
            '#4338CA',
            '#3730A3',
            '#312E81',
          ],
          blue: [
            '#EFF6FF',
            '#DBEAFE',
            '#BFDBFE',
            '#93C5FD',
            '#60A5FA',
            '#3B82F6',
            '#2563EB',
            '#1D4ED8',
            '#1E40AF',
            '#1E3A8A',
          ],
          light_blue: [
            '#F0F9FF',
            '#E0F2FE',
            '#BAE6FD',
            '#7DD3FC',
            '#38BDF8',
            '#0EA5E9',
            '#0284C7',
            '#0369A1',
            '#075985',
            '#0C4A6E',
          ],
          cyan: [
            '#ECFEFF',
            '#CFFAFE',
            '#A5F3FC',
            '#67E8F9',
            '#22D3EE',
            '#06B6D4',
            '#0891B2',
            '#0E7490',
            '#155E75',
            '#164E63',
          ],
          teal: [
            '#F0FDFA',
            '#CCFBF1',
            '#99F6E4',
            '#5EEAD4',
            '#2DD4BF',
            '#14B8A6',
            '#0D9488',
            '#0F766E',
            '#115E59',
            '#134E4A',
          ],
          green: [
            '#F0FDF4',
            '#DCFCE7',
            '#BBF7D0',
            '#86EFAC',
            '#4ADE80',
            '#22C55E',
            '#16A34A',
            '#15803D',
            '#166534',
            '#14532D',
          ],
          yellow: [
            '#FEFCE8',
            '#FEF9C3',
            '#FEF08A',
            '#FDE047',
            '#FACC15',
            '#EAB308',
            '#CA8A04',
            '#A16207',
            '#854D0E',
            '#713F12',
          ],
          orange: [
            '#FFF7ED',
            '#FFEDD5',
            '#FED7AA',
            '#FDBA74',
            '#FB923C',
            '#F97316',
            '#EA580C',
            '#C2410C',
            '#9A3412',
            '#7C2D12',
          ],
          red: [
            '#FEF2F2',
            '#FEE2E2',
            '#FECACA',
            '#FCA5A5',
            '#F87171',
            '#EF4444',
            '#DC2626',
            '#B91C1C',
            '#991B1B',
            '#7F1D1D',
          ],
        },
      }}
    >
      <MediaProvider>
        <ProfileProvider>
          <div>
            <NavigContainer />
          </div>
        </ProfileProvider>
      </MediaProvider>
    </MantineProvider>
  );
}
