import produce from 'immer';
import { pal } from '../../constants/palette';
import { LAYOUT_TYPE } from './type';

const initialState = {
  zoom: 100,
  zoomMap: 100,
  active_palette: 2,
  configure_object: false,
  configure_posX: 0,
  configure_posY: 0,
  configure_tab: 1,
  isPerspectivesPanelOpen: true,
  isVRActive: false,
  remote_ctrl_visible: false,
  objDetailsOpen: false,
  menuX: 200,
  menuY: 200,
  showSettings: false,
  showSettingLanguage: false,
  showPalette: false,
  isShowProfileView: false,
  layers_ctrl_visible: false,
  scrollY: 0,
  scrollX: 0,
  mousePosX: -1,
  mousePosY: -1,
  hiddenLayers: [],
  highlightedLayer: null,
  isShowActivityLog: false,
  currentObjIdSelect: null,
  objectDetailXPosition: 0,
  nodesSelect: [],
  isShowSelectNodeView: false,
  isShowMenuMobile: false,
  isShowPreviewSvgSketch: false,
  svgPreviewData: null,
  isShowModalImportMappingFile: false,
};
export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAYOUT_TYPE.SET_STATUS_MODAL_IMPORT_MAPPING_FILE:
      return produce(state, (draft) => {
        draft.isShowModalImportMappingFile = action.status;
      });

    case LAYOUT_TYPE.SET_SELECT_NODE:
      return produce(state, (draft) => {
        if (draft.nodesSelect.includes(action?.node?.key)) {
          draft.nodesSelect = draft.nodesSelect.filter(
            (node) => node !== action?.node?.key
          );
        } else {
          draft.nodesSelect.push(action?.node?.key);
        }
      });

    case LAYOUT_TYPE.RESET_SELECT_NODE:
      return produce(state, (draft) => {
        draft.nodesSelect = [];
      });

    case LAYOUT_TYPE.TOGGLE_PREVIEW_SVG_SKETCH:
      return produce(state, (draft) => {
        draft.isShowPreviewSvgSketch =
          action.status ?? !draft.isShowPreviewSvgSketch;
      });
    case LAYOUT_TYPE.SET_PREVIEW_SVG_SKETCH_DATA:
      return produce(state, (draft) => {
        draft.svgPreviewData = action.data;
      });

    case LAYOUT_TYPE.SET_SHOW_MENU_MOBILE:
      return produce(state, (draft) => {
        draft.isShowMenuMobile = action.value;
      });

    case LAYOUT_TYPE.SET_PRESS_SHIFT:
      return produce(state, (draft) => {
        draft.isShowSelectNodeView = action.status;
      });

    case LAYOUT_TYPE.SET_POSITION_X_OBJ_DETAIL:
      return produce(state, (draft) => {
        draft.objectDetailXPosition = action.objectDetailXPosition;
      });

    case LAYOUT_TYPE.CHANGE_STATUS_ACTIVITY_LOG:
      return produce(state, (draft) => {
        draft.isShowActivityLog = action.value;
      });

    case LAYOUT_TYPE.SET_ZOOM:
      return produce(state, (draft) => {
        draft.zoom = action.value;
      });

    case LAYOUT_TYPE.SET_ZOOM_MAP:
      return produce(state, (draft) => {
        draft.zoomMap = action.value;
      });

    case LAYOUT_TYPE.TOGGLE_PALETTE:
      return produce(state, (draft) => {
        draft.active_palette = (draft.active_palette + 1) % pal.length;
      });
    case LAYOUT_TYPE.CONFIGURE_CLOSE_SCREEN:
      return produce(state, (draft) => {
        draft.configure_object = false;
      });

    case LAYOUT_TYPE.CONFIGURE_OPEN_SCREEN:
      console.log('CONFIGURE_OPEN_SCREEN', action);
      return produce(state, (draft) => {
        const tabToOpen = action.objId === null ? '0' : '1'; // if no objId, open tab for objtype selection
        draft.configure_object = true;
        draft.configure_posX = action.posX;
        draft.configure_posY = action.posY;
        draft.configure_tab = tabToOpen;
      });

    case LAYOUT_TYPE.TOGGLE_PERSPECTIVES_PANEL:
      return produce(state, (draft) => {
        draft.isPerspectivesPanelOpen = !draft.isPerspectivesPanelOpen;
      });

    case LAYOUT_TYPE.TOGGLE_VR:
      return produce(state, (draft) => {
        draft.isVRActive = !draft.isVRActive;
      });

    case LAYOUT_TYPE.REMOTE_CTRL_SHOW_TOGGLE: {
      return produce(state, (draft) => {
        draft.remote_ctrl_visible = !draft?.remote_ctrl_visible;
        draft.objDetailsOpen = false;
        draft.menuX = action.x;
        draft.menuY = action.y;
      });
    }

    case LAYOUT_TYPE.OBJECT_DETAILS_OPEN:
      return produce(state, (draft) => {
        draft.objDetailsOpen = action.objDetailsOpen;
      });

    case LAYOUT_TYPE.TOGGLE_SHOW_SETTINGS:
      return produce(state, (draft) => {
        draft.showSettings = !draft.showSettings;
      });

    case LAYOUT_TYPE.LAYERS_CTRL_SHOW_TOGGLE: {
      return { ...state, layers_ctrl_visible: !state.layers_ctrl_visible };
    }
    case LAYOUT_TYPE.SCROLL_POS:
      return produce(state, (draft) => {
        draft.scrollX = action.scrollX;
        draft.scrollY = action.scrollY;
      });

    case LAYOUT_TYPE.SET_MOUSE_POS:
      return produce(state, (draft) => {
        draft.mousePosX = action.x;
        draft.mousePosY = action.y;
      });
    case LAYOUT_TYPE.SET_MENU_ACTIVE:
      return produce(state, (draft) => {
        draft.showSettings = false;
        draft.showSettingLanguage = false;
        draft[action.option] = true;
      });
    case LAYOUT_TYPE.RESET_MENU_OPTION:
      return produce(state, (draft) => {
        draft.showSettings = false;
        draft.showSettingLanguage = false;
        draft.showPalette = false;
      });
    case LAYOUT_TYPE.SHOW_PROFILE_VIEW:
      return produce(state, (draft) => {
        draft.isShowProfileView = true;
      });
    case LAYOUT_TYPE.HIDDEN_PROFILE_VIEW:
      return produce(state, (draft) => {
        draft.isShowProfileView = false;
      });
    case LAYOUT_TYPE.SET_CURRENT_OBJ_ID:
      return produce(state, (draft) => {
        draft.currentObjIdSelect = action.objId;
      });

    case LAYOUT_TYPE.UPDATE_LAYERS_DISPLAY: {
      switch (action.dataAction) {
        case 'show':
          return {
            ...state,
            hiddenLayers: state.hiddenLayers.filter((a) => a != action.dataId),
          };

        case 'hide':
          return {
            ...state,
            hiddenLayers: [
              ...state.hiddenLayers.filter((a) => a != action.dataId),
              action.dataId,
            ],
          };

        case 'highlight':
          return {
            ...state,
            hiddenLayers: state.hiddenLayers.filter((a) => a != action.dataId),
            highlightedLayer: action.dataId,
          };

        default:
          break;
      }
      break;
    }

    default:
      return state;
  }
};
