export const events = {
  list: [
    {
      header: 'task1',
      blocs: [{ name: 'bloc1' }, { name: 'bloc2' }],
    },
    {
      header: 'task2',
      blocs: [
        { name: 'bloc2.1' },
        { name: 'bloc2.2' },
        { name: 'bloc2.3' },
        { name: 'bloc2.4' },
      ],
    },
  ],
};
