import { createSelector } from 'reselect';
import { pal } from '../../constants/palette';

export const zoomSelector = createSelector(
  (state) => state?.layout?.zoom,
  (zoom) => zoom || 100
);

export const zoomMapSelector = (state) => state?.layout?.zoomMap || 100;
export const activePaletteSelector = (state) =>
  state?.layout?.active_palette || 2;

export const palSelector = (state) => {
  const active_palette = state?.layout?.active_palette || 2;
  return pal[active_palette];
};

export const configure_objectSelector = (state) =>
  state?.layout?.configure_object;
export const configure_posXSelector = (state) => state?.layout?.configure_posX;
export const configure_posYSelector = (state) => state?.layout?.configure_posY;
export const configure_tabSelector = (state) => state?.layout?.configure_tab;

export const isPerspectivesPanelOpenSelector = (state) =>
  state?.layout?.isPerspectivesPanelOpen;

export const isVRActiveSelector = (state) => state?.layout?.isVRActive;

export const remote_ctrl_visibleSelector = (state) =>
  state?.layout?.remote_ctrl_visible;
export const layers_ctrl_visibleSelector = (state) =>
  state?.layout?.layers_ctrl_visible;

export const objDetailsOpenSelector = (state) => state?.layout?.objDetailsOpen;
export const menuXSelector = (state) => state?.layout?.menuX;
export const menuYSelector = (state) => state?.layout?.menuY;
export const showSettingsSelector = (state) => state?.layout?.showSettings;
export const showPaletteSelector = (state) => state?.layout?.showPalette;

export const showSettingsLanguageSelector = (state) =>
  state?.layout?.showSettingLanguage;

export const scrollXSelector = (state) => state?.layout?.scrollX;
export const scrollYSelector = (state) => state?.layout?.scrollY;

export const mousePosXSelector = (state) => state?.layout?.mousePosX;
export const mousePosYSelector = (state) => state?.layout?.mousePosY;

export const hiddenLayersSelector = (state) => state?.layout?.hiddenLayers;
export const highlightedLayerSelector = (state) =>
  state?.layout?.highlightedLayer;

export const isShowProfileViewSelector = (state) =>
  state?.layout?.isShowProfileView;

export const isShowActivityLogSelector = (state) =>
  state?.layout?.isShowActivityLog;

export const currentObjIdSelectSelector = (state) =>
  state?.layout?.currentObjIdSelect;
export const objectDetailXPositionSelector = (state) =>
  state?.layout?.objectDetailXPosition;

export const isShowSelectNodeViewSelector = (state) =>
  state?.layout?.isShowSelectNodeView;
export const nodesSelectSelector = (state) => state?.layout?.nodesSelect;

export const nodeSelectedSelector = (state, nodeId) =>
  createSelector(
    () => state.layout.nodesSelect,
    (nodesSelect) => nodesSelect.includes(nodeId)
  );

export const isShowMenuMobileSelector = (state) =>
  state?.layout?.isShowMenuMobile;

export const isShowPreviewSvgSketchSelector = (state) =>
  state?.layout?.isShowPreviewSvgSketch;

export const previewSvgSketchDataSelector = (state) =>
  state?.layout?.svgPreviewData;

export const isShowModalImportMappingFileSelector = (state) =>
  state?.layout?.isShowModalImportMappingFile;
