import { useCallback, useEffect, useState } from 'react';
import { makeDraggable } from '../../../../utils/DragDropTouchTablet';
import { getPositionDragSnapSvg } from '../../../../utils/generatePolyline';
import { ObjFieldDrawingSVGText } from './ObjFieldDrawingPadCSS';

const SvgTextRenderer = ({
  svg,
  setActionDragSvg,
  keySvgSelect,
  setSvgFocus,
  handleSaveSvgFileToDb,
  isDisableAction = false,
  prefix_key,
  action,
  onDeleteSvgWithId,
  sizeGrid,
  isShowGrids,
  onClickElement,
}) => {
  const idNode = prefix_key ? `${prefix_key + svg?.key}` : svg?.key;

  const [boundElement, setBoundElement] = useState(null);
  const WrapWidthElement = boundElement?.width + 40 || '100%';
  const WrapHeightElement = boundElement?.height + 10 || '100%';

  const [position, setPosition] = useState({
    x: svg.x,
    y: svg.y,
  });

  useEffect(() => {
    if (svg?.key) {
      setBoundElement(
        document.getElementById(`content_${svg?.key}`)?.getBoundingClientRect()
      );
    }
  }, [svg?.key, svg?.fontSize, svg?.points]);

  // drawing;
  const handleSelectSvg = useCallback(() => {
    if (isDisableAction) {
      if (svg.link && typeof onClickElement === 'function')
        return onClickElement(svg.link);
    }
    if (action === 'delete') {
      return onDeleteSvgWithId(svg?.key);
    }

    if (typeof setSvgFocus === 'function') setSvgFocus(svg?.key);
  }, [svg?.key, keySvgSelect, isDisableAction, action, onDeleteSvgWithId]);

  const onMakeDraggable = (id) => {
    const centerWidthSvg = WrapWidthElement / 2;
    const centerHeightSvg = WrapHeightElement / 2;
    makeDraggable(
      document.getElementById(id),
      (e) => {
        // drag start
        if (action === 'delete') return;

        setActionDragSvg('move');
      },
      (e) => {
        // drag end
        const svgBox = document
          .getElementById('svgPad')
          ?.getBoundingClientRect();
        const x = e.x - svgBox?.x - centerWidthSvg;
        const y = e.y - svgBox?.y - centerHeightSvg;

        const positionSvg = getPositionDragSnapSvg({ x, y }, sizeGrid);
        handleSaveSvgFileToDb(svg?.key, isShowGrids ? positionSvg : { x, y });
      },
      (e) => {
        const svgBox = document
          .getElementById('svgPad')
          ?.getBoundingClientRect();

        // drag move
        const x = e.x - svgBox?.x - centerWidthSvg;
        const y = e.y - svgBox?.y - centerHeightSvg;
        const positionSvg = getPositionDragSnapSvg({ x, y }, sizeGrid);

        setPosition(isShowGrids ? positionSvg : { x, y });
      },
      (e) => {},
      15
    );
  };
  useEffect(() => {
    if (svg?.key === keySvgSelect) {
      onMakeDraggable(`wrap-${svg?.key}`);
    }
  });

  const isTransparent = svg?.backgroundColor === 'rgba(255, 255, 255, 0)';

  return (
    <svg
      x={isDisableAction ? svg?.x : position.x}
      y={isDisableAction ? svg?.y : position.y}
    >
      {svg?.backgroundColor && !isTransparent && !prefix_key && (
        <rect
          x="2"
          y="2"
          width={WrapWidthElement}
          height={WrapHeightElement}
          fill={svg?.backgroundColor}
        />
      )}

      <foreignObject
        width={WrapWidthElement}
        height={WrapHeightElement}
        style={{
          paddingLeft: 22,
          paddingTop: 7,
        }}
      >
        <ObjFieldDrawingSVGText
          link={svg?.link}
          action={action}
          isDisableAction={isDisableAction}
          onClick={handleSelectSvg}
          id={`content_${idNode}`}
          draggable={false}
          fontSize={svg?.fontSize}
          fontWeight={svg?.fontWeight}
          color={svg?.color}
          backgroundColor={
            prefix_key && !isTransparent ? svg?.backgroundColor : ''
          }
        >
          {svg?.points}
        </ObjFieldDrawingSVGText>
      </foreignObject>
      {svg?.key === keySvgSelect && (
        <rect
          // data-html2canvas-ignore="true"
          className="data-html-to-image-ignore"
          style={{ cursor: 'pointer' }}
          id={`wrap-${svg?.key}`}
          x="2"
          y="2"
          width={WrapWidthElement}
          height={WrapHeightElement}
          fill="transparent"
          stroke="#D8D8D8"
          strokeWidth="2"
          strokeDasharray="8 4"
        />
      )}
    </svg>
  );
};

export default SvgTextRenderer;
