import { useViewportSize } from '@mantine/hooks';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../constants/colors';
import { setShowMenuMobile } from '../../../reducers/layoutReducer/action';
import {
  isMobileSelector,
  isShowNodeContentSelector,
} from '../../../reducers/responsiveReducer/responsiveSelector';
import { isDarkModeSelector } from '../../../reducers/themeReducers/themeSelector';
import Swipeable from '../../Swipeable';
import { SwipeViewOpenMenu } from './SwipeMenuCSS';

function SwipeMenuMobile() {
  const isMobile = useSelector(isMobileSelector);
  const isShowNodeContent = useSelector(isShowNodeContentSelector);
  const dispatch = useDispatch();
  const { width } = useViewportSize();

  const onsetShowMenuMobile = ({ clientX }) => {
    if (width - clientX >= 15) {
      dispatch(setShowMenuMobile(true));
    }
  };
  const isDarkMode = useSelector(isDarkModeSelector);

  return (
    isMobile &&
    isShowNodeContent && (
      <Swipeable
        callback={onsetShowMenuMobile}
        onClick={() => {
          dispatch(setShowMenuMobile(true));
        }}
      >
        <SwipeViewOpenMenu
          backgroundColor={isDarkMode ? colors.io_grey_600 : colors.io_grey_300}
        />
      </Swipeable>
    )
  );
}

export default memo(SwipeMenuMobile);
