import * as d3 from 'd3';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { makeDraggable } from '../../../../utils/DragDropTouchTablet';
import { buildCursorSvgPadWithAction } from '../../../../utils/buildCursorSvgPadWithAction';
import {
  generatePointsPolylineWhenMovePoint,
  getPositionDragSnapSvg,
} from '../../../../utils/generatePolyline';

const SvgArrowLineRenderer = ({
  lineData,
  idLineDrawing,
  setSvgFocus,
  keySvgSelect,
  action,
  onDeleteSvgWithId,
  isDisableAction,
  setActionDragSvg,
  onUpdatePointsOnMove,
  sizeGrid,
  isShowGrids,
  prefix_key,
}) => {
  const svgRef = useRef();
  const startPosition = useRef({ x: 0, y: 0 });
  const [positionSvg, setPositionSvg] = useState(null);
  const svgVertexRef = useRef(null);

  const [dataPoint, setDataPoint] = useState([]);
  useEffect(() => {
    setDataPoint(lineData?.points);
  }, [lineData?.points]);

  const idSvg = prefix_key
    ? `${prefix_key + lineData?.key}`
    : `${lineData?.key}`;
  const handleSelectSvg = () => {
    if (isDisableAction) return;
    if (action === 'delete') {
      return onDeleteSvgWithId(lineData?.key);
    }
    if (typeof setSvgFocus === 'function') setSvgFocus(lineData?.key);
  };

  const svgArrowLineRenderer = () => {
    const svg = d3.select(svgRef.current);

    svg.selectAll('*').remove();

    // Define marker
    svg
      .append('defs')
      .append('marker')
      .attr('id', `arrow-${lineData.key}`)
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 2)
      .attr('refY', 0)
      .attr('markerWidth', 3)
      .attr('markerHeight', 3)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M0,-5L10,0L0,5')
      .style('fill', lineData?.color)
      .on('click', handleSelectSvg);

    if (lineData?.type === 'bidirectional-line') {
      svg
        .append('defs')
        .append('marker')
        .attr('id', `arrow-start-${lineData.key}`)
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 2)
        .attr('refY', 0)
        .attr('markerWidth', 3)
        .attr('markerHeight', 3)
        .attr('orient', 'auto-start-reverse')
        .append('path')
        .attr('d', 'M0,-5L10,0L0,5')
        .style('fill', lineData?.color)
        .on('click', handleSelectSvg);
    }
    // Draw line with arrow
    showVertexPont();

    if (dataPoint?.length >= 2) {
      d3.select(svgVertexRef.current).selectAll('*').remove();

      svg
        .append('line')
        .attr('x1', dataPoint[0].x)
        .attr('y1', dataPoint[0].y)
        .attr('x2', dataPoint[1].x)
        .attr('y2', dataPoint[1].y)
        .attr('stroke', lineData?.color)
        .attr('stroke-width', lineData?.tick)
        .attr('marker-end', `url(#arrow-${lineData.key})`)
        .attr('marker-start', `url(#arrow-start-${lineData.key})`)
        .on('click', handleSelectSvg);
    }
  };

  useEffect(() => {
    if (idLineDrawing === lineData?.key) {
      showVertexPont();
    }
    if (lineData?.key === keySvgSelect) {
      showVertexPont();
    }
  }, [idLineDrawing, lineData?.key, keySvgSelect]);

  useEffect(() => {
    if ((!keySvgSelect && !idLineDrawing) || keySvgSelect !== lineData?.key) {
      d3.select(svgVertexRef.current).selectAll('*').remove();
    }
  }, [idLineDrawing, keySvgSelect, lineData?.key]);

  // HANDLE  MOVE POINT
  useEffect(() => {
    const svg = d3.select(svgVertexRef.current);
    if (lineData?.key === keySvgSelect) {
      showVertexPont();
      // handle move point
      var dragHandler = d3
        .drag()
        .on('start', function () {
          startPosition.current = { x: d3.event.x, y: d3.event.y };
        })
        .on('drag', function (e) {
          const newDataPoints = generatePointsPolylineWhenMovePoint({
            dataPoint,
            isShowGrids,
            sizeGrid,
          });
          showVertexPont(newDataPoints);
          setDataPoint(newDataPoints);
        })
        .on('end', function () {
          const newDataPoints = generatePointsPolylineWhenMovePoint({
            dataPoint,
            isShowGrids,
            sizeGrid,
          });
          onUpdatePointsOnMove(lineData?.key, newDataPoints);
        });

      dragHandler(svg.selectAll('.vertex'));
    }
  });

  useEffect(() => {
    svgArrowLineRenderer();
  }, [lineData, action, dataPoint, lineData?.key]);

  const showVertexPont = (newDataPoints) => {
    d3.select(svgVertexRef.current).selectAll('*').remove();
    d3.select(svgVertexRef.current)
      .selectAll('.vertex')
      .data(newDataPoints || dataPoint)
      .enter()
      .append('circle')
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', lineData.tick)
      .attr('fill', '#068FFF')
      .attr('class', 'vertex');
  };

  useEffect(() => {
    if (keySvgSelect === lineData?.key) {
      const [point1, point2] = dataPoint;
      setPositionSvg(
        onGetStartPositionSvgArrowLine(point1.x, point1.y, point2.x, point2.y)
      );
    }
  }, [keySvgSelect, lineData?.key, dataPoint]);

  const onMakeDraggable = (id) => {
    makeDraggable(
      document.getElementById(id),
      ({ x, y }) => {
        // drag start
        startPosition.current = { x, y };

        if (action === 'delete') return;
        startPosition.current = { x, y };

        setActionDragSvg('move');
      },
      (position) => {
        const newPosition = calculateNewPositionLine(
          position,
          startPosition.current,
          dataPoint,
          sizeGrid,
          isShowGrids
        );
        onUpdatePointsOnMove(lineData?.key, newPosition);
      },
      (position) => {
        // d3.select(svgVertexRef.current).selectAll('*').remove();

        const svg = d3.select(svgRef.current);
        const newPosition = calculateNewPositionLine(
          position,
          startPosition.current,
          dataPoint,
          sizeGrid,
          isShowGrids
        );
        setDataPoint(newPosition);

        const [point1, point2] = newPosition;
        setPositionSvg(
          onGetStartPositionSvgArrowLine(point1.x, point1.y, point2.x, point2.y)
        );

        svg
          .select('line')
          .attr('x1', point1.x)
          .attr('y1', point1.y)
          .attr('x2', point2.x)
          .attr('y2', point2.y);
      },
      (e) => {},
      15
    );
  };
  useEffect(() => {
    if (lineData?.key !== keySvgSelect) return;
    onMakeDraggable(`wrap-${lineData.key}`);
  });
  const [point1, point2] = dataPoint;

  const sizeBox = onGetSizeSvg(point1?.x, point1?.y, point2?.x, point2?.y);

  return (
    <>
      <svg
        style={{
          cursor: buildCursorSvgPadWithAction(
            isDisableAction,
            action,
            lineData?.link
          ),
        }}
        ref={svgRef}
        id={idSvg}
      ></svg>

      {keySvgSelect === lineData?.key && dataPoint?.length >= 2 && (
        <>
          <rect
            // data-html2canvas-ignore="true"
            className="data-html-to-image-ignore"
            style={{ cursor: 'pointer' }}
            x={positionSvg?.x - 10}
            y={positionSvg?.y - 10}
            width={sizeBox?.width + 20}
            height={sizeBox?.height + 20}
            fill="transparent"
            stroke="#D8D8D8"
            strokeWidth="4"
            strokeDasharray="8 4"
          />
          <rect
            // data-html2canvas-ignore="true"
            className="data-html-to-image-ignore"
            style={{ cursor: 'pointer' }}
            id={`wrap-${lineData.key}`}
            x={positionSvg?.x}
            y={positionSvg?.y}
            width={sizeBox?.width}
            height={sizeBox?.height}
            fill="transparent"
            stroke="transparent"
            strokeWidth="4"
            strokeDasharray="8 4"
          />
        </>
      )}
      <svg ref={svgVertexRef} />
    </>
  );
};

export default SvgArrowLineRenderer;

const calculateNewPositionLine = (
  position,
  startPosition,
  dataPoint,
  sizeGrid,
  isShowGrids
) => {
  const positionMove = { x: 0, y: 0 };
  positionMove.x = position.x - startPosition.x;
  positionMove.y = position.y - startPosition.y;

  const positionSvg = isShowGrids
    ? getPositionDragSnapSvg(positionMove, sizeGrid)
    : positionMove;

  const newPosition = [
    {
      x: dataPoint[0].x + positionSvg.x,
      y: dataPoint[0].y + positionSvg.y,
      id: dataPoint[0]?.id,
    },
    {
      x: dataPoint[1].x + positionSvg.x,
      y: dataPoint[1].y + positionSvg.y,
      id: dataPoint[1]?.id,
    },
  ];
  if (!isShowGrids) return newPosition;

  return prepareDataWithSnapPosition(newPosition, sizeGrid);
};

export const onGetStartPositionSvgArrowLine = (x0, y0, x1, y1) => {
  return { x: x0 < x1 ? x0 : x1, y: y0 < y1 ? y0 : y1 };
};

const onGetSizeSvg = (x0, y0, x1, y1) => {
  return {
    width: x0 < x1 ? x1 - x0 : x0 - x1,
    height: y0 < y1 ? y1 - y0 : y0 - y1,
  };
};

const prepareDataWithSnapPosition = (position, sizeGrid) => {
  const newPosition = cloneDeep(position);
  const StartPositionSvg = onGetStartPositionSvgArrowLine(
    newPosition[0].x,
    newPosition[0].y,
    newPosition[1].x,
    newPosition[1].y
  );
  const positionSvgSnap = getPositionDragSnapSvg(StartPositionSvg, sizeGrid);
  const roundX = positionSvgSnap.x - StartPositionSvg.x;
  const roundY = positionSvgSnap.y - StartPositionSvg.y;

  newPosition[0].x += roundX;
  newPosition[0].y += roundY;
  newPosition[1].x += roundX;
  newPosition[1].y += roundY;
  return newPosition;
};
