import { isEmpty, uniqueId } from 'lodash';
import debounce from 'lodash/debounce';
import ObjectApi from './api/ObjectApi.js';
import * as NodeUtils from './components/Utils/NodeUtils';

import { getLayers } from './reducers/layoutReducer/action.js';
import {
  onPushAndAuthRemoveNotification,
  onPushNotification,
  onRemoveNotification,
} from './reducers/notificationReducer/action.js';
import {
  getPerspectives,
  getUserPrivs,
  getUserRole,
  openPerspective,
  refreshTreeByPattern,
  saveCurrentPerspective,
  setLoadingCrumbTrail,
} from './reducers/objectReducers/action.js';
import { OBJECTS_TYPE } from './reducers/objectReducers/type.js';
import store from './store/configureStore.js';

ObjectApi.saveObjFormDataDebounced = debounce(ObjectApi.saveObjFormData, 1000, {
  leading: false,
});

const ObjectActionCreators = {
  initApp() {
    return (dispatch) => {
      if (!store.getState().objects.initDone) {
        dispatch({ type: OBJECTS_TYPE.INIT_DONE });
        dispatch(getUserRole());
        dispatch(getUserPrivs());
        dispatch(getLayers());
        dispatch(getPerspectives());
        dispatch(ObjectActionCreators.getObjDefList());
      }
    };
  },

  pingServer() {
    return (dispatch) => {
      dispatch({ type: 'PING_SERVER' });
      ObjectApi.pingServer().then(
        (objects) =>
          dispatch({ type: 'PINGED_SERVER', success: true, objects }),
        (error) => dispatch({ type: 'PINGED_SERVER', success: false })
      );
    };
  },

  setDraggedObjFormData(objFormData) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_DRAGGED_OBJECT, objFormData });
    };
  },

  setSelectedObjFormData(objFormData) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_SELECTED_OBJECT, objFormData });
    };
  },

  toggleDevelopment() {
    return (dispatch) => {
      dispatch({ type: 'TOGGLE_DEVELOPMENT' });
    };
  },
  getObjectFormDataQueryBuilder(objDefId) {
    return (dispatch) => {
      dispatch({ type: 'GET_OBJECTFORMDATA_QUERYBUILDER', objDefId });
      ObjectApi.selectObject(objDefId).then((objects) => {
        dispatch({
          type: 'RECEIVED_OBJECTFORMDATA_QUERYBUILDER',
          success: true,
          objects,
        });
      });
    };
  },

  // allow to keep track of the form field being edited accross rendering
  setObjectFormFieldEdited(fieldName) {
    return (dispatch) => {
      dispatch({ type: 'SET_OBJECTFORM_FIELD_EDITED', fieldName });
    };
  },

  saveObjectFormDataDebounced(objId, objFormData) {
    return (dispatch) => {
      dispatch({
        type: OBJECTS_TYPE.SAVE_OBJECTFORMDATA,
        objId,
        objects: objFormData,
      });

      return ObjectApi.saveObjFormDataDebounced(objId, objFormData)?.then(
        (objects) => {
          dispatch(ObjectActionCreators.refreshCrumbtrail(objId));
          if (objId.indexOf('persp') === 0) {
            dispatch(getPerspectives());
          }
          const state = store.getState();
          if (objId === state.objects.perspectiveId) {
            dispatch(openPerspective(state.objects.objFormData));
          }
        }
      );
    };
  },

  saveObjectFormData(objId, objFormData) {
    console.log('saveObjectFormData_action');
    return (dispatch) => {
      dispatch({
        type: OBJECTS_TYPE.SAVE_OBJECTFORMDATA,
        objId,
        objects: objFormData,
      });

      return ObjectApi.saveObjFormData(objId, objFormData)?.then((objects) => {
        // dispatch(ObjectActionCreators.refreshCrumbtrail(objId));
        if (objId.indexOf('persp') === 0) {
          dispatch(getPerspectives());
        }
        const state = store.getState();
        if (objId === state.objects.perspectiveId) {
          dispatch(openPerspective(state.objects.objFormData));
        }
      });
    };
  },

  expandNodes(col) {
    const state = store.getState();
    const allNodes = [...state.objects.nodes];
    const res = [];
    while (allNodes.length > 0) {
      const elem = allNodes.pop();
      if (elem.depth - 1 === col) res.push(elem.key);
      if (elem.children) {
        allNodes.push(...elem.children);
      }
    }
    const nodes = res;

    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.EXPAND_NODES, nodes });
    };
  },

  collapseNodes(col) {
    const state = store.getState();
    const allNodes = [...state.objects.nodes];
    const res = [];
    while (allNodes.length > 0) {
      const elem = allNodes.pop();
      if (elem.depth - 1 === col) res.push(elem.key);
      if (elem.children) {
        allNodes.push(...elem.children);
      }
    }
    const nodes = res;
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.COLLAPSE_NODES, nodes });
    };
  },

  collapseNode(objId) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.COLLAPSE_NODE, objId });
    };
  },

  expandNode(objId) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.EXPAND_NODE, objId });
      setTimeout(() => {
        dispatch(refreshTreeByPattern());
      }, 500);
    };
  },

  socialToggleIcon(objId, iconName) {
    return (dispatch) => {
      dispatch({ type: 'SOCIAL_TOGGLE_ICON' });
      ObjectApi.socialToggleIcon(objId, iconName).then(
        (objects) =>
          dispatch({
            type: OBJECTS_TYPE.RECEIVED_SOCIAL,
            success: true,
            objects,
          }),
        (error) =>
          dispatch({ type: OBJECTS_TYPE.RECEIVED_SOCIAL, success: false })
      );
    };
  },

  socialPostComment(objId, comment) {
    return (dispatch) => {
      dispatch({ type: 'SOCIAL_POST_COMMENT' });
      ObjectApi.socialPostComment(objId, comment).then(
        (objects) =>
          dispatch({
            type: OBJECTS_TYPE.RECEIVED_SOCIAL,
            success: true,
            objects,
          }),
        (error) =>
          dispatch({ type: OBJECTS_TYPE.RECEIVED_SOCIAL, success: false })
      );
    };
  },

  getSocialContent(objId) {
    return (dispatch) => {
      dispatch({ type: 'GET_SOCIAL_CONTENT' });
      ObjectApi.getSocialContent(objId).then(
        (objects) =>
          dispatch({
            type: OBJECTS_TYPE.RECEIVED_SOCIAL,
            success: true,
            objects,
          }),
        (error) =>
          dispatch({ type: OBJECTS_TYPE.RECEIVED_SOCIAL, success: false })
      );
    };
  },
  postFile(objId, file, filename, attrName) {
    return (dispatch) => {
      dispatch({ type: 'POST_FILE' });
      ObjectApi.postFile(objId, file, filename, attrName).then(
        (objects) => {
          dispatch({ type: 'POSTED_FILE', success: true, objects });
          dispatch(ObjectActionCreators.selectObject(objId));
        },
        (error) => dispatch({ type: 'POSTED_FILE', success: false })
      );
    };
  },
  postTemplateFile(objId, file, filename, attrName) {
    return (dispatch) => {
      dispatch({ type: 'POST_TEMPLATE_FILE' });
      ObjectApi.postTemplateFile(objId, file, filename, attrName).then(
        (objects) => {
          dispatch({ type: 'POSTED_TEMPLATE_FILE', success: true, objects });
          dispatch(ObjectActionCreators.selectObject(objId));
        },
        (error) => dispatch({ type: 'POSTED_TEMPLATE_FILE', success: false })
      );
    };
  },
  saveObject(objId, params) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SAVE_OBJECT });

      ObjectApi.saveObject(objId, params).then(
        (objects) => {
          dispatch({ type: 'SAVED_OBJECTS', success: true, objects });

          dispatch(ObjectActionCreators.selectObject(objId));
          dispatch(ObjectActionCreators.refreshCrumbtrail(objId));
        },
        (error) => dispatch({ type: 'SAVED_OBJECTS', success: false })
      );
    };
  },

  setObjectCreatorState(objectCreatorState) {
    return (dispatch) => {
      dispatch({ type: 'SET_OBJECT_CREATOR_STATE', objectCreatorState });
    };
  },

  setObjectSelectorState(objectSelectorState) {
    return (dispatch) => {
      dispatch({ type: 'SET_OBJECT_SELECTOR_STATE', objectSelectorState });
    };
  },

  deleteObject(objId) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.DELETE_OBJECT });
      ObjectApi.deleteObject(objId).then((objects) => {
        dispatch(refreshTreeByPattern());
      });
    };
  },
  // SET_LOADING_RECEIVE_TREE: 'SET_LOADING_RECEIVE_TREE',

  getChildsCountByType(parentsId, level) {
    return (dispatch) => {
      dispatch({ type: 'GET_CHILDS_COUNT_BY_TYPE' });
      const state = store.getState();
      const nodesFlat = NodeUtils.getArrayChildrenFromHierarchy(
        state.objects.nodes
      );
      const parentsId = nodesFlat
        .filter((item) => item.depth === level)
        .map((item) => item.key);

      if (parentsId.length > 0 || level === 0) {
        ObjectApi.getChildsCountByType(parentsId, level).then(
          (objects) => {
            dispatch({
              type: OBJECTS_TYPE.RECEIVED_CHILDS_COUNT_BY_TYPE,
              success: true,
              objects,
              level,
              value: false,
            });
          },
          (error) => {
            dispatch({
              type: OBJECTS_TYPE.RECEIVED_CHILDS_COUNT_BY_TYPE,
              success: false,
              value: false,
            });
          }
        );
      }
    };
  },

  getObjDefList(origin) {
    return (dispatch) => {
      dispatch({ type: 'REQUEST_OBJECTS_DEF_LIST' });
      dispatch({
        type: OBJECTS_TYPE.SET_STATUS_OBJECT_DEFINITION,
        status: true,
        isLoadingObjectsDefList: true,
      });
      ObjectApi.getObjDefList().then(
        (objects) => {
          dispatch({
            type: OBJECTS_TYPE.RECEIVED_OBJECTS_DEF_LIST,
            success: true,
            objects,
          });
          dispatch({
            type: OBJECTS_TYPE.SET_STATUS_OBJECT_DEFINITION,
            status: false,
            isLoadingObjectsDefList: false,
          });
        },
        (error) => {
          dispatch({
            type: OBJECTS_TYPE.RECEIVED_OBJECTS_DEF_LIST,
            success: false,
          });
          dispatch({
            type: OBJECTS_TYPE.SET_STATUS_OBJECT_DEFINITION,
            status: false,
            isLoadingObjectsDefList: false,
          });
        }
      );
    };
  },

  deleteObjectAttribute(objAttrId, objDefId) {
    return (dispatch) => {
      dispatch({ type: 'DELETE_OBJECT_ATTRIBUTE' });
      ObjectApi.deleteObjectAttribute(objAttrId).then(
        (objects) => {
          dispatch(ObjectActionCreators.selectDefObject(objDefId));
          dispatch({
            type: 'DELETED_OBJECT_ATTRIBUTE',
            success: true,
            objects,
          });
        },
        (error) =>
          dispatch({ type: 'DELETED_OBJECT_ATTRIBUTE', success: false })
      );
    };
  },

  updateObjectDefinition(
    objId,
    label,
    description,
    icon,
    allowUnder,
    callback
  ) {
    return (dispatch) => {
      dispatch({ type: 'UPDATE_OBJECT_DEFINITION' });
      ObjectApi.updateObjectDefinition(
        objId,
        label,
        description,
        icon,
        allowUnder
      ).then(
        async (objects) => {
          dispatch(ObjectActionCreators.getObjDefList(objId));
          await dispatch(ObjectActionCreators.selectDefObject(objId));
          if (typeof callback === 'function') {
            callback();
          }
          dispatch({
            type: 'UPDATED_OBJECT_DEFINITION',
            success: true,
            objects,
          });
        },
        (error) => {
          console.log('🚀 ~ file: actions.js:365 ~ return ~ error:', error);
          dispatch({ type: 'UPDATED_OBJECT_DEFINITION', success: false });
          if (typeof callback === 'function') {
            callback();
          }
        }
      );
    };
  },

  saveObjectDefinition(objId, description, inherit, t, callBackSuccess) {
    return (dispatch) => {
      dispatch({
        type: OBJECTS_TYPE.SET_STATUS_OBJECT_DEFINITION,
        status: true,
      });
      dispatch({ type: OBJECTS_TYPE.SAVE_OBJECT_DEFINITION });

      ObjectApi.saveObjectDefinition(objId, description, inherit).then(
        (objects) => {
          dispatch(ObjectActionCreators.getObjDefList(objId));
          dispatch(ObjectActionCreators.selectDefObject(objId));
          dispatch({ type: 'SAVED_OBJECT_DEFINITION', success: true, objects });
          dispatch(
            onPushAndAuthRemoveNotification({
              title: t('object.object_config'),
              description: t('object.add_item_success'),
              disallowClose: false,
              isLoading: false,
            })
          );
          if (typeof callBackSuccess === 'function') callBackSuccess();
        },
        (error) => {
          dispatch({ type: 'SAVED_OBJECT_DEFINITION', success: false });
          dispatch(
            onPushAndAuthRemoveNotification({
              title: t('object.object_config'),
              description: t('object.add_item_failed'),
              disallowClose: false,
              isLoading: false,
            })
          );
        }
      );
    };
  },

  deleteObjectDefinition(objId) {
    return (dispatch) => {
      dispatch({ type: 'DELETE_OBJECT_DEFINITION' });
      dispatch({
        type: OBJECTS_TYPE.SET_STATUS_OBJECT_DEFINITION,
        status: true,
      });
      ObjectApi.deleteObjectDefinition(objId).then(
        (objects) => {
          dispatch(ObjectActionCreators.getObjDefList());

          dispatch({
            type: 'DELETED_OBJECT_DEFINITION',
            success: true,
            objects,
          });
        },
        (error) =>
          dispatch({ type: 'DELETED_OBJECT_DEFINITION', success: false })
      );
    };
  },

  executeCommand(command, objId) {
    return (dispatch) => {
      dispatch({ type: command });
      let notificationId = uniqueId();
      dispatch(
        onPushNotification({
          title: `command ${command}`, // this.props.t('app.suggest_model'),
          description: `${command} ${objId}`, // this.props.t('app.in_progressing'),
          disallowClose: true,
          notificationId,
        })
      );
      ObjectApi.executeCommand(command, objId).then(
        (objects) => {
          dispatch({ type: `${command}_DONE`, success: true, objects });
          dispatch(refreshTreeByPattern());
          dispatch(ObjectActionCreators.getObjDefList());
          dispatch(onRemoveNotification({ notificationId }));
        },
        (error) => {
          dispatch(onRemoveNotification({ notificationId }));
          dispatch({ type: `${command}_DONE`, success: false });
        }
      );
    };
  },

  suggestWorkflow(objId) {
    return (dispatch) => {
      dispatch({ type: 'SUGGEST_WORKFLOW' });
      let notificationId = uniqueId();
      dispatch(
        onPushNotification({
          title: 'suggest workflow', // this.props.t('app.suggest_model'),
          description: 'suggest workflow', // this.props.t('app.in_progressing'),
          disallowClose: true,
          notificationId,
        })
      );
      ObjectApi.suggestWorkflow(objId).then(
        (objects) => {
          dispatch({ type: 'SUGGEST_WORKFLOW_DONE', success: true, objects });
          dispatch(refreshTreeByPattern());
          dispatch(onRemoveNotification({ notificationId }));
        },
        (error) => {
          dispatch(onRemoveNotification({ notificationId }));
          dispatch({ type: 'SUGGEST_WORKFLOW_DONE', success: false });
        }
      );
    };
  },

  suggestModel(objId) {
    return (dispatch) => {
      dispatch({ type: 'SUGGEST_MODEL' });
      let notificationId = uniqueId();
      dispatch(
        onPushNotification({
          title: 'suggest model', // this.props.t('app.suggest_model'),
          description: 'suggest model', // this.props.t('app.in_progressing'),
          disallowClose: true,
          notificationId,
        })
      );
      ObjectApi.suggestModel(objId).then(
        (objects) => {
          dispatch({ type: 'SUGGEST_MODEL_DONE', success: true, objects });
          dispatch(refreshTreeByPattern());
          dispatch(onRemoveNotification({ notificationId }));
        },
        (error) => {
          dispatch(onRemoveNotification({ notificationId }));
          dispatch({ type: 'SUGGEST_MODEL_DONE', success: false });
        }
      );
    };
  },

  implementModel(objId) {
    return (dispatch) => {
      dispatch({ type: 'IMPLEMENT_MODEL' });
      let notificationId = uniqueId();
      dispatch(
        onPushNotification({
          title: 'Implement model', // this.props.t('app.suggest_model'),
          description: 'Implement model', // this.props.t('app.in_progressing'),
          disallowClose: true,
          notificationId,
        })
      );
      ObjectApi.implementModel(objId).then(
        (objects) => {
          dispatch({ type: 'IMPLEMENT_MODEL_DONE', success: true, objects });
          dispatch(ObjectActionCreators.getObjDefList());
          dispatch(onRemoveNotification({ notificationId }));
        },
        (error) => {
          dispatch(onRemoveNotification({ notificationId }));
          dispatch({ type: 'IMPLEMENT_MODEL_DONE', success: false });
        }
      );
    };
  },

  voiceToText(objId, attrName, blob) {
    return (dispatch) => {
      dispatch({ type: 'VOICE_TO_TEXT' });
      ObjectApi.voiceToText(objId, attrName, blob).then(
        (objects) => {
          dispatch({ type: 'VOICE_TO_TEXT_DONE', success: true, objects });
          dispatch(ObjectActionCreators.selectObject(objId));
        },
        (error) => dispatch({ type: 'VOICE_TO_TEXT_DONE', success: false })
      );
    };
  },

  saveObjectAttribute(objId, ...arg) {
    return (dispatch) => {
      dispatch({ type: 'SAVE_OBJECT_ATTRIBUTE' });
      ObjectApi.saveObjectAttribute(objId, ...arg).then(
        (objects) => {
          dispatch(ObjectActionCreators.selectDefObject(objId));
          dispatch(ObjectActionCreators.refreshCrumbtrail(objId));
          dispatch({ type: 'SAVED_OBJECT_ATTRIBUTE', success: true, objects });
        },
        (error) => dispatch({ type: 'SAVED_OBJECT_ATTRIBUTE', success: false })
      );
    };
  },

  getFather(objId) {
    return (dispatch) => {
      dispatch({ type: 'GET_FATHER' });
      const state = store.getState();
      const { fathers } = state.objects;
      const exists = fathers.find((a) => a.objId === objId);
      if (!exists)
        ObjectApi.getFather(objId).then(
          (objects) => {
            dispatch({ type: OBJECTS_TYPE.GOT_FATHER, success: true, objects });
          },
          (error) => dispatch({ type: OBJECTS_TYPE.GOT_FATHER, success: false })
        );
    };
  },
  createBrother(objId, copyChilds = false) {
    return (dispatch) => {
      dispatch({ type: 'CREATE_BROTHER' });
      ObjectApi.createBrother(objId, copyChilds).then(
        (objects) => {
          dispatch({
            type: OBJECTS_TYPE.CREATED_FROM_OBJ_DEF,
            success: true,
            objects,
          });
        },
        (error) =>
          dispatch({ type: OBJECTS_TYPE.CREATED_FROM_OBJ_DEF, success: false })
      );
    };
  },

  getNumberChilds(objId) {
    return (dispatch) => {
      ObjectApi.getNumberChilds(objId).then(
        (objects) => {
          dispatch({
            type: OBJECTS_TYPE.GOT_NBR_CHILD,
            success: true,
            objects,
          });
        },
        (error) =>
          dispatch({ type: OBJECTS_TYPE.GOT_NBR_CHILD, success: false })
      );
    };
  },

  createNewPerspective() {
    let state = store.getState();
    let objectsDefList = state.objects.objectsDefList;
    let defId = NodeUtils.getObjectTypeByLabel('perspective', objectsDefList);
    return (dispatch) => {
      dispatch({ type: 'CREATE_FROM_OBJ_DEF' });
      ObjectApi.createFromObjectDefinitionInNode(defId, 'ROOT', null).then(
        (objects) => {
          dispatch({
            type: OBJECTS_TYPE.CREATED_PERSP_FROM_OBJ_DEF,
            success: true,
            objects,
          });
          dispatch(saveCurrentPerspective(objects[0].newObjId));
        },
        (error) =>
          dispatch({
            type: OBJECTS_TYPE.CREATED_PERSP_FROM_OBJ_DEF,
            success: false,
          })
      );
    };
  },

  createFromObjectDefinitionInNode(objId, parentId, perspectiveId) {
    return (dispatch) => {
      dispatch({ type: 'CREATE_FROM_OBJ_DEF' });
      ObjectApi.createFromObjectDefinitionInNode(
        objId,
        parentId,
        perspectiveId
      ).then(
        (objects) => {
          dispatch({
            type: OBJECTS_TYPE.CREATED_FROM_OBJ_DEF,
            success: true,
            objects,
          });
        },
        (error) =>
          dispatch({ type: OBJECTS_TYPE.CREATED_FROM_OBJ_DEF, success: false })
      );
    };
  },

  createFromObjectDefinitionInNodeOnLayers(
    objId,
    parentId,
    perspectiveId,
    layersRead,
    layersWrite
  ) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.CLEAN_OBJECT });
      dispatch({ type: 'CREATE_FROM_OBJ_DEF' });
      ObjectApi.createFromObjectDefinitionInNodeOnLayers(
        objId,
        parentId,
        perspectiveId,
        layersRead,
        layersWrite
      ).then(
        (objects) => {
          dispatch({
            type: OBJECTS_TYPE.CREATED_FROM_OBJ_DEF,
            success: true,
            objects,
          });
        },
        (error) =>
          dispatch({ type: OBJECTS_TYPE.CREATED_FROM_OBJ_DEF, success: false })
      );
    };
  },

  refreshCrumbtrail(objId) {
    return (dispatch) => {
      dispatch({ type: 'REFRESH_CRUMBTRAIL' });

      const state = store.getState();
      const crumb = state.objects.crumbTrail;
      if (crumb) {
        const crumbIndex = crumb.findIndex((elem) => elem.key == objId);
        if (crumbIndex > -1) {
          const parent = crumb[crumbIndex - 1]?.key;
          if (parent) {
            ObjectApi.selectObject(parent).then(
              (objects) => {
                dispatch({
                  type: OBJECTS_TYPE.REFRESHED_OBJECT,
                  success: true,
                  objects,
                  objId,
                });
                dispatch(ObjectActionCreators.refreshCrumbtrail(parent));
                dispatch({ type: 'REFRESHED_CRUMBTRAIL_ELEM', success: true });
              },
              (error) =>
                dispatch({ type: 'REFRESHED_CRUMBTRAIL_ELEM', success: false })
            );
          }
        }
      }
    };
  },

  unstackObject() {
    return (dispatch) => {
      const state = store.getState();
      const { objStack } = state.objects;
      let objId = null;
      if (objStack) {
        objId = objStack[objStack.length - 1]?.objId;
      }
      dispatch({ type: OBJECTS_TYPE.UNSTACK_OBJECT });

      if (objId != null)
        ObjectApi.selectObject(objId).then(
          (objects) =>
            dispatch({
              type: OBJECTS_TYPE.SELECTED_OBJECT,
              success: true,
              objects,
              objId,
            }),
          (error) =>
            dispatch({ type: OBJECTS_TYPE.SELECTED_OBJECT, success: false })
        );
    };
  },
  setObjectToCreate(objType) {
    return (dispatch) => {
      dispatch({ type: 'SELECTED_OBJECT_TO_CREATE', objType });
    };
  },

  selectObjectAndStack(objId) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.STACK_OBJECT, objId });
      if (objId != null)
        ObjectApi.selectObject(objId).then(
          (objects) =>
            dispatch({
              type: OBJECTS_TYPE.SELECTED_OBJECT,
              success: true,
              objects,
              objId,
            }),
          (error) =>
            dispatch({ type: OBJECTS_TYPE.SELECTED_OBJECT, success: false })
        );
    };
  },

  selectObject(objId) {
    return async (dispatch, getState) => {
      const objFormData = getState().objects.objFormData;

      if (!isEmpty(objFormData) && objId !== getState().objects?.objId) {
        await dispatch({ type: OBJECTS_TYPE.CLEAN_OBJECT });
      }
      dispatch({ type: OBJECTS_TYPE.SELECT_OBJECT, objId });
      if (objId != null) {
        dispatch({ type: OBJECTS_TYPE.SET_LOADING_STATUS, status: true });
        ObjectApi.selectObject(objId).then(
          (objects) => {
            dispatch(ObjectActionCreators.getCrumbTrail(objId));
            dispatch({
              type: OBJECTS_TYPE.SELECTED_OBJECT,
              success: true,
              objects,
              objId,
            });
            dispatch({
              type: OBJECTS_TYPE.SET_LOADING_STATUS,
              status: false,
            });
          },
          (error) => {
            dispatch({ type: OBJECTS_TYPE.SELECTED_OBJECT, success: false });
            dispatch({ type: OBJECTS_TYPE.SET_LOADING_STATUS, status: false });
          }
        );
      }
    };
  },

  selectDefObject(defId) {
    return (dispatch) => {
      dispatch({ type: 'SELECT_DEF_OBJECT', defId });
      if (defId != null)
        ObjectApi.selectObject(defId).then(
          (objects) =>
            dispatch({
              type: OBJECTS_TYPE.SELECTED_DEF_OBJECT,
              success: true,
              objects,
              defId,
            }),
          (error) =>
            dispatch({ type: OBJECTS_TYPE.SELECTED_DEF_OBJECT, success: false })
        );
    };
  },

  export(objId, format, notificationId) {
    return async (dispatch) => {
      await ObjectApi.exportObject(objId, format);
      dispatch(onRemoveNotification({ notificationId }));
    };
  },

  setStatBy(statKey, level) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_STAT_BY, statKey, level });
    };
  },

  toggleDisplayMode(level) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.TOGGLE_DISPLAY_MODE, level });
    };
  },

  setDisplayMode(mode, level) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_DISPLAY_MODE, mode, level });
      dispatch({ type: 'TOGGLED_ADDITIONAL_LINE' });
    };
  },

  setSortBy(sortKey, level) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_SORT_BY, sortKey, level });
    };
  },

  setActivePanel(level) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_ACTIVE_PANEL, level });
    };
  },

  setActiveBand(level, band) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_ACTIVE_BAND, level, band });
    };
  },

  //-------------------------------------------------------------------------------------------
  getCrumbTrail(objId) {
    return async (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.RESET_CRUMB_TRAIL });
      dispatch(setLoadingCrumbTrail(true));
      ObjectApi.getCrumbTrail(objId).then(
        (objects) => {
          dispatch({
            type: OBJECTS_TYPE.RECEIVED_CRUMBTRAIL,
            success: true,
            objects,
          });
        },
        (error) =>
          dispatch({ type: OBJECTS_TYPE.RECEIVED_CRUMBTRAIL, success: false })
      );
    };
  },
  //------------------------------------------------------------------------------------------------
  clearFieldRestriction() {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.CLEAR_FIELD_RESTRICTION });
    };
  },

  //------------------------------------------------------------------------------------------------
  addFieldRestriction(field, value, objectType, fieldType, comparator, level) {
    return (dispatch) => {
      dispatch({
        type: 'ADD_TERM_TO_QB',
        level,
        comparator,
        fieldType,
        field,
        value,
      });
      setTimeout(() => {
        dispatch(refreshTreeByPattern());
      }, 1500);
    };
  },
  //------------------------------------------------------------------------------------------------
  resetQBRestrictionByLevel(level) {
    return ObjectActionCreators.setQBRestrictionByLevel(level, '', '');
  },

  //------------------------------------------------------------------------------------------------
  setQBRestrictionByLevel(level, object, str, strraw) {
    return (dispatch) => {
      dispatch({
        type: 'SET_QB_RESTRICTION_BY_LEVEL',
        level,
        object,
        str,
        strraw,
      });
      dispatch(refreshTreeByPattern());
    };
  },

  //------------------------------------------------------------------------------------------------
  selectContext(strContext) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SELECT_CONTEXT, strContext });
    };
  },

  setPanels(bands_config) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_PANELS, bands_config });
    };
  },

  setParamClassifX(paramClassifX) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_CLASSIFX, paramClassifX });
    };
  },
  setParamClassifY(paramClassifY) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_CLASSIFY, paramClassifY });
    };
  },

  upContext() {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.UP_CONTEXT });
    };
  },

  downContext() {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.DOWN_CONTEXT });
    };
  },
  setHoverNode(objId) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.SET_HOVER_NODE, objId });
    };
  },

  closeObjectMenu() {
    return (dispatch) => {
      dispatch({ type: 'CLOSE_OBJECTMENU' });
    };
  },

  linkField(fieldName) {
    return async (dispatch) => {
      await dispatch({ type: 'LINK_FIELD', fieldName });
      await dispatch(
        await ObjectActionCreators.saveObjectFormData(
          store.getState().objects.objId,
          store.getState().objects.objFormData
        )
      );
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 5000);
      });
      await dispatch(
        ObjectActionCreators.selectObject(store.getState().objects.objId)
      );
    };
  },

  linkObject(objId) {
    return async (dispatch) => {
      await dispatch({ type: 'LINK_OBJECT', objId });
      await dispatch(
        await ObjectActionCreators.saveObjectFormData(
          store.getState().objects.objId,
          store.getState().objects.objFormData
        )
      );
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 5000);
      });
      await dispatch(
        ObjectActionCreators.selectObject(store.getState().objects.objId)
      );
    };
  },

  closeSelectObject() {
    return (dispatch) => {
      dispatch({ type: 'CLOSE_SELECTOBJECT' });
    };
  },

  openObjectMenu(action) {
    return (dispatch) => {
      if (action === 'moveObject') {
        dispatch({ type: 'OPEN_OBJECTMENU_FORMOVE', action });
      } else if (action === 'addObject') {
        dispatch({ type: 'OPEN_OBJECTMENU', action });
      }
    };
  },

  setDrawingItemSelected(labelSelected, selectedDrawingItem, typeElement) {
    return (dispatch) => {
      dispatch({
        type: 'SET_DRAWING_ITEM_SELECTED',
        labelSelected,
        selectedDrawingItem,
        typeElement,
      });
    };
  },

  openObjectSelector(action, posX, posY) {
    return (dispatch) => {
      dispatch({ type: 'OPEN_OBJECTSELECTOR', action, posX, posY });
    };
  },

  configureSelectTab(ind) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.CONFIGURE_SELECT_TAB, ind });
    };
  },
  timeline_upday(date) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.TIMELINE_UPDAY });
    };
  },
  timeline_downday() {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.TIMELINE_DOWNDAY });
    };
  },

  filter_set_level(level, val) {
    return (dispatch) => {
      dispatch({ type: OBJECTS_TYPE.FILTER_SET_LEVEL, level, val });
      // when a filter is changed, reset the qbuilder for that level too

      if (level < 3)
        dispatch(ObjectActionCreators.getChildsCountByType(null, level + 1));
    };
  },
};

export default ObjectActionCreators;
