import produce from 'immer';
import { uniqueId } from 'lodash';
import { NOTIFICATION_TYPE } from './type';

const initialState = {
  notifications: [],
};
export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_TYPE.PUSH_NOTIFICATION:
      return produce(state, (draft) => {
        const {
          description,
          title,
          notificationId,
          disallowClose = false,
          isLoading = true,
        } = action;
        draft.notifications.push({
          description,
          title,
          disallowClose,
          isLoading,
          id: notificationId || uniqueId(),
        });
      });
    case NOTIFICATION_TYPE.REMOVE_NOTIFICATION:
      return produce(state, (draft) => {
        const notificationNecessary = state.notifications.filter(
          (notification) => notification?.id !== action?.notificationId
        );
        draft.notifications = notificationNecessary;
      });

    default:
      return state;
  }
};
