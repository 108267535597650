import { memo } from 'react';
import { connect } from 'react-redux';
import { onSetPositionXObjectDetail } from '../../reducers/layoutReducer/action';
import {
  objDetailsOpenSelector,
  objectDetailXPositionSelector,
  scrollXSelector,
  zoomSelector,
} from '../../reducers/layoutReducer/layoutSelector';
import { currentPaletteActiveSelector } from '../../reducers/themeReducers/themeSelector';
import ResizablePanel from './ResizablePanel';

//------------------------------------------------------------------------------------------------------
function mapStateToProps(state) {
  return {
    zoom: zoomSelector(state),
    scrollX: scrollXSelector(state),
    phoneModel: state.objects.phoneModel,
    activePanel: state.objects.activePanel,
    pal: currentPaletteActiveSelector(state),
    objDetailsOpen: objDetailsOpenSelector(state),
    objectDetailXPosition: objectDetailXPositionSelector(state),
    objId: state.objects.objId,
  };
}
//------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
  return {
    onSetPositionXObjectDetail: (objectDetailXPosition) =>
      dispatch(onSetPositionXObjectDetail(objectDetailXPosition)),
  };
};
export default memo(
  connect(mapStateToProps, mapDispatchToProps)(ResizablePanel)
);
