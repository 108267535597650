import styled from 'styled-components';
import { devices } from '../../../constants/devices';

export const WrapCloseIconMenuMobile = styled.div({
  display: 'flex',
  marginTop: 8,
  marginLeft: 8,
  width: 24,
  height: 24,
});

export const WrapContentMenuMobile = styled.div({
  position: 'absolute',
  right: 0,
  zIndex: 999,
  top: 120,
  backgroundColor: (props) => props.backgroundColor,
  width: 60,
  borderRadius: '4px 0px 0px 4px',
  boxShadow: '-1px 1px 10px 2px rgba(80,66,75,0.18)',
});

export const SwipeViewOpenMenu = styled.div({
  position: 'absolute',
  height: 120,
  zIndex: 11,
  top: 120,
  right: 0,
  borderRadius: 4,
  [devices.tablet]: {
    width: 12,
  },
  [devices.mobileL]: {
    width: 12,
  },
  backgroundColor: (props) => props.backgroundColor,
  opacity: 0.8,
});

export const WrapProfileAuth0Content = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
});
