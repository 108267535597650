import { useAuth0 } from '@auth0/auth0-react';
import { Group, Popover, Select, Text } from '@mantine/core';
import i18next from 'i18next';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  CloseIcon,
  CollapseIcon,
  DefaultUserIcon,
  GearIcon,
  LanguageIcon,
  PalletIcon,
  SignOutIcon,
} from '../../constants/icon';
import {
  onHiddenProfileView,
  onResetOptionMenu,
} from '../../reducers/layoutReducer/action';
import {
  isPerspectivesPanelOpenSelector,
  isShowProfileViewSelector,
} from '../../reducers/layoutReducer/layoutSelector';
import { currentPaletteActiveSelector } from '../../reducers/themeReducers/themeSelector';
import ButtonIcon from '../Button/ButtonIcon';
import { TooltipButton } from '../layout/VerticalButtonBar';

import ButtonOpenProfile from './ButtonOpenProfile';
import {
  ContentContainer,
  ContentContainerSiOut,
  popoverStyle,
  ProfileAuthContainer,
  ProfileAuthIconDetail,
  ProfileAuthIconDetailDefault,
  ProfileAuthInfo,
  ProfileAuthInfoContainer,
  ProfileAuthInfoEmail,
  ProfileAuthInfoName,
  ProfileAuthPopoverContainer,
  ProfileCloseIconContainer,
  ProfileDivider,
  selectStyle,
  Space,
  WrapIconGear,
} from './ProfileAuth0Css';
import useLogicMenuOption from './useLogicMenuOption';
import { isMobileSelector } from '../../reducers/responsiveReducer/responsiveSelector';

const ProfileAuth0Content = ({ onCloseMenuMobile }) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const dispatch = useDispatch();
  const isPerspectivesPanelOpen = useSelector(isPerspectivesPanelOpenSelector);
  const [currentLanguage, setCurrentLanguage] = useState({});
  const isDevelopment = useSelector((state) => state.CONFIG.isDevelopment);
  const showModalProfile = useSelector(isShowProfileViewSelector);
  const currentPaletteActive = useSelector(currentPaletteActiveSelector);
  const primaryButton = currentPaletteActive?.button?.primaryButton;
  const { onSelectOption, currentOptionActive, onResetOptionMenuSelected } =
    useLogicMenuOption();
  const isMobile = useSelector(isMobileSelector);

  const { t } = useTranslation();
  const languages = [
    {
      id: 0,
      label: t('app.english'),
      value: 'en',
      flag: '🇬🇧',
    },
    {
      id: 1,
      label: t('app.french'),
      value: 'fr',
      flag: '🇫🇷',
    },
  ];
  const languagesVisible = useMemo(() => {
    return languages?.map((item) => {
      if (item.value === currentLanguage.value) {
        return {
          ...item,
          disabled: true,
        };
      }
      return item;
    });
  }, [currentLanguage]);
  const onClickCloseMenuMobile = () => {
    if (typeof onCloseMenuMobile === 'function') {
      onCloseMenuMobile();
    }
  };

  const onSetClose = useCallback(() => {
    onClickCloseMenuMobile();
    dispatch(onHiddenProfileView());
    dispatch(onResetOptionMenu());
  }, []);

  const onChangeLanguage = useCallback((value) => {
    const languageSelected = languages?.find((item) => item.value === value);
    i18next.changeLanguage(languageSelected.value).then((t) => {});
    localStorage.setItem(
      'localLanguage',
      JSON.stringify({ language: languageSelected })
    );
    onResetOptionMenuSelected();
  }, []);

  const getLanguage = () => {
    const languageSelected = languages?.find(
      (item) => item.value === i18next.language
    );
    return languageSelected;
  };
  useEffect(() => {
    setCurrentLanguage(getLanguage());
  }, [i18next.language]);

  const onShowSetting = useCallback(() => {
    onSelectOption('showSettings');
  }, []);
  const onShowLanguage = () => {
    onSelectOption('showSettingLanguage');
  };
  const onShowPalette = useCallback(() => {
    onSelectOption('showPalette');
    dispatch(onHiddenProfileView());
  }, []);

  const SelectItem = forwardRef(
    ({ flag, label, description, ...others }, ref) => {
      return (
        <div ref={ref} {...others}>
          <Group noWrap>
            <Text>{flag}</Text>
            <div>
              <Text fw={500} size="sm">
                {label}
              </Text>
            </div>
          </Group>
        </div>
      );
    }
  );
  const onSignOut = useCallback(() => {
    dispatch(onHiddenProfileView());
    dispatch(onResetOptionMenu());
    logout({ returnTo: window.location.origin });
  }, []);

  return (
    <ProfileAuthContainer>
      {!isMobile && (
        <TooltipButton label={t('app.profile')}>
          <ButtonOpenProfile />
        </TooltipButton>
      )}

      <Popover
        style={{
          position: 'relative',
          left: isPerspectivesPanelOpen ? 12 : 47,
          top: 8,
        }}
        id="profile-popover"
        width={240}
        position="left-start"
        shadow="md"
        opened={showModalProfile}
        positionDependencies={[isPerspectivesPanelOpen]}
        styles={{
          ...popoverStyle,
          body: {
            backgroundColor: currentPaletteActive?.body?.profileModalBackground,
            borderColor: currentPaletteActive?.border?.regular,
            boxShadow: currentPaletteActive?.border?.boxShadow,
          },
        }}
      >
        <ProfileAuthPopoverContainer>
          <div>
            <ProfileCloseIconContainer
              onClick={onSetClose}
              onTouchEnd={onSetClose}
            >
              <CloseIcon />
            </ProfileCloseIconContainer>
            {(isDevelopment ? true : isAuthenticated) ? (
              <div>
                <ProfileAuthInfoContainer>
                  {user?.picture ? (
                    <ProfileAuthIconDetail
                      width="44"
                      height="44"
                      src={user?.picture}
                      alt={user?.name}
                    />
                  ) : (
                    <ProfileAuthIconDetailDefault>
                      <DefaultUserIcon />
                    </ProfileAuthIconDetailDefault>
                  )}

                  <ProfileAuthInfo>
                    <ProfileAuthInfoName
                      color={currentPaletteActive?.text?.primary}
                    >
                      {user?.name || 'Unnamed'}
                    </ProfileAuthInfoName>
                    <ProfileAuthInfoEmail
                      color={currentPaletteActive?.text?.secondary}
                    >
                      {user?.email || 'no-email@io.land'}
                    </ProfileAuthInfoEmail>
                  </ProfileAuthInfo>
                </ProfileAuthInfoContainer>
                <ProfileDivider
                  borderColor={currentPaletteActive?.border?.regular}
                  boxShadow={currentPaletteActive?.border?.boxShadow}
                />
              </div>
            ) : (
              <Space height={16} />
            )}
          </div>
          <div>
            <ContentContainer>
              {!isMobile && (
                <>
                  <ButtonIcon
                    leftIcon={
                      <WrapIconGear>
                        <GearIcon
                          fill={currentPaletteActive?.text?.secondary}
                        />
                      </WrapIconGear>
                    }
                    onClick={onShowSetting}
                    label={t('app.setting')}
                    isActive={currentOptionActive === 'showSettings'}
                  />
                  <Space height={12} />
                </>
              )}
              <ButtonIcon
                leftIcon={
                  <div>
                    <PalletIcon
                      fill={currentPaletteActive?.text?.secondary}
                      fill2={currentPaletteActive?.text?.disable}
                    />
                  </div>
                }
                onClick={onShowPalette}
                label="Palette"
                isActive={currentOptionActive === 'showPalette'}
              />
              <Space height={12} />

              <div onClick={onShowLanguage}>
                <Select
                  transition="pop-top-left"
                  transitionDuration={80}
                  transitionTimingFunction="ease"
                  itemComponent={SelectItem}
                  onChange={onChangeLanguage}
                  value={currentLanguage.value}
                  rightSection={
                    <CollapseIcon
                      fill={currentPaletteActive?.text?.secondary}
                    />
                  }
                  icon={
                    <LanguageIcon
                      fill={currentPaletteActive?.text?.secondary}
                    />
                  }
                  placeholder="Select language"
                  data={languagesVisible}
                  styles={{
                    ...selectStyle,
                    input: {
                      ...selectStyle.input,
                      backgroundColor:
                        currentOptionActive === 'showSettingLanguage'
                          ? primaryButton?.backgroundColorActive
                          : primaryButton?.backgroundColor,
                      '&:hover': {
                        backgroundColor: primaryButton?.backgroundColorHover,
                      },
                      color: currentPaletteActive?.text?.secondary,
                      fontWeight: 500,
                    },
                    dropdown: {
                      backgroundColor:
                        currentPaletteActive?.select?.backgroundColor,
                      borderColor: currentPaletteActive?.border?.regular,
                      boxShadow: currentPaletteActive?.border?.boxShadow,
                    },

                    item: {
                      color: currentPaletteActive?.text?.primary,
                    },
                    disabled: {
                      color: currentPaletteActive?.text?.disable,
                    },
                  }}
                />
              </div>
            </ContentContainer>

            {(isDevelopment ? true : isAuthenticated) ? (
              <div>
                <Space height={60} />
                <ProfileDivider
                  mt={8}
                  mb={8}
                  borderColor={currentPaletteActive?.border?.regular}
                  boxShadow={currentPaletteActive?.border?.boxShadow}
                />
                <ContentContainerSiOut>
                  {
                    <ButtonIcon
                      leftIcon={
                        <SignOutIcon
                          fill={currentPaletteActive?.text?.secondary}
                        />
                      }
                      onClick={onSignOut}
                      label={t('display.logout')}
                    />
                  }
                </ContentContainerSiOut>
              </div>
            ) : (
              <Space height={100} />
            )}
          </div>
        </ProfileAuthPopoverContainer>
      </Popover>
    </ProfileAuthContainer>
  );
};

export default memo(ProfileAuth0Content);
