import styled from 'styled-components';

export const StyledImgContainer = styled.div({
  '&:hover .xtooltip': {
    opacity: '1 !important',
    display: 'block !important',
  },
});
//-----------------------------------------------------------------------------------------
export const StyledImg = styled.img({
  cursor: 'pointer',
  width: 18,
  height: 18,
  margin: '3px',
  opacity: '0.6',
  border: (props) => (props.withBorders ? '1px solid red' : '0px'),
  ':hover': {
    opacity: '1',
  },
});
export const ProgressBarContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: '20px',
  margin: '5px',
  alignItems: 'center',
  width: '95%',
});

export const ProgressBarLabel = styled.div({
  alignSelf: 'center',
  width: '40%',
  padding: '4px',
  fontSize: (props) => `${props.fontSize}px`,
  fontWeight: 'normal',
  color: (props) => props.color,
});

export const ProgressViewContainer = styled.div({
  minWidth: (props) => props.minWidth,
  maxWidth: '60%',
  paddingLeft: '0px',
  paddingRight: '0px',
  borderRadius: '1px',
  bottom: '0px',
  border: '0px solid #E6E5F1',
  height: '3px',
  backgroundColor: '#EEEEEE',
  position: 'relative',
});

export const ProgressViewValue = styled.div({
  color: (props) => props.color,
  fontSize: (props) => `${props.fontSize}px`,
  fontWeight: 'normal',
  display: 'flex',
  justifyContent: 'left',
  padding: '0px',
  backgroundColor: (props) => props.backgroundColor,
  border: '0px solid #E6E5F1',
  borderRadius: '1px',
  height: '3px',
  width: (props) => props.width,
});
export const ProgressViewValueInPerc = styled.div({
  alignSelf: 'center',
  fontSize: (props) => `${props.fontSize}px`,
  fontWeight: 'normal',
  paddingLeft: '5px',
  width: '15%',
  color: (props) => props.color,
});

export const ProgressBarsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: '20px',
  margin: '5px',
  alignItems: 'center',
  width: '99%',
});

export const ProgressBarsLabel = styled.div({
  alignSelf: 'center',
  width: '40%',
  overflow: 'hidden',
  border: '0px solid red',
  padding: '4px',
  textOverflow: 'ellipsis',
  fontSize: (props) => `${props.fontSize}px`,
  fontWeight: 'normal',
  color: (props) => props.color || '#333',
});

export const ProgressBarsViewContainer = styled.div({
  minWidth: '40%',
  paddingLeft: '0px',
  paddingRight: '0px',
  borderRadius: '1px',
  bottom: '0px',
  border: '0px solid #E6E5F1',
  height: '5px',
  backgroundColor: '#EEEEEE',
  position: 'relative',
  display: 'inline-flex',
});

export const ProgressBarsViewValue = styled.div({
  color: (props) => props.color,
  fontSize: (props) => `${props.fontSize}px`,
  fontWeight: 'normal',
  display: 'inline',
  padding: '0px',
  backgroundColor: (props) => props.backgroundColor,
  border: '0px solid #E6E5F1',
  borderRadius: '3px',
  height: '6px',
  width: (props) => props.width,
});

export const ProgressBarsViewValuePerc = styled.div({
  alignSelf: 'center',
  fontSize: (props) => `${props.fontSize}px`,
  fontWeight: 'normal',
  paddingLeft: '5px',
  width: '15%',
  color: (props) => props.color,
});
