/* eslint-disable node/no-unsupported-features/node-builtins */
import styled from 'styled-components';
import { ipServer } from '../../api/ObjectApi';

export const ModalLibraryIconContainer = styled.div({
  height: (props) => props.height,
  display: 'flex',
  flexDirection: 'row',
  maxHeight: 850,
  minHeight: 400,
});

export const CategoriesLibraryIconsContainer = styled.div({
  width: 250,
  height: '100%',
  padding: 12,
  borderTopLeftRadius: 6,
  borderBottomLeftRadius: 6,
  border: '1px solid #E2E8F0',
  background: (props) => props.background,
  border: (props) => props.border,
});

export const CategoriesLibraryCategory = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 8,
});

export const buttonStyleCategoryIconStyle = {
  width: '100%',
  height: 'auto',
  columnGap: 8,
  padding: 6,
  justifyContent: 'flex-start',
};
export const modalDropZoneIconStyle = {
  border: '1px dashed #CBD5E1',
  backgroundColor: 'transparent',
  maxHeight: 300,
  height: '100%',
  maxWidth: '100%',
  width: '100%',
  padding: 0,
};

export const ModalDropZoneButtonAddTag = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ModalDropZoneAddTagContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  columnGap: 8,
  flexWrap: 'wrap',
  rowGap: 8,
});

export const ModalDropZoneGalleryIcon = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 112,
  height: 112,
  maxWidth: 112,
  maxHeight: 112,
});

export const ModalDropZonePreviewImage = styled.div({
  backgroundImage: (props) =>
    !props.isHaveNewFile
      ? `url('${ipServer + props?.file}')`
      : `url(${URL?.createObjectURL(props?.file)})`,
  width: '100%',
  height: 250,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
});

export const ModalDropZoneButtonUploadIcon = styled.div({
  width: '100%',
  height: 70,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const WrapButtonCategory = styled.div({
  width: '100%',
  display: 'flex',
  backgroundColor: (props) => props.backgroundColor,
  flexDirection: 'row',
  ':hover': {
    backgroundColor: (props) => props.backgroundColorHover,
  },

  alignItems: 'center',
  padding: '5px 10px',
  borderRadius: 4,
  border: (props) => `0.1px solid ${props?.borderColor}`,
});
