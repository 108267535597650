import produce from 'immer';
import { getRealPatternFromPrefixes } from '../../components/Utils/NodeUtils';
import { getTypeOfField } from '../../components/Utils/Utils.js';
import { events } from '../../constants/event';
import { fixed_field_type } from '../../constants/field';
import { layout } from '../../constants/layout';
import {
  calculateGirlViewAdditionalColumn,
  extractDicoFormObjFormData,
  extractDicoFullFormObjFormData,
  getTodayDate,
  preParseDataOpenPerspective,
  replaceObj,
  retrieveObj,
} from '../../utils/commonFunctionObjectsReducer';
import { OBJECTS_TYPE } from './type';
import { parseJson } from '../../utils/parseJson.js';

let res;

const contextTable = ['nbChild', 'nbRandom', 'meteo', 'document', 'risk'];

const objects = (
  state = {
    currObjNbrChilds: -1,
    fathers: [],
    paging_excluded: ['ids1', 'id2'],
    paging_map: [{ objId: 'cli', skip: 5 }],
    nodes_position: [],
    objectsDefList: [],
    isLoadingObjectsDefList: false,
    childsCountByType: [[], [], []], // by level
    isGettingState: false,
    objectsReceived: [],
    field_type: fixed_field_type,
    cue: 1000,
    selectedConfig: 'config1',
    pattern:
      process.env.NODE_ENV === 'development' ? 'xxx_xxx_xxx' : 'none_none_none',
    restrictions: [],
    timeline_date: getTodayDate(),
    crumbTrail: null,
    menuPosX: 0,
    menuPosY: 0,
    isFetching: false,
    context: 'nbChild',
    contextInd: 0,
    classifX: 'assignee',
    classifY: null,
    globalValues: { aaa: 0 },
    filters:
      process.env.NODE_ENV === 'development'
        ? { level0: 'Client', level1: 'Project', level2: 'Task' }
        : { level0: 'none', level1: 'none', level2: 'none' },
    hoverNode: 'XXXOOOXXXX',
    loggedUser: null,
    dragged_node_key: null,
    dragged_over: null,
    dragged_node_origin_panel: null,
    events: events,
    filteredNodes: [],
    nodes: [],
    collapsedNodes: [],
    layers: [],
    objDefFormData: [],
    objFormData: [],
    objFormFieldEdited: 'random',
    objLayersRead: [], // layers attached to the objForm selected
    objLayersWrite: [],
    usedLayers: [],
    dicoNameRef: {},
    dicoFullRef: {},
    arrayParamClassif: [],
    arrayParamClassifDetails: [],
    arrayStatBy: ['effort', 'effort', 'effort'],
    arraySortBy: ['name', 'globeffort', 'effort'],
    arrayClassifBy: ['default', 'default', 'default'],
    displayMode: ['card', 'card', 'card'],
    dataColumns: [[], [], []],
    dataLines: [[], [], []],
    activePanel: 0,
    activeBand: null,
    objId: null,
    objTypeId: null,
    objStack: [], // used to store references when an object is open from anohter one
    objToCreate: null,
    perspectiveId: null,
    perspectiveName: '[----]',
    arraycolor: '#1C7ED6',
    arraytype: 'classic',
    openModule: 'INV',
    perspectives: [],
    isLoadingPerspectives: true,
    panels: layout.bands_config,
    configure_objId: null,
    social_content: [],
    scrollListeners: [],
    initDone: false,
    isLoadingCrumbTrail: false,
    isOpenActivityFromVertical: false,
    isLoadingObjData: false,
    isLoadingTree: { 0: false, 1: false, 2: false },
    isLoadingObjectConfig: false,
  },
  action,
  root
) => {
  switch (action.type) {
    case OBJECTS_TYPE.INIT_DONE:
      return produce(state, (draft) => {
        draft.initDone = true;
      });

    case OBJECTS_TYPE.SET_LOADING_RECEIVE_TREE: {
      if (typeof action.level !== 'undefined') {
        return produce(state, (draft) => {
          draft.isLoadingTree[action.level] = action.value;
        });
      }
      return state;
    }

    case OBJECTS_TYPE.SET_PAGING:
      return produce(state, (draft) => {
        const { objId } = action;
        const newValue =
          (draft.paging_map.find((a) => a.objId === objId)?.skip ?? 0) + 5;
        let newMap = draft.paging_map.filter((a) => a.objId !== objId);
        newMap = [...newMap, { objId: action.objId, skip: newValue }];
        const paging_excluded = [
          ...draft.paging_excluded,
          ...retrieveObj(draft.nodes, action.objId)?.children?.map(
            (item) => item.key
          ),
        ];
        draft.paging_map = newMap;
        draft.paging_excluded = [...new Set(paging_excluded)];
      });
    case OBJECTS_TYPE.GET_STATE:
      return produce(state, (draft) => {
        draft.isGettingState = true;
      });
    case OBJECTS_TYPE.RECEIVED_STATE:
      return produce(state, (draft) => {
        draft = action.objects;
        draft.isGettingState = true;
      });
    case OBJECTS_TYPE.SET_NODES_POSITION:
      return produce(state, (draft) => {
        const { nodes } = action;
        const allNodes = [...nodes];
        const res = [];
        while (allNodes.length > 0) {
          const elem = allNodes.pop();
          res.push({
            key: elem.data.key,
            y: elem.x,
            x: elem.offsetY,
            data: elem.data,
            height: elem.xSize,
            depth: elem.depth,
          });
          if (elem.children) {
            allNodes.push(...elem.children);
          }
        }
        draft.nodes_position = res;
      });
    case OBJECTS_TYPE.GOT_NBR_CHILD:
      return produce(state, (draft) => {
        draft.currObjNbrChilds = action.objects.nbrChilds;
      });
    case OBJECTS_TYPE.ADD_SCROLLLISTENER:
      return produce(state, (draft) => {
        draft.scrollListeners.push(action.callback);
      });
    case OBJECTS_TYPE.REMOVE_SCROLLLISTENER:
      return produce(state, (draft) => {
        draft.scrollListeners = draft.scrollListeners.filter(
          (a) => a !== action.callback
        );
      });
    case OBJECTS_TYPE.CONFIGURE_SELECT_TAB:
      return produce(state, (draft) => {
        draft.configure_tab = action.ind;
      });
    case OBJECTS_TYPE.SET_ACTIVE_PANEL:
      return produce(state, (draft) => {
        draft.activePanel = action.level;
        draft.classifX = draft.arrayClassifBy[action.level];
      });

    case OBJECTS_TYPE.SET_ACTIVE_BAND:
      return produce(state, (draft) => {
        draft.activePanel = action.level;
        draft.activeBand = action.band;
      });
    case OBJECTS_TYPE.SET_SORT_BY:
      return produce(state, (draft) => {
        draft.arraySortBy[action.level] = action.sortKey;
      });

    case OBJECTS_TYPE.TOGGLE_DISPLAY_MODE:
      return produce(state, (draft) => {
        draft.displayMode[action.level] =
          draft.displayMode[action.level] === 'grid' ? 'card' : 'grid';
      });

    case OBJECTS_TYPE.SET_DISPLAY_MODE:
      return produce(state, (draft) => {
        draft.displayMode[action.level] = action.mode;
        draft.dataColumns = draft.dataColumns.reduce((arr, item, ind) => {
          if (draft.displayMode[ind].includes('grid')) arr.push(item);
          else arr.push([]);
          return arr;
        }, []);
        draft.dataLines = draft.dataColumns.reduce((arr, item, ind) => {
          if (draft.displayMode[ind].includes('card')) arr.push(item);
          else arr.push([]);
          return arr;
        }, []);
        draft.arrayClassifBy = draft.arrayClassifBy.reduce((arr, item, ind) => {
          draft.displayMode[ind] === 'card'
            ? arr.push(item)
            : arr.push('default');
          return arr;
        }, []);
        draft.classifX =
          action.mode === 'grid'
            ? 'default'
            : draft.arrayClassifBy[action.level];
      });
    case OBJECTS_TYPE.SET_STAT_BY:
      return produce(state, (draft) => {
        draft.arrayStatBy[action.level] = action.statKey;
        draft.arrayClassifBy[action.level] = action.statKey;
        draft.classifX = action.statKey;
      });

    case OBJECTS_TYPE.SET_ARRAY_PARAM_CLASSIF:
      return produce(state, (draft) => {
        draft.arrayParamClassif = action.arrayParamClassif;
        draft.arrayParamClassifDetails = action.arrayParamClassifDetails;
      });

    case OBJECTS_TYPE.RESET_ACTIVE_PANEL:
      return produce(state, (draft) => {
        draft.activePanel = 0;
      });
    case OBJECTS_TYPE.SET_CUE:
      return produce(state, (draft) => {
        draft.cue = action.value;
      });
    case OBJECTS_TYPE.COLLAPSE_NODE:
      return produce(state, (draft) => {
        draft.collapsedNodes.push(action.objId);
      });
    case OBJECTS_TYPE.COLLAPSE_NODES:
      return produce(state, (draft) => {
        draft.collapsedNodes.push(...action.nodes);
      });
    case OBJECTS_TYPE.EXPAND_NODE:
      return produce(state, (draft) => {
        draft.collapsedNodes = draft.collapsedNodes.filter(
          (e) => e !== action.objId
        );
      });
    case OBJECTS_TYPE.EXPAND_NODES:
      return produce(state, (draft) => {
        draft.collapsedNodes = draft.collapsedNodes.filter(
          (e) => !action.nodes.includes(e)
        );
      });
    case OBJECTS_TYPE.SEC_CURRENT_CONFIG:
      return produce(state, (draft) => {
        draft.selectedConfig = action.value;
      });
    case OBJECTS_TYPE.TIMELINE_UPDAY:
      return produce(state, (draft) => {
        draft.timeline_date = new Date(
          draft.timeline_date.getTime() + 1000 * 60 * 60 * 24 * 7
        );
      });
    case OBJECTS_TYPE.TIMELINE_DOWNDAY:
      return produce(state, (draft) => {
        draft.timeline_date = new Date(
          draft.timeline_date.getTime() - 1000 * 60 * 60 * 24 * 7
        );
      });

    case OBJECTS_TYPE.TOGGLE_ADDITIONAL_COLUMN: {
      const { objectType, column } = action;
      let activePanelDataColumns = state.dataColumns[state.activePanel];
      activePanelDataColumns = activePanelDataColumns.filter(
        (a) => a.data !== action.column
      );
      const isElemRemoved =
        activePanelDataColumns.length !==
        state.dataColumns[state.activePanel].length;
      if (!isElemRemoved) {
        if (column === 'layers') {
          for (const aLayerInd in state.layers) {
            const aLayer = state.layers[aLayerInd];
            activePanelDataColumns.push({
              w: 60,
              to: objectType,
              data: 'layers',
              type: 'yesno',
              operation: 'contains',
              value: aLayer.key,
              attrName: aLayer.name,
            });
          }
        } else {
          const typeOfficial = getTypeOfField(
            state.objectsDefList,
            objectType,
            column
          );
          activePanelDataColumns = calculateGirlViewAdditionalColumn({
            typeOfficial,
            activePanelDataColumns,
            objectType,
            column,
          });
        }
      }

      const arrayDataColumns = [...state.dataColumns];
      arrayDataColumns[state.activePanel] = activePanelDataColumns;
      return { ...state, dataColumns: arrayDataColumns };
    }

    case OBJECTS_TYPE.TOGGLE_ADDITIONAL_LINE: {
      // ndl = new data line to be displyaed in node
      let activePanelDataLines = [];
      try {
        if (state.dataLines && state.dataLines[state.activePanel]) {
          activePanelDataLines = state.dataLines[state.activePanel];
        }
      } catch (e) {}

      activePanelDataLines = activePanelDataLines.filter(
        (a) => a.data !== action.line
      );
      const isElemRemoved =
        activePanelDataLines.length !==
        state.dataLines[state.activePanel]?.length;
      if (!isElemRemoved) {
        if (action.line !== 'layers') {
          activePanelDataLines = [
            ...activePanelDataLines,
            { to: action.objectType, data: action.line, h: 1, type: 'text' },
          ];
        }
      }

      const arrayDataLines = [...state.dataLines];
      arrayDataLines[state.activePanel] = activePanelDataLines;
      return { ...state, dataLines: arrayDataLines };
    }

    case OBJECTS_TYPE.RESET_ADDITIONAL_LINES:
      return produce(state, (draft) => {
        draft.dataLines[draft.activePanel] = [];
      });
    case OBJECTS_TYPE.RESET_ADDITIONAL_COLUMNS:
      return produce(state, (draft) => {
        draft.dataColumns[draft.activePanel] = [];
      });
    case 'CHECK_LOGIN':
      return produce(state, (draft) => {
        draft.loggedUser = '';
        draft.nodes = [];
      });
    case OBJECTS_TYPE.SET_HOVER_NODE:
      return produce(state, (draft) => {
        draft.hoverNode = action.objId;
      });
    case OBJECTS_TYPE.CLEAR_FIELD_RESTRICTION:
      return produce(state, (draft) => {
        draft.restrictions = [];
      });
    case OBJECTS_TYPE.ADD_FIELD_RESTRICTION:
      return produce(state, (draft) => {
        const newRest = [
          ...draft.restrictions,
          {
            [action.field]: action.value,
            objectType: action.objectType,
            comparator: action.comparator,
          },
        ];
        draft.restrictions = newRest;
      });
    case OBJECTS_TYPE.CREATED_FROM_OBJ_DEF:
      return produce(state, (draft) => {
        draft.objId = action.objects[0].newObjId;
      });

    case OBJECTS_TYPE.POST_CREATED_FROM_OBJ_DEF:
      return produce(state, (draft) => {});

    case OBJECTS_TYPE.CREATED_PERSP_FROM_OBJ_DEF:
      return produce(state, (draft) => {
        draft.objId = action.objects[0].newObjId;
        draft.perspectiveId = action.objects[0].newObjId;
      });

    case OBJECTS_TYPE.LINK_FIELD:
      let fieldName = action.fieldName;
      let labelToLink = root.OBJECT_SELECTOR.labelSelected.trim();
      let drawingToLink = root.OBJECT_SELECTOR.selectedDrawingItem.trim();
      let selectedObject =
        root.OBJECT_SELECTOR.objectSelectorState.idSelectedObject.trim();

      // we find the index and default value of the field from the global object
      const svgToLinkPadIndex = root.objects.objFormData.findIndex(
        (obj) => obj.attrName.trim() === labelToLink
      );
      let defaultValueStrToLink =
        root.objects.objFormData[svgToLinkPadIndex].value;
      let defaultValueObjToLink = parseJson(defaultValueStrToLink);

      let drawingToLinkInd = defaultValueObjToLink.findIndex(
        (obj) => obj.key === drawingToLink
      );

      // in the default value we set the link
      defaultValueObjToLink[
        drawingToLinkInd
      ].points = `${selectedObject}|${fieldName}`;
      defaultValueObjToLink[
        drawingToLinkInd
      ].calculated = `${selectedObject}|${fieldName}`;

      // we udpate the object with the new value with the link
      return produce(state, (draft) => {
        draft.objFormData[svgToLinkPadIndex].value = JSON.stringify(
          defaultValueObjToLink
        );
        draft.objFormData[svgToLinkPadIndex].defaultValue = JSON.stringify(
          defaultValueObjToLink
        );
      });

    case OBJECTS_TYPE.LINK_OBJECT:
      let labelSelected = root.OBJECT_SELECTOR.labelSelected.trim();
      let selectedDrawingItem = root.OBJECT_SELECTOR.selectedDrawingItem.trim();
      // we find the index and default value of the field from the global object
      const svgPadIndex = root.objects.objFormData.findIndex(
        (obj) => obj.attrName.trim() === labelSelected
      );
      let defaultValueStr = root.objects.objFormData[svgPadIndex].value;
      let defaultValueObj = parseJson(defaultValueStr);

      let selectDrawingItemInd = defaultValueObj.findIndex(
        (obj) => obj.key === selectedDrawingItem
      );

      // in the default value we set the link
      defaultValueObj[selectDrawingItemInd].link = action.objId;

      // we udpate the object with the new value with the link
      return produce(state, (draft) => {
        draft.objFormData[svgPadIndex].value = JSON.stringify(defaultValueObj);
        draft.objFormData[svgPadIndex].defaultValue =
          JSON.stringify(defaultValueObj);
      });

    case OBJECTS_TYPE.DELETE_OBJECT:
      return produce(state, (draft) => {
        draft.objFormData = '';
        draft.objId = '';
      });

    case OBJECTS_TYPE.UNSTACK_OBJECT:
      return produce(state, (draft) => {
        const poped = draft.objStack.pop();
        draft.objId = poped.objId;
        draft.objTypeId = poped.objTypeId;
      });
    case OBJECTS_TYPE.STACK_OBJECT:
      return produce(state, (draft) => {
        draft.objStack.push({ objId: draft.objId, objTypeId: draft.objTypeId });
      });
    case OBJECTS_TYPE.SELECT_OBJECT:
      return produce(state, (draft) => {
        draft.objId = action.objId ?? '';
        draft.objTypeId = action.objId ? draft.objTypeId : '';
        draft.objStack = [];
      });
    case OBJECTS_TYPE.SELECTED_OBJECT:
      return produce(state, (draft) => {
        draft.objFormData = action.objects.objFormData;
        // draft.objLayers = action.objects.layers;
        draft.objLayersRead = action.objects.layersRead;
        draft.objLayersWrite = action.objects.layersWrite;
        draft.objTypeId = action.objects.type;
        draft.objId = action.objects.objId ?? draft.objId;
        draft.buttons = action.objects.buttons;
        draft.workflow = action.objects.workflow;
        draft.state = action.objects.state;
        draft.nodes = replaceObj(
          draft.nodes,
          action.objects,
          action.objects.objId
        );
        draft.dicoNameRef = {
          ...draft.dicoNameRef,
          ...extractDicoFormObjFormData(action.objects.objFormData),
        };
        draft.dicoFullRef = {
          ...draft.dicoFullRef,
          ...extractDicoFullFormObjFormData(action.objects.objFormData),
        };
      });
    case OBJECTS_TYPE.CLEAN_OBJECT: {
      return produce(state, (draft) => {
        draft.objFormData = [];
        draft.objLayersRead = [];
        draft.objLayersWrite = [];
        draft.objTypeId = null;
        draft.objId = null;
        draft.crumbTrail = null;
      });
    }
    case OBJECTS_TYPE.SET_LOADING_STATUS: {
      return produce(state, (draft) => {
        draft.isLoadingObjData = action.status;
      });
    }

    case 'SET_OBJECTFORM_FIELD_EDITED': {
      return produce(state, (draft) => {
        draft.objFormFieldEdited = action.fieldName;
      });
    }
    case OBJECTS_TYPE.SAVE_OBJECTFORMDATA:
      return produce(state, (draft) => {
        draft.objFormData = action.objects;
        draft.nodes = replaceObj(draft.nodes, action.objects, action.objId);
      });
    case OBJECTS_TYPE.ADDED_NODE_TO_LAYER:
      return produce(state, (draft) => {
        draft.objFormData = action.objects.objFormData;
        draft.objId = action.objId ?? draft.objId;
        draft.nodes = replaceObj(draft.nodes, action.objects, draft.objId);
      });
    case OBJECTS_TYPE.SELECTED_DEF_OBJECT:
      return produce(state, (draft) => {
        draft.objDefFormData = action.objects.objFormData;
      });

    case OBJECTS_TYPE.SAVE_CURRENT_PERSPECTIVE:
      return produce(state, (draft) => {});

    case OBJECTS_TYPE.SET_OPEN_MODULE:
      return produce(state, (draft) => {
        draft.openModule = action.moduleId;
      });

    case OBJECTS_TYPE.SET_PANELS:
      return produce(state, (draft) => {
        draft.panels = action.bands_config;
      });

    case OBJECTS_TYPE.GOT_FATHER:
      return produce(state, (draft) => {
        draft.fathers = [...state.fathers, { ...action.objects }];
      });

    case OBJECTS_TYPE.SHIFT_PERSPECTIVE_DOWN:
      return produce(state, (draft) => {
        const father = state.fathers.find(
          (elem) => elem.objId === action.objId
        );
        if (father) {
          const { fatherType } = father;
          const filters1 = {};
          draft.panels = [{ width: 500 }, ...state.panels];

          draft.restrictions = [[], ...state.restrictions];
          draft.restrictions.pop();
          draft.dataLines = [[], ...state.dataLines];
          draft.dataLines.pop();
          draft.dataLines.push([]);
          draft.dataColumns = [[], ...state.dataColumns];
          draft.dataColumns.pop();

          draft.displayMode = ['card', ...state.displayMode];

          let temp = state.pattern;
          temp = temp.split('_');
          temp.pop();
          draft.pattern = `${fatherType}_${temp.join('_')}`;

          draft.pattern.split('_').map((item, ind) => {
            filters1[`level${ind}`] = item;
            return null;
          });
          draft.filters = filters1;
        }
      });

    case OBJECTS_TYPE.SHIFT_PERSPECTIVE_UP:
      return produce(state, (draft) => {
        const filters1 = {};
        draft.panels = [...state.panels];
        draft.panels.shift();
        draft.panels.push({ width: 500 });
        draft.restrictions = [...state.restrictions];
        draft.restrictions.shift();
        draft.dataLines = [...state.dataLines];
        draft.dataLines.shift();
        draft.dataLines.push([]);
        draft.dataColumns = [...state.dataColumns];
        draft.dataColumns.shift();
        draft.dataColumns.push([]);
        draft.displayMode = [...state.displayMode];
        draft.displayMode.shift();
        draft.displayMode.push('card');
        let temp = state.pattern;
        temp = temp.split('_');
        temp.shift();
        draft.pattern = `${temp.join('_')}_none`;
        draft.pattern.split('_').map((item, ind) => {
          filters1[`level${ind}`] = item;
          return null;
        });
        draft.filters = filters1;
      });

    case OBJECTS_TYPE.OPEN_PERSPECTIVE:
      const { objId } = state;
      const res = {
        ...state,
        ...preParseDataOpenPerspective({ action, objId }),
      };
      return res;

    case OBJECTS_TYPE.RECEIVED_OBJECTS:
      return produce(state, (draft) => {
        draft.objectsReceived = action.objects;
        draft.dicoNameRef = {
          ...draft.dicoNameRef,
          ...extractDicoFormObjFormData(action.objects.objFormData),
        };
      });

    case OBJECTS_TYPE.REFRESHED_OBJECT:
      return produce(state, (draft) => {
        draft.nodes = replaceObj(
          draft.nodes,
          action.objects,
          action.objects.objId
        );
        draft.dicoNameRef = {
          ...draft.dicoNameRef,
          ...extractDicoFormObjFormData(action.objects.objFormData),
        };
        draft.dicoFullRef = {
          ...draft.dicoFullRef,
          ...extractDicoFullFormObjFormData(action.objects.objFormData),
        };
      });
    case OBJECTS_TYPE.REQUEST_TREE_BY_PATTERN:
      return produce(state, (draft) => {
        if (action.pattern !== draft.pattern) {
          action.pattern.split('_').map((item, ind) => {
            draft.filters[`level${ind}`] = item;
            return null;
          });
          draft.pattern = action.pattern;
        }
      });

    case OBJECTS_TYPE.STARTED_DRAGGING_NODE: {
      console.log('action______0');
      return produce(state, (draft) => {
        draft.dragged_node_key = action.key;
        draft.draggedObjectFormData = action.promise;
        draft.dragged_node_origin_panel = draft.activePanel;
      });
    }

    case OBJECTS_TYPE.RECEIVED_DRAGGED_OBJECT: {
      console.log('action______1');

      return produce(state, (draft) => {
        draft.draggedObjectFormData = action.objects.objFormData;
      });
    }

    case OBJECTS_TYPE.SET_DRAGGED_OVER:
      return produce(state, (draft) => {
        draft.dragged_over = action.objId;
      });

    case OBJECTS_TYPE.SET_DRAGGED_OBJECT: {
      console.log('action______2');

      return produce(state, (draft) => {
        const arrayName = action.objFormData.filter(
          (item) => item.attrName === 'name'
        );
        const key = arrayName[0].key.substring(0, 9);
        draft.nodes = replaceObj(draft.nodes, action.objFormData, key);
        draft.draggedObjectFormData = action.objFormData;
      });
    }

    case OBJECTS_TYPE.SET_SELECTED_OBJECT:
      return produce(state, (draft) => {
        const arrayName = action.objFormData.filter(
          (item) => item.attrName === 'name'
        );
        const key = arrayName[0].key.substring(0, 9);
        draft.nodes = replaceObj(draft.nodes, action.objFormData, key);
        draft.objFormData = action.objFormData;
      });

    case OBJECTS_TYPE.SET_GLOBAL_VALUE:
      return produce(state, (draft) => {
        draft.globalValues[action.globValueId] = action.val;
      });

    case OBJECTS_TYPE.SET_CLASSIFX:
      return produce(state, (draft) => {
        draft.arrayClassifBy = ['default', 'default', 'default'].map(
          (item, ind) =>
            ind === draft.activePanel
              ? action.paramClassifX
              : draft.arrayClassifBy[ind] ?? item
        );
        draft.classifX = draft.arrayClassifBy[draft.activePanel];
      });

    case OBJECTS_TYPE.SET_CLASSIFY:
      return produce(state, (draft) => {
        draft.classifY = action.paramClassifY;
      });

    case OBJECTS_TYPE.SELECT_CONTEXT:
      return produce(state, (draft) => {
        draft.context = draft.context == 'nbChild' ? 'nbRandom' : 'nbChild';
      });

    case OBJECTS_TYPE.UP_CONTEXT:
      return produce(state, (draft) => {
        draft.contextInd = (draft.contextInd + 1) % contextTable.length;
        draft.context = contextTable[draft.contextInd];
      });
    case OBJECTS_TYPE.DOWN_CONTEXT:
      return produce(state, (draft) => {
        draft.contextInd =
          draft.contextInd - 1 < 0
            ? contextTable.length - 1
            : state.contextInd - 1;
        draft.context = contextTable[draft.contextInd];
      });

    case OBJECTS_TYPE.RECEIVE_EVENTS:
      return produce(state, (draft) => {
        draft.events = { list: action.objects.list };
      });
    case OBJECTS_TYPE.RECEIVE_TREE:
      return produce(state, (draft) => {
        draft.nodes = action.objects;

        draft.node0 = action.objects?.[0]?.key ?? null;
        draft.dicoNameRef = {
          ...draft.dicoNameRef,
          ...extractDicoFormObjFormData(draft.objFormData),
        };
      });
    case OBJECTS_TYPE.RECEIVED_PERSPECTIVES:
      return produce(state, (draft) => {
        draft.perspectives = action.objects;
      });
    case OBJECTS_TYPE.SET_LOADING_PERSPECTIVE:
      return produce(state, (draft) => {
        draft.isLoadingPerspectives = action.value;
      });
    case OBJECTS_TYPE.RECEIVE_SEARCH_RESULT:
      return produce(state, (draft) => {
        draft.nodes = action.objects;
        draft.pattern = 'all_all_all';
        draft.filters = { level0: 'all', level1: 'all', level2: 'all' };
      });
    case OBJECTS_TYPE.RECEIVE_LAYERS:
      return produce(state, (draft) => {
        draft.layers = action.objects;
      });
    case OBJECTS_TYPE.FILTER_SET_LEVEL:
      return produce(state, (draft) => {
        draft.filters[`level${action.level}`] = action.val;
        // Set values with indices greater than action.level to "none"
        for (let i = action.level + 1; i < 3; i++) {
          draft.filters[`level${i}`] = 'none';
        }
        draft.pattern = `${draft.filters.level0}_${draft.filters.level1}_${draft.filters.level2}`;
      });
    case OBJECTS_TYPE.RECEIVED_CRUMBTRAIL:
      return produce(state, (draft) => {
        draft.crumbTrail = action.objects;
        draft.isLoadingCrumbTrail = false;
      });
    case OBJECTS_TYPE.RECEIVED_SOCIAL:
      return produce(state, (draft) => {
        draft.social_content = action.objects;
      });
    case OBJECTS_TYPE.RECEIVE_OBJECTS:
      return produce(state, (draft) => {
        const odl = draft.nodes;
        const listNode = new Array();
        listNode.push.apply(listNode, odl);
        let stop = false;
        while (!stop && !listNode.length === 0) {
          const currObj = listNode.pop();

          if (currObj.key === action.objId) {
            stop = true;
            currObj.children = action.objects;
          } else if (currObj.children !== null)
            listNode.push.apply(listNode, currObj.children);
        }
        draft.nodes = listNode;
      });
    case OBJECTS_TYPE.SET_STATUS_OBJECT_DEFINITION:
      return produce(state, (draft) => {
        draft.isLoadingObjectConfig = action.status;
        draft.isLoadingObjectsDefList = action.isLoadingObjectsDefList;
      });
    case OBJECTS_TYPE.SAVE_OBJECT_DEFINITION:
      return produce(state, (draft) => {
        draft.objectsDefList = [];
      });
    case OBJECTS_TYPE.RECEIVED_CHILDS_COUNT_BY_TYPE:
      return produce(state, (draft) => {
        draft.childsCountByType[action.level] = action.objects;
        draft.isLoadingTree[action.level] = action.value;
      });
    case OBJECTS_TYPE.RECEIVED_OBJECTS_DEF_LIST:
      return produce(state, (draft) => {
        draft.objectsDefList =
          action.objects != null
            ? action.objects.sort((a, b) => a.label.localeCompare(b.label))
            : null;
        draft.pattern = getRealPatternFromPrefixes(
          draft.pattern,
          draft.objectsDefList
        );
        draft.field_type = [
          ...fixed_field_type,
          ...(action.objects
            ? action.objects.map((elem) => {
                return {
                  label: elem.label,
                  description: elem.description,
                  name: elem.key,
                  icon: 'anchor.png',
                  predefined: false,
                };
              })
            : []),
        ];
        draft.dicoNameRef = {
          ...state.dicoNameRef,
          ...(action.objects
            ? action.objects.reduce(
                (acc, a) => extractDicoFormObjFormData(a.objFormData, acc),
                {}
              )
            : []),
        };
      });
    case OBJECTS_TYPE.SAVE_OBJECT:
      return produce(state, (draft) => {});
    case OBJECTS_TYPE.SET_LOADING_CRUMB_TRAIT:
      return produce(state, (draft) => {
        draft.isLoadingCrumbTrail = action.status;
      });
    case OBJECTS_TYPE.RESET_CRUMB_TRAIL:
      return produce(state, (draft) => {
        draft.crumbTrail = null;
      });
    case OBJECTS_TYPE.OPEN_ACTIVITY_FROM_VERTICAL:
      return produce(state, (draft) => {
        draft.isOpenActivityFromVertical = action.status;
      });

    default:
      return state;
  }
};
export default objects;
