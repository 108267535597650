import React, { Component } from 'react';

class Swipeable extends Component {
  constructor(props) {
    super(props);
    this.onTouchMove = this.onTouchMove.bind(this);
  }

  onTouchMove(e) {
    var clientX = e.changedTouches[0].clientX;
    var clientY = e.changedTouches[0].clientY;
    this.props.callback({ clientX, clientY });
  }

  render() {
    return (
      <div {...this.props} onTouchMove={this.onTouchMove}>
        {this.props.children}
      </div>
    );
  }
}

export default Swipeable;
