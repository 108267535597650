import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onSetDevice } from '../reducers/responsiveReducer/action';
import { size } from '../constants/devices';

export const MediaProvider = ({ children }) => {
  const isMobile = useMediaQuery(`(max-width: ${size.tablet})`);
  console.log('isMobile_MediaProvider', isMobile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onSetDevice({ device: 'isMobile', status: isMobile }));
  }, [isMobile]);

  return children;
};
