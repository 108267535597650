/* eslint-disable no-prototype-builtins */
/* eslint-disable node/no-unsupported-features/es-builtins */
import { produce } from 'immer';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

export const iconsLibrarySelector = createSelector(
  (state) => state.iconsLibrary.icons,
  (data) => {
    return data;
  }
);
export const modeIconLibrarySelector = createSelector(
  (state) => state.iconsLibrary.mode,
  (data) => {
    return data;
  }
);

export const statusShowModalLibrarySelector = createSelector(
  (state) => state.iconsLibrary.isShowModalIconsLibrary,
  (data) => {
    return data;
  }
);
export const callbackLibrarySelector = createSelector(
  (state) => state.iconsLibrary.callback,
  (data) => {
    return data;
  }
);

export const sessionModalLibrarySelector = createSelector(
  (state) => state.iconsLibrary,
  (data) => {
    if (isEmpty(data.icons?.svg)) {
      return produce(data.dataSessionLibraryIcons, (draft) => {
        return draft.filter((item) => item.value !== 'svg');
      });
    }
    return data.dataSessionLibraryIcons;
  }
);
export const isLoadingDataLibrarySelector = createSelector(
  (state) => state.iconsLibrary.isLoadingGetData,
  (data) => {
    return data;
  }
);

export const iconsLibraryAvailableSelector = createSelector(
  (state) => state.iconsLibrary.icons,
  (data) => {
    return filterItemAvailableObjectLibIcons(data);
  }
);

export const currentIconSelectLibrarySelector = (state) =>
  state.iconsLibrary.currentIconSelect;

export const actionAddResourceLibrarySelector = (state) =>
  state.iconsLibrary.isShowActionAddResource;

const filterItemAvailableObjectLibIcons = (data) =>
  produce(data, (draftState) => {
    Object.entries(draftState).map(([keyTypeLibIcon]) => {
      // keyTypeLibIcon = 'images' | 'icons';
      Object.entries(draftState[keyTypeLibIcon].categories).map(
        ([keyCategoryLibIcon, valueCategoryLibIcon]) => {
          Object.entries(valueCategoryLibIcon.icons).map(
            ([keyIcon, valueIcon]) => {
              if (valueIcon.isDelete) {
                delete draftState[keyTypeLibIcon].categories[keyCategoryLibIcon]
                  .icons[keyIcon];
              }
            }
          );
        }
      );
    });
    return draftState;
  });
