import styled from 'styled-components';

export const stepperModalImportExcelStyles = {
  step: {
    display: 'flex',
    flexDirection: 'column',
  },
  stepLabel: {
    fontSize: 12,
    fontWeight: 400,
  },
  separator: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 24,
  },
  stepBody: {
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const WrapIconExcelModalImport = styled.div({
  width: 120,
  height: 120,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const FinishTabMappingContainer = styled.div({
  height: '100%',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});
