import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Pagination,
  Popover,
  ScrollArea,
  Select,
  Space,
  Stack,
  Table,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import produce from 'immer';
import React, {
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Settings, X } from 'tabler-icons-react';
import { isEmpty } from 'lodash';
import { objectsDefListSelector } from '../../reducers/objectReducers/objectSelector';
import { heightScreen } from '../../utils/windowSize';
import { FieldTabSelectOption } from './FieldTabCSS';

export default function FieldTab({
  responseUpFile,
  valueSelect,
  handleNextStepMappingData,
  prevStep,
  isLoading,
  opened,
  setOpened,
  checked,
  setChecked,
  columnObjectsDefListImport,
  setColumnObjectsDefListImport,
  getDataFromExcel,
  isLoadingLoadDataExcel,
  active,
  activePage,
  setPage,
}) {
  const objFormData = useSelector(objectsDefListSelector);

  const [columnRemove, setColumnRemove] = useState([]);

  let isDisable = useRef(false);

  const specificObj = objFormData.find(
    (itemObj) => itemObj?.key === valueSelect
  );

  const columnSpecificObj = useMemo(
    () =>
      specificObj?.objFormData.reduce((acc, currentItem) => {
        acc.push(currentItem?.label);
        return acc;
      }, []),
    [specificObj?.objFormData]
  );

  const [columnHeader, setColumnHeader] = useState([]);

  useEffect(() => {
    let initialColumn = [];
    responseUpFile?.headers.map((columnExcel) => {
      initialColumn.push(columnExcel?.name);
    });
    setColumnHeader(initialColumn);
  }, [responseUpFile?.headers]);

  const columnHeaderAvailable = useMemo(
    () =>
      columnHeader
        .filter((item) => !!item && !columnRemove.includes(item))
        .sort(),
    [columnHeader, columnRemove]
  );

  useEffect(() => {
    if (active !== 1) return;
    let checked = {};
    const columnMappingImport = columnSpecificObj.reduce((acc, currentItem) => {
      if (columnHeaderAvailable.includes(currentItem)) {
        acc[currentItem] = currentItem;
        checked[currentItem] = {
          uppercase: false,
          capitalize: false,
        };
      }
      return acc;
    }, {});
    setColumnObjectsDefListImport(columnMappingImport);
    setChecked(checked);
  }, [columnSpecificObj?.length, columnHeaderAvailable?.length, active]);

  const rows = responseUpFile?.data?.map((data, index) => {
    return (
      <tr key={index}>
        {columnHeaderAvailable.map((column) => {
          if (data[column]?.length <= 27 && data[column]) {
            return (
              <td key={column}>
                <FieldValue value={data[column]} />
              </td>
            );
          }
          if (data[column]) {
            return (
              <td key={column}>
                <Tooltip
                  label={data[column]}
                  withArrow
                  position="bottom"
                  wrapLines
                  styles={{
                    body: {
                      width: 250,
                      maxWidth: 250,
                      wordBreak: 'break-all',
                    },
                  }}
                  transition="fade"
                  transitionDuration={200}
                >
                  <FieldValue value={data[column]} />
                </Tooltip>
              </td>
            );
          }
          isDisable.current = true;
          return (
            <td>
              <FieldValue value={'X'} style={{ textAlign: 'center' }} />
            </td>
          );
        })}
      </tr>
    );
  });

  const { colors } = useMantineTheme();

  const SelectItem = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <FieldTabSelectOption
        ref={ref}
        {...others}
        isSelected={Object.values(columnObjectsDefListImport).find(
          (item) => item === label
        )}
        colors={colors}
      >
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </FieldTabSelectOption>
    )
  );
  const specificObjAvailable = [];
  specificObj?.objFormData?.map((obj) => {
    specificObjAvailable.push(obj?.label);
  });
  specificObjAvailable.sort();
  return (
    <div
      style={{
        height: heightScreen / 1.8,
        maxHeight: heightScreen / 1.8,
      }}
    >
      <Stack spacing={0} style={{ position: 'relative' }}>
        <LoadingOverlay
          style={{ borderRadius: 4 }}
          visible={isLoadingLoadDataExcel}
          overlayOpacity={0.3}
          overlayColor="#c5c5c5"
        />
        <ScrollArea
          pl={12}
          scrollbarSize={8}
          offsetScrollbars
          style={{
            maxWidth: 850,
            height: heightScreen / 1.8 / 2,
            maxHeight: heightScreen / 1.8 / 2,
          }}
        >
          <div
            style={{
              position: 'relative',
              zIndex: 10,
              backgroundColor: 'white',
            }}
          >
            <Space h={4} />
            <Text size="lg" weight={'bold'}>
              Mapping data
            </Text>
          </div>
          <Table striped highlightOnHover fontSize="xs" verticalSpacing="sm">
            <thead>
              <tr>
                {columnHeaderAvailable?.map((name) => {
                  return (
                    <th
                      key={name}
                      style={{
                        minWidth: 180,
                        position: 'sticky',
                        top: 0,
                        maxWidth: 180,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        backgroundColor: 'white',
                        zIndex: 10,
                        // minWidth: 'maxContent',
                      }}
                    >
                      <Stack spacing="xs" pr={10}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span
                            style={{
                              maxWidth: 100,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {name}
                          </span>
                          <Group spacing="4px">
                            <Popover
                              width={180}
                              opened={!!opened[name]}
                              onClose={() =>
                                setOpened({ ...opened, [name]: false })
                              }
                              target={
                                <ActionIcon
                                  size="sm"
                                  onClick={() => {
                                    setOpened({
                                      ...opened,
                                      [name]: !opened[name],
                                    });
                                  }}
                                >
                                  <Settings size={14} />
                                </ActionIcon>
                              }
                              position="bottom"
                              withArrow
                            >
                              <Stack>
                                <Checkbox
                                  label="Uppercase"
                                  checked={!!checked[name]?.uppercase}
                                  onChange={(event) =>
                                    setChecked({
                                      ...checked,
                                      [name]: {
                                        ...checked[name],
                                        uppercase: event.currentTarget.checked,
                                      },
                                    })
                                  }
                                />
                                <Checkbox
                                  label="Capitalize"
                                  checked={!!checked[name]?.capitalize}
                                  onChange={(event) =>
                                    setChecked({
                                      ...checked,
                                      [name]: {
                                        ...checked[name],
                                        capitalize: event.currentTarget.checked,
                                      },
                                    })
                                  }
                                />
                              </Stack>
                            </Popover>
                            <Tooltip
                              position="bottom"
                              label="Hide column"
                              withArrow
                            >
                              <ActionIcon
                                size="sm"
                                onClick={() => {
                                  const newChecked = produce(
                                    checked,
                                    (draft) => {
                                      delete draft[name];
                                    }
                                  );
                                  setChecked(newChecked);
                                  setColumnRemove([...columnRemove, name]);
                                }}
                              >
                                <X size={14} />
                              </ActionIcon>
                            </Tooltip>
                          </Group>
                        </div>
                        <Select
                          searchable
                          clearable
                          size="xs"
                          placeholder="Import for"
                          data={columnSpecificObj}
                          itemComponent={SelectItem}
                          value={columnObjectsDefListImport[name]}
                          onChange={(value) => {
                            isDisable.current = false;
                            if (isEmpty(columnObjectsDefListImport)) {
                              setColumnObjectsDefListImport({
                                [name]: value,
                              });
                              return;
                            }
                            const newColumnImport = produce(
                              columnObjectsDefListImport,
                              (draft) => {
                                Object.entries(draft).map(
                                  ([key, columnValue]) => {
                                    if (columnValue === value) {
                                      draft[key] = null;
                                    }
                                    draft[name] = value;
                                  }
                                );
                                return draft;
                              }
                            );
                            setColumnObjectsDefListImport(newColumnImport);
                          }}
                        />
                      </Stack>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </ScrollArea>
        <div
          style={{
            height: 44,
            marginBottom: 6,
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {responseUpFile && (
            <Pagination
              size="sm"
              total={responseUpFile?.pagesTotal}
              page={activePage}
              onChange={(page) => {
                getDataFromExcel(page);
                setPage(page);
              }}
            />
          )}
        </div>
      </Stack>
      {/* <Space h={16} /> */}
      <ScrollArea
        pl={12}
        scrollbarSize={8}
        offsetScrollbars
        style={{
          maxWidth: 850,
          height: heightScreen / 1.8 / 2 - 50,
          maxHeight: heightScreen / 1.8 / 2 - 50,
        }}
      >
        <Box style={{ maxWidth: 900 }} mx="auto">
          <Group
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            <Text size="lg" weight={'bold'} style={{ width: 200 }}>
              Fields in file excel
            </Text>
            <Text size="lg" weight={'bold'}>
              Fields in object target
            </Text>
          </Group>
          <Space h={8} />
          <Group style={{ alignItems: 'flex-start' }}>
            <Stack style={{ width: 200 }}>
              {columnHeaderAvailable?.map((field, index) => {
                return (
                  <Text key={`columnHeader${field}_${index}`}>{field}</Text>
                );
              })}
            </Stack>
            <Stack>
              {specificObjAvailable?.map((field, index) => {
                return <Text key={`specific${field}_${index}`}>{field}</Text>;
              })}
            </Stack>
          </Group>
          <Space h={8} />

          <Group
            style={{
              justifyContent: 'flex-end',
              position: 'absolute',
              right: 0,
              bottom: 0,
            }}
          >
            <Group mt="md">
              <Button onClick={prevStep} disabled={isLoading}>
                Prev step
              </Button>
            </Group>
            <Group mt="md">
              <Button
                // disabled={isDisable.current || isLoading}
                // disabled={false}
                onClick={handleNextStepMappingData}
              >
                Next step
              </Button>
            </Group>
          </Group>
          <Space h={30} />
        </Box>
      </ScrollArea>
    </div>
  );
}

export const FieldValue = memo(({ value, style }) => {
  return (
    <div
      style={{
        maxWidth: 180,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ...style,
      }}
    >
      {value}
    </div>
  );
});
