import styled from 'styled-components';

export const NotificationContainer = styled.div({
  position: 'absolute',
  bottom: 50,
  right: 30,
  zIndex: 99,
});

export const ViewBackPerspective = styled.div({
  position: 'absolute',
  height: 52,
  width: 52,
  zIndex: 999,
  left: 0,
  bottom: 50,
  backgroundColor: '#333333',
  borderRadius: '0px 6px 6px 0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // [devices.tablet]: {
  //   width: 40,
  // },
  // [devices.mobileL]: {
  //   width: 35,
  // },
});
