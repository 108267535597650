import { getSuggestedType } from '../components/object-creator/ObjectCreatorUtils';

const OBJECT_CREATOR = (state = DEFAULT_STATE, action, root) => {
  let res;

  switch (action.type) {
    //--------------------------------------------------------------------------------------------------
    case 'SET_OBJECT_CREATOR_STATE': {
      return { ...state, objectCreatorState: { ...action.objectCreatorState } };
    }
    //--------------------------------------------------------------------------------------------------
    case 'CLOSE_OBJECTMENU':
      return { ...state, menuOpen: false };

    //--------------------------------------------------------------------------------------------------
    case 'OPEN_OBJECTMENU_FORMOVE': {
      const typeToMove = root.objects.objTypeId;
      const idToMove = root.objects.objId;

      return {
        ...state,
        menuOpen: true,
        objectCreatorState: {
          action: 'moveObject',
          typeNewObject: typeToMove,
          typeParent: null,
          idParent: null,
          idToMove,
        },
        suggestedType: null,
      };
    }
    //--------------------------------------------------------------------------------------------------
    case 'OPEN_OBJECTMENU': {
      const typeParent = root.objects.objTypeId;
      const suggestedType = getSuggestedType(
        root.objects.pattern,
        typeParent,
        root.objects.objectsDefList
      );

      return {
        ...state,
        menuOpen: true,
        objectCreatorState: {
          action: 'addObject',
          typeParent,
          idParent: root.objects.objId,
        },
        suggestedType,
      };
    }
    //--------------------------------------------------------------------------------------------------
    case 'SELECTED_OBJECT_TO_CREATE':
      res = {
        ...state,
        objectCreatorState: {
          ...state.objectCreatorState,
          typeNewObject: action.objType,
        },
      };
      return res;
    //--------------------------------------------------------------------------------------------------
    case 'OPEN_OBJECTMENU_FOR_OBJ':
      const typeParent = action.ptype;
      const suggestedType = getSuggestedType(
        root.objects.pattern,
        typeParent,
        root.objects.objectsDefList
      );

      return {
        ...state,
        menuOpen: true,

        objectCreatorState: {
          typeParent,
          idParent: action.pkey,
          action: action.action,
        },
        suggestedType,
      };

    default:
      return state;
  }
};

//--------------------------------------------------------------------------------------------------
const DEFAULT_STATE = {
  menuOpen: false,
  suggestedType: null,
  objectCreatorState: {
    action: null,
    typeNewObject: null,
    idNewObject: 'yyy',
    typeParent: null,
    idParent: null,
    layers: null,
    step: 0,
  },
};
//--------------------------------------------------------------------------------------------------
export default OBJECT_CREATOR;
