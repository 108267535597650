import { getObjectsByTypeNoRestrictions } from '../../reducers/objectReducers/action';
import { parseJson } from '../../utils/parseJson';

export function isJSON(data) {
  try {
    const testIfJson = parseJson(data);
    return typeof testIfJson === 'object';
  } catch {
    return false;
  }
}

export function expToDBQuery(object) {
  let resStr = '(';

  if (object.negate != null && object.negate) resStr = ' not(';
  if (object != null && object.terms != null)
    resStr += object.terms
      .map((term) => termToDBQuery(term))
      .join(` ${object.op} `);
  resStr += ')';
  return resStr;
}

export function termToDBQuery(object) {
  if (object.type === 'term') {
    return (
      FCTS_TERM_TO_DB_QUERY[object.op]?.(object) ??
      FCTS_TERM_TO_DB_QUERY.default?.(object)
    );
  }
  return expToDBQuery(object);
}

const d = new Date();
const currYear = d.getFullYear(); // Get the year as a four digit number (yyyy)
const currMonth = d.getMonth();
const timeZoneOffsetMs = 4 * 1000 * 60 * 60;

const firstDayThisYear =
  "(datetime.truncate('year',date())+Duration({years:0})).epochMillis";
const firstDayLastYear =
  "(datetime.truncate('year',date())+Duration({years:-1})).epochMillis";
const firstDayNextYear =
  "(datetime.truncate('year',date())+Duration({years:1})).epochMillis";
const firstDayTwoYear =
  "(datetime.truncate('year',date())+Duration({years:2})).epochMillis";

const firstDayThisMonth =
  "(datetime.truncate('month',date())+Duration({months:0})).epochMillis";
const firstDayNextMonth =
  "(datetime.truncate('month',date())+Duration({months:1})).epochMillis";
const firstDayTwoMonth =
  "(datetime.truncate('month',date())+Duration({months:2})).epochMillis";
const firstDayLastMonth =
  "(datetime.truncate('month',date())+Duration({months:-1})).epochMillis";

const FCTS_TERM_TO_DB_QUERY = {
  text_is: (object) => ` obj.${object.field} = '${object.value}' `,
  text_gte: (object) => ` obj.${object.field} > '${object.value}' `,
  text_lte: (object) => ` obj.${object.field} < '${object.value}' `,

  text_contains: (object) =>
    ` obj.${object.field} =~ '(?im).*${object.value}.*' `, // ?i = case insensitive
  text_startswith: (object) =>
    ` obj.${object.field} =~ '(?im)${object.value}.*' `, // ?i = case insensitive
  text_endswith: (object) =>
    ` obj.${object.field} =~ '(?im).*${object.value}' `, // ?i = case insensitive

  number_is: (object) => ` toFloat(obj.${object.field}) = ${object.value} `,
  number_gte: (object) => ` toFloat(obj.${object.field}) >= ${object.value} `,
  number_lt: (object) => ` toFloat(obj.${object.field}) < ${object.value} `,
  number_lte: (object) => ` toFloat(obj.${object.field}) <= ${object.value} `,

  formula_is: (object) => ` toFloat(obj.${object.field}) = ${object.value} `,
  formula_gte: (object) => ` toFloat(obj.${object.field}) >= ${object.value} `,
  formula_lt: (object) => ` toFloat(obj.${object.field}) < ${object.value} `,
  formula_lte: (object) => ` toFloat(obj.${object.field}) <= ${object.value} `,

  date_is: (object) => ` (obj.${object.field}) = '${object.value}' `,
  date_gte: (object) => ` obj.${object.field} > '${object.value}' `,
  date_lte: (object) => ` obj.${object.field} < '${object.value}' `,
  // date_past : (object) => ` obj.${object.field} < '${Date.now()}' `,
  // date_futur : (object) => ` obj.${object.field} > '${Date.now()}' `,
  date_past: (object) => ` toInteger(obj.${object.field}) < timestamp() `,
  date_futur: (object) => ` toInteger(obj.${object.field}) > timestamp() `,

  last_24: (object) =>
    ` (toInteger(obj.${object.field}) < timestamp()) and (toInteger(obj.${object.field}) > timestamp()-1000*60*60*24)  `,
  next_24: (object) =>
    ` (toInteger(obj.${object.field}) > timestamp()) and (toInteger(obj.${object.field}) < timestamp()+1000*60*60*24)  `,

  last_7d: (object) =>
    ` (toInteger(obj.${object.field}) < timestamp()) and (toInteger(obj.${object.field}) > timestamp()-1000*60*60*24*7)  `,
  next_7d: (object) =>
    ` (toInteger(obj.${object.field}) > timestamp()) and (toInteger(obj.${object.field}) < timestamp()+1000*60*60*24*7)  `,

  last_30d: (object) =>
    ` (toInteger(obj.${object.field}) < timestamp()) and (toInteger(obj.${object.field}) > timestamp()-1000*60*60*24*30)  `,
  next_30d: (object) =>
    ` (toInteger(obj.${object.field}) > timestamp()) and (toInteger(obj.${object.field}) < timestamp()+1000*60*60*24*30)  `,

  this_year: (object) =>
    ` (toInteger(obj.${object.field}) > ${firstDayThisYear}) and (toInteger(obj.${object.field}) < ${firstDayNextYear}) `,
  last_year: (object) =>
    ` (toInteger(obj.${object.field}) > ${firstDayLastYear}) and (toInteger(obj.${object.field}) < ${firstDayThisYear}) `,
  next_year: (object) =>
    ` (toInteger(obj.${object.field}) > ${firstDayNextYear}) and (toInteger(obj.${object.field}) < ${firstDayTwoYear}) `,

  this_month: (object) =>
    ` (toInteger(obj.${object.field})+${timeZoneOffsetMs} >= ${firstDayThisMonth}) and (toInteger(obj.${object.field}) < ${firstDayNextMonth}) `,
  last_month: (object) =>
    ` (toInteger(obj.${object.field}) > ${firstDayLastMonth}) and (toInteger(obj.${object.field}) < ${firstDayThisMonth}) `,
  next_month: (object) =>
    ` (toInteger(obj.${object.field}) > ${firstDayNextMonth}) and (toInteger(obj.${object.field}) < ${firstDayTwoMonth}) `,
  x_days_ago: (object) =>
    ` (toInteger(obj.${object.field}) < timestamp()-1000*60*60*24*${object.value})  `,
  next_x_days: (object) =>
    ` (toInteger(obj.${object.field}) < timestamp()+1000*60*60*24*${object.value} and toInteger(obj.${object.field}) > timestamp() )  `,

  list_is: (object) => ` (obj.${object.field}) = '${object.value}' `,
  list_gte: (object) => ` obj.${object.field} > '${object.value}' `,
  list_lte: (object) => ` obj.${object.field} < '${object.value}' `,

  is_current_user: (object) => ` obj.${object.field} = 'xxx-current-user-xxx' `,
  default: (object) =>
    object.field_type === 'number' || object.field_type === 'formula'
      ? ` toFloat(obj.${object.field}) ${object.op}${object.value}`
      : ` obj.${object.field} = '${object.value}'`,
};

export function getParemetersByOp(type, operatorName) {
  return getOpsByType(type).find((elem) => elem.name === operatorName);
}

export function getOpsByType(type) {
  let res = [];
  switch (type) {
    case 'text':
      res = [
        { name: 'text_contains', label: 'contains', showValue: true },
        { name: 'text_startswith', label: 'starts with', showValue: true },
        { name: 'text_endswith', label: 'ends with', showValue: true },
        { name: 'text_is', label: 'is', showValue: true },
        { name: 'text_gte', label: 'after', showValue: true },
        { name: 'text_lte', label: 'before', showValue: true },
      ];
      break;
    case 'area':
      res = [
        { name: 'text_contains', label: 'contains', showValue: true },
        { name: 'text_is', label: 'is', showValue: true },
        { name: 'text_gte', label: 'after', showValue: true },
        { name: 'text_lte', label: 'before', showValue: true },
        { name: 'text_startswith', label: 'starts with', showValue: true },
        { name: 'text_endswith', label: 'ends with', showValue: true },
      ];
      break;
    case 'number':
      res = [
        { name: 'number_is', label: 'is', showValue: true },
        { name: 'number_gte', label: 'at most', showValue: true },
        { name: 'number_lt', label: 'less than', showValue: true },
        { name: 'number_lte', label: 'less or equal than', showValue: true },
      ];
      break;
    case 'formula':
      res = [
        { name: 'formula_is', label: 'is', showValue: true },
        { name: 'formula_gte', label: 'at most', showValue: true },
        { name: 'formula_lt', label: 'less than', showValue: true },
        { name: 'formula_lte', label: 'less or equal than', showValue: true },
      ];
      break;
    case 'date':
      res = [
        { name: 'date_is', label: 'date is', showValue: true },
        { name: 'date_gte', label: 'after', showValue: true },
        { name: 'date_lte', label: 'before', showValue: true },
        { name: 'date_past', label: 'in the past', showValue: false },
        { name: 'date_futur', label: 'in the futur', showValue: false },
        { name: 'last_24', label: 'last 24', showValue: false },
        { name: 'next_24', label: 'next 24', showValue: false },
        { name: 'last_7d', label: 'within last 7d', showValue: false },
        { name: 'next_7d', label: 'within next 7d', showValue: false },
        { name: 'last_30d', label: 'within last 30d', showValue: false },
        { name: 'next_30d', label: 'within next 30d', showValue: false },
        {
          name: 'x_days_ago',
          label: ' x days ago',
          showValue: true,
          valueType: 'number',
        },
        {
          name: 'next_x_days',
          label: 'next x days',
          showValue: true,
          valueType: 'number',
        },

        { name: 'this_month', label: 'this month', showValue: false },
        { name: 'last_month', label: 'last month', showValue: false },
        { name: 'next_month', label: 'next month', showValue: false },
        { name: 'this_year', label: 'this year', showValue: false },
        { name: 'last_year', label: 'last year', showValue: false },
        { name: 'next_year', label: 'next year', showValue: false },
      ];
      break;
    case 'list':
      res = [
        { name: 'list_is', label: 'is', showValue: true },
        { name: 'list_lte', label: 'before', showValue: true },
        { name: 'list_gte', label: 'after', showValue: true },
      ];
      break;
    default:
      // we assume the field is linked to another type
      res = [
        { name: 'text_is', label: 'is', showValue: true },
        { name: 'text_contains', label: 'contains', showValue: true },
      ];
      break;
  }
  if (type.startsWith('empl')) {
    res.push({ name: 'is_current_user', label: 'is me', showValue: false });
  }
  return res;
}
