import {
  Button,
  ColorInput,
  Modal,
  NumberInput,
  ScrollArea,
  Space,
  Switch,
  Textarea,
} from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colorsSvgDrawing } from '../../../../constants/colors';

export default function AddTextSvgBox({
  onAddTextSvg,
  visible,
  onHideModalAddText,
  isEditText,
  currentElementSelect,
  color,
}) {
  const { t } = useTranslation();
  const [valueText, setValueText] = useState('');
  const [textColor, setTextColor] = useState('');
  const [backgroundColorText, setBackgroundColor] = useState('');
  const [isTransparentBackground, setIsTransparentBackground] = useState(false);

  useEffect(() => {
    if (visible) {
      setTextColor(color);
    }
  }, [visible, color]);
  const backgroundColor = isTransparentBackground
    ? 'transparent'
    : backgroundColorText;

  const [fontWeight, setFontWeight] = useState(400);
  const [fontSize, setFontSize] = useState(20);

  const resetState = () => {
    onHideModalAddText(false);
    setFontSize(20);
    setFontWeight(400);
    setTextColor(colorsSvgDrawing[0]);
    setValueText('');
  };
  const onAddText = useCallback(() => {
    onAddTextSvg(
      {
        text: valueText,
        color: textColor,
        fontWeight,
        fontSize,
        backgroundColor,
      },
      isEditText
    );
    resetState();
  }, [valueText, textColor, fontWeight, fontSize, backgroundColor]);

  useEffect(() => {
    if (isEditText) {
      const { points, fontWeight, color, fontSize, backgroundColor } =
        currentElementSelect;
      setValueText(points);
      setTextColor(color);
      setFontWeight(fontWeight);
      setFontSize(fontSize);
      setBackgroundColor(backgroundColor);
    }
  }, [isEditText, currentElementSelect]);
  useEffect(() => {
    if (!visible) {
      resetState();
    }
  }, [visible]);
  return (
    <>
      <Modal
        zIndex={20000000015}
        opened={visible}
        onClose={onHideModalAddText}
        title={t(isEditText ? 'edit_text' : 'add_text')}
        centered
        overlayOpacity={0.55}
        overlayBlur={3}
        size={'md'}
      >
        <Textarea
          mt="md"
          placeholder={t('enter_text')}
          label={t('your_text')}
          value={valueText}
          onChange={(event) => setValueText(event.currentTarget.value)}
        />
        <NumberInput
          mt="md"
          label={t('font_weight')}
          // description={t('limit_font_weight', { from: '100', to: '700' })}
          defaultValue={400}
          step={100}
          min={100}
          max={700}
          value={fontWeight}
          onChange={(val) => setFontWeight(val)}
        />
        <NumberInput
          mt="md"
          label={t('fon_size')}
          // description={t('limit_font_size', { from: '16', to: '82' })}
          defaultValue={20}
          step={10}
          min={10}
          max={80}
          value={fontSize}
          onChange={(val) => setFontSize(val)}
        />
        <Space h="md" />

        <ColorInput
          dropdownZIndex={210000000017}
          value={textColor}
          placeholder="Pick color"
          label="Text color"
          disallowInput
          withPicker={false}
          swatches={colorsSvgDrawing}
          onChange={(color) => {
            setTextColor(color);
          }}
        />
        <Space h="md" />

        <ColorInput
          dropdownZIndex={210000000017}
          value={backgroundColorText}
          placeholder="Pick color"
          label="Background color"
          swatches={colorsSvgDrawing}
          withPicker={false}
          disallowInput
          onChange={(color) => {
            setBackgroundColor(color);
          }}
        />
        <Space h="md" />

        <Switch
          label="Background transparent"
          checked={isTransparentBackground}
          onChange={(event) =>
            setIsTransparentBackground(event.currentTarget.checked)
          }
        />

        {valueText && (
          <>
            <Space h="lg" />
            <ScrollArea style={{ height: 270 }} type="scroll" scrollbarSize={8}>
              <div
                style={{
                  padding: 12,
                  backgroundColor,
                }}
              >
                <PreviewText
                  fontWeight={fontWeight}
                  color={textColor}
                  fontSize={fontSize}
                >
                  {valueText}
                </PreviewText>
              </div>
            </ScrollArea>
          </>
        )}
        <Space h="md" />

        <Button onClick={onAddText}>
          {t(isEditText ? 'update' : 'add_text')}
        </Button>
      </Modal>
    </>
  );
}

const PreviewText = styled.span({
  textAlign: 'center',
  fontWeight: (props) => props.fontWeight || '400',
  color: (props) => props.color || 'black',
  fontSize: (props) => `${props.fontSize}px` || '20px',
  whiteSpace: 'pre-wrap',
});
const WrapPreviewText = styled.div({
  maxHeight: 120,
  overflow: 'scroll',
});

const ContentPreviewText = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
