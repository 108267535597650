export const LAYOUT_TYPE = {
  SET_ZOOM: 'SET_ZOOM',
  SET_ZOOM_MAP: 'SET_ZOOM_MAP',
  TOGGLE_PALETTE: 'TOGGLE_PALETTE',
  CONFIGURE_CLOSE_SCREEN: 'CONFIGURE_CLOSE_SCREEN',
  CONFIGURE_OPEN_SCREEN: 'CONFIGURE_OPEN_SCREEN',
  TOGGLE_PERSPECTIVES_PANEL: 'TOGGLE_PERSPECTIVES_PANEL',
  TOGGLE_VR: 'TOGGLE_VR',
  REMOTE_CTRL_SHOW_TOGGLE: 'REMOTE_CTRL_SHOW_TOGGLE',
  OBJECT_DETAILS_OPEN: 'OBJECT_DETAILS_OPEN',
  TOGGLE_SHOW_SETTINGS: 'TOGGLE_SHOW_SETTINGS',
  LAYERS_CTRL_SHOW_TOGGLE: 'LAYERS_CTRL_SHOW_TOGGLE',
  SCROLL_POS: 'SCROLL_POS',
  SET_MOUSE_POS: 'SET_MOUSE_POS',
  UPDATE_LAYERS_DISPLAY: 'UPDATE_LAYERS_DISPLAY',
  SET_MENU_ACTIVE: 'SET_MENU_ACTIVE',
  RESET_MENU_OPTION: 'RESET_MENU_OPTION',
  SHOW_PROFILE_VIEW: 'SHOW_PROFILE_VIEW',
  HIDDEN_PROFILE_VIEW: 'HIDDEN_PROFILE_VIEW',
  CHANGE_STATUS_ACTIVITY_LOG: 'CHANGE_STATUS_ACTIVITY_LOG',
  SET_CURRENT_OBJ_ID: 'SET_CURRENT_OBJ_ID',
  SET_POSITION_X_OBJ_DETAIL: 'SET_POSITION_X_OBJ_DETAIL',
  SET_SELECT_NODE: 'SET_SELECT_NODE',
  RESET_SELECT_NODE: 'RESET_SELECT_NODE',
  SET_PRESS_SHIFT: 'SET_PRESS_SHIFT',
  SET_SHOW_MENU_MOBILE: 'SET_SHOW_MENU_MOBILE',
  TOGGLE_PREVIEW_SVG_SKETCH: 'TOGGLE_PREVIEW_SVG_SKETCH',
  SET_PREVIEW_SVG_SKETCH_DATA: 'SET_PREVIEW_SVG_SKETCH_DATA',
  SET_STATUS_MODAL_IMPORT_MAPPING_FILE: 'SET_STATUS_MODAL_IMPORT_MAPPING_FILE',
};
