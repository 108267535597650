import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  nodeDragSelector,
  parentNodeDragIdSelector,
} from '../../reducers/responsiveReducer/responsiveSelector';
import { draggedOnNode } from '../../reducers/layoutReducer/action';

export default function TabletLayout() {
  const nodeDragItem = useSelector(nodeDragSelector);
  const parentNodeDragId = useSelector(parentNodeDragIdSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (!nodeDragItem?.id || !parentNodeDragId) return;
      const nodeElement = document.getElementById(`nc_${parentNodeDragId}`);

      const nodeDrag = document.getElementById(`nc_${nodeDragItem?.id}`);

      if (!nodeDrag && !nodeElement) return;

      const { left, top, width, height } = nodeElement.getBoundingClientRect();
      const {
        left: leftNodeDrag,
        top: topNodeDrag,

        height: heightNodeDrag,
      } = nodeDrag.getBoundingClientRect();
      let isDragEnable =
        left < leftNodeDrag &&
        leftNodeDrag < left + width &&
        ((top < topNodeDrag && topNodeDrag < top + height) ||
          (top < topNodeDrag + heightNodeDrag &&
            topNodeDrag + heightNodeDrag < top + height));

      window.addEventListener('touchend', () => {
        if (isDragEnable) {
          dispatch(draggedOnNode(parentNodeDragId, nodeDragItem?.id));
          isDragEnable = false;
          nodeElement.style.borderWidth = '1px';

          window.addEventListener('touchend', null);
        }
      });
    } catch (error) {}
  }, [parentNodeDragId, nodeDragItem]);

  return null;
}
