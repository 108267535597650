import styled from 'styled-components';

export const ProfileAuthContainer = styled.div({
  height: '30px',
});

export const ProfileAuthIcon = styled.img({
  borderRadius: '50%',
  pointerEvents: 'none',
  marginRight: '3px',
  marginTop: '-5px',
});

export const ProfileAuthIconDefault = styled.div({
  borderRadius: 30,
  width: 30,
  height: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ProfileAuthIconDetail = styled.img({
  marginRight: 12,
  borderRadius: 44,
});

export const ProfileAuthIconDetailDefault = styled.div({
  marginRight: 12,
  borderRadius: 44,
  width: 44,
  height: 44,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E9E9E9',
  boxShadow: '0px 15px 47px -3px rgba(150, 150, 150, 0.6)',
});
export const ProfileAuthInfo = styled.div({
  color: 'black',
  wordBreak: 'break-word',
});
export const ProfileAuthInfoContainer = styled.div({
  flexDirection: 'row',
  alignItems: 'center',
  display: 'flex',
  padding: '0px 12px',
});
export const ProfileAuthInfoEmail = styled.div({
  wordBreak: 'break-word',
  color: (props) => props?.color || 'grey',
  fontSize: 12,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 150,
});

export const ProfileAuthInfoName = styled.div({
  color: (props) => props?.color || '#313131',
  wordBreak: 'break-word',
  fontSize: 16,
  fontWeight: '600',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 150,
});
export const ProfileAuthPopoverContainer = styled.div({
  // height: 250,
  position: 'relative',
  width: 240,
  left: -17,
  // height: (props) => props.height,
});
export const ProfileCloseIconContainer = styled.div({
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  borderRadius: 6,
  width: 24,
  height: 24,
  padding: 2,
  right: 10,
  top: -10,
  color: 'grey',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: 'rgba(150, 150, 150, 0.10)',
    boxShadow: '0px 1px 4px 2px rgba(150, 150, 150, 0.05)',
  },
});

export const ProfileDivider = styled.div({
  borderBottom: '1px solid #DEDEDE',
  marginTop: (props) => (props?.mt ? `${props?.mt}px` : '12px'),
  marginBottom: (props) => (props?.mb ? `${props?.mb}px` : '12px'),
  width: '100%',
  borderColor: (props) => props?.borderColor,
  boxShadow: (props) => props?.boxShadow,
});
export const ProfileOptionItemIconImg = styled.img({
  display: 'inline-block',
  height: 24,
  width: 24,
  verticalAlign: 'top',
  padding: '0px',
  backgroundColor: 'transparent',
  opacity: '0.3',
});

export const ContentContainer = styled.div({
  padding: '0px 8px',
});
export const ContentContainerSiOut = styled.div({
  padding: '0px 8px',
});
export const Space = styled.div({
  height: (props) => `${props.height}px`,
});
///

export const selectStyle = {
  disabled: {
    // backgroundColor: 'rgba(250, 250, 250, 0.8)',
  },
  input: {
    border: '0px',
    // '&:hover': {
    //   backgroundColor: 'rgba(220, 220, 220,0.2)',
    //   // opacity: 0.6,
    // },
    outline: 0,
  },
};

export const popoverStyle = {
  inner: {
    paddingBottom: 8,
  },
};

export const WrapIconGear = styled.div({
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
