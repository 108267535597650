import React from 'react';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  render() {
    if (this?.state?.hasError) {
      // You can render any custom fallback UI
      return (
        <b style={{ color: 'red' }}>
          {this.props.t('display.something_wrong')}[
          {this?.state?.error?.message}]{JSON.stringify(this?.state?.error)}
        </b>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
