import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  onResetOptionMenu,
  onSelectOptionMenu,
} from '../../reducers/layoutReducer/action';
import {
  isShowProfileViewSelector,
  showPaletteSelector,
  showSettingsLanguageSelector,
  showSettingsSelector,
} from '../../reducers/layoutReducer/layoutSelector';

export default function useLogicMenuOption() {
  const [currentOptionActive, setCurrentOptionActive] = useState('');
  const showSettings = useSelector(showSettingsSelector);
  const showSettingLanguage = useSelector(showSettingsLanguageSelector);
  const showModalProfile = useSelector(isShowProfileViewSelector);
  const isShowPaletteSelector = useSelector(showPaletteSelector);

  const dispatch = useDispatch();

  const onResetOptionMenuSelected = () => {
    dispatch(onResetOptionMenu());
    setCurrentOptionActive('');
  };
  const onSelectOption = (option) => {
    if (option === 'showSettings' && showSettings) {
      return onResetOptionMenuSelected();
    }
    if (option === 'showSettingLanguage' && showSettingLanguage) {
      return onResetOptionMenuSelected();
    }
    if (option === 'showPalette' && isShowPaletteSelector) {
      return onResetOptionMenuSelected();
    }
    dispatch(onSelectOptionMenu(option));
  };

  useEffect(() => {
    if (!showModalProfile) {
      return setCurrentOptionActive('');
    }
    if (showSettings) {
      return setCurrentOptionActive('showSettings');
    }
    if (showSettingLanguage) {
      return setCurrentOptionActive('showSettingLanguage');
    }
    if (isShowPaletteSelector) {
      return setCurrentOptionActive('showPalette');
    }
    if (!showSettings && !showSettingLanguage && !isShowPaletteSelector) {
      setCurrentOptionActive('');
    }
  }, [
    showSettings,
    showSettingLanguage,
    showModalProfile,
    isShowPaletteSelector,
  ]);
  return { onSelectOption, currentOptionActive, onResetOptionMenuSelected };
}
