import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getStartPositionSvgPolyline } from '../components/UI/Objects/ObjFieldDrawingPad/SVGPolylineRenderer';

export const renderCursorWithAction = (action) => {
  switch (action) {
    case 'delete':
      // return 'url(img/eraser.png), auto';
      return 'pointer';

    case 'move':
      return 'default';
    case 'mouse':
      return 'default';
    case 'drawing-polyline':
      return 'crosshair';
    case 'drawing-line':
      return 'crosshair';

    default:
      return 'crosshair';
  }
};
export const generateNewPointsItemDuplicate = (
  points = [],
  svgBoundingPad,
  svgItemBounding
) => {
  const newPoints = cloneDeep(points);
  const startPosSvgView = getStartPositionSvgPolyline(points);
  const centerX = svgBoundingPad.width / 2 - svgItemBounding.width / 2;
  const centerY = svgBoundingPad.height / 2 - svgItemBounding.height / 2;

  const translationsNewPoint = [];

  newPoints.map((point) => {
    translationsNewPoint.push({
      x: startPosSvgView.x - point.x,
      y: startPosSvgView.y - point.y,
    });
  });
  const listData = [];
  newPoints.map((_, index) => {
    const id = `id_${uuidv4()}`;
    listData.push({
      x: centerX - translationsNewPoint[index].x,
      y: centerY - translationsNewPoint[index].y,
      id,
    });
  });
  return listData;
};

const handleSwapIndexIndex = (type, lines, currentIndex) => {
  return lines.map((line) => {
    if (line.zIndex === currentIndex && type === 'forward') {
      line.zIndex -= 1;
    }
    if (line.zIndex === currentIndex && type === 'back') {
      line.zIndex += 1;
    }
    return line;
  });
};

export const changeIndexElement = (type, lines, keySvgSelect, topZIndex) => {
  const _lines = cloneDeep(lines);
  _lines.map((line) => {
    if (line.key === keySvgSelect) {
      if (type === 'forward' && line.zIndex < topZIndex - 1) {
        handleSwapIndexIndex(type, _lines, line.zIndex + 1);
        line.zIndex += 1;
      }
      if (type === 'back' && line.zIndex !== 1) {
        handleSwapIndexIndex(type, _lines, line.zIndex - 1);
        line.zIndex -= 1;
      }
    }
    return line;
  });
  return _lines;
};
