import { Stack, Text } from '@mantine/core';
import React from 'react';
import { CheckIcon } from '../../constants/icon';
import { FinishTabMappingContainer } from './ModalImportExcelCSS';

export default function FinishTabMapping() {
  return (
    <FinishTabMappingContainer>
      <Stack>
        <div style={{ width: 200, height: 200 }}>
          <CheckIcon />
        </div>
        <Text weight={400} size="xl">
          Completed import Excel
        </Text>
      </Stack>
    </FinishTabMappingContainer>
  );
}
