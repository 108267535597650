import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useMantineTheme } from '@mantine/core';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector';
import {
  ModalBackgdDiv,
  ModalContentDiv,
  ModalDiv,
  ModalTitleDiv,
  modalDialogStyle,
} from './ModalDialogCSS';
import { CloseIcon } from '../../../constants/icon';

class ModalDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offsetX: 0,
      offsetY: 0,
    };

    this.handleClickBG = this.handleClickBG.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  handleClickBG(e) {
    e.stopPropagation();
    if (
      e.currentTarget &&
      e.currentTarget.id === `div_bg${this.props.id}` &&
      e.target.id === `div_bg${this.props.id}`
    ) {
      this.props.onClose();
    }
  }

  handleDrag(e) {
    if (e.target.getAttribute('data-uitype') == 'titleBarModal') {
      switch (e.type) {
        case 'dragstart':
          this.setState({ startDragX: e.clientX, startDragY: e.clientY });
          break;
        case 'dragend':
          this.setState({
            offsetX: e.clientX - this.state.startDragX + this.state.offsetX,
            offsetY: e.clientY - this.state.startDragY + this.state.offsetY,
          });
          break;
        case 'drag':
          break;
        default:
          break;
      }
    }
  }

  render() {
    let {
      show,
      width,
      height,
      top,
      left,
      pal,
      menuPosX,
      menuPosY,
      onMouseLeave,
      id,
      paddingTitle,
      modalContentStyle = {},
    } = this.props;
    top = this.state.offsetY + menuPosY + top;
    left = this.state.offsetX + menuPosX + left;
    return show ? (
      <ModalBackgdDiv id={`div_bg${id}`} onClick={this.handleClickBG}>
        <ModalDiv
          id="modal_div_1"
          width={width}
          height={height}
          top={top}
          left={left}
          onMouseLeave={onMouseLeave}
          backgroundColor={pal?.objForm?.backgroundColor || 'white'}
          padding={this.props.modalPadding}
        >
          <div
            id="titleBar"
            data-uitype="titleBarModal"
            draggable={true}
            onDrag={this.handleDrag}
            onDragStart={this.handleDrag}
            onDragEnd={this.handleDrag}
            style={{
              ...modalDialogStyle.titleBarModal,
              backgroundColor:
                this.props?.theme.colorScheme === 'dark'
                  ? this.props?.theme.colors.blue_gray[9]
                  : this.props?.theme.colors.blue_gray[0],
            }}
          >
            <ModalTitleDiv color={pal?.text?.highlight} padding={paddingTitle}>
              {this.props.title}
            </ModalTitleDiv>
            <div
              onClick={this.props.onClose}
              style={modalDialogStyle.closeIcon}
            >
              <CloseIcon />
            </div>
          </div>
          <ModalContentDiv
            padding={this.props.modalContentPadding}
            id="modalContent"
            backgroundColor={pal?.objForm?.backgroundColor}
            style={modalContentStyle}
          >
            {this.props.children}
          </ModalContentDiv>
        </ModalDiv>
      </ModalBackgdDiv>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pal: currentPaletteActiveSelector(state),
    menuPosX: state.objects.menuPosX,
    menuPosY: state.objects.menuPosY,
  };
};
//--------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({});

const ModalDialogHOC = (props) => {
  const theme = useMantineTheme();
  return <ModalDialog {...props} theme={theme} />;
};
export default memo(
  connect(mapStateToProps, mapDispatchToProps)(ModalDialogHOC)
);

//-------------------------------------------------------------------------------------------------------
ModalDialog.defaultProps = {
  width: '600px',
  height: '400px',
  top: '200px',
  left: '200px',
  id: '1',
};
