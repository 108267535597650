export const buildCursorSvgPadWithAction = (isDisableAction, action, link) => {
  if (isDisableAction) {
    if (link) return 'pointer';
    return 'default';
  }
  switch (action) {
    case 'delete':
      return 'url(img/eraser.png) 12 12,  auto';

    default:
      return 'pointer';
  }
};
