import * as d3 from 'd3';
import React from 'react';
import { isInteger } from 'lodash';
import store from '../../store/configureStore';
import {
  ProgressBarContainer,
  ProgressBarLabel,
  ProgressBarsContainer,
  ProgressBarsLabel,
  ProgressBarsViewContainer,
  ProgressBarsViewValue,
  ProgressBarsViewValuePerc,
  ProgressViewContainer,
  ProgressViewValue,
  ProgressViewValueInPerc,
  StyledImg,
  StyledImgContainer,
} from './UtilsCSS.js';
import { abbreviateName } from './TextUtils';
import { parseJson } from '../../utils/parseJson.js';

export const IMAGE_FORMAT = ['jpg', 'jpeg', 'png', 'bmp', 'svg', 'webp'];
export const BASIC_FIELD = [
  'area',
  'text',
  'number',
  'date',
  'formula',
  'file',
  'location',
  'address',
  'list',
];
//-----------------------------------------------------------------------------------------
export function isFieldBasic(type) {
  return BASIC_FIELD.includes(type);
}

export const getProgressBar = (
  valueInPerc,
  label,
  colorOrig,
  unit = '',
  fontSize = 11,
  pal
) => {
  let color = '#EEEEEE';
  try {
    color = colorOrig;
  } catch (e) {}

  return (
    <>
      <ProgressBarContainer>
        {label && (
          <ProgressBarLabel
            fontSize={fontSize}
            color={pal?.text?.primary || '#333'}
            title={label}
          >
            {label}
          </ProgressBarLabel>
        )}
        <ProgressViewContainer minWidth={label ? '40%' : '80%'}>
          <ProgressViewValue
            color={color}
            fontSize={fontSize}
            backgroundColor={color}
            width={valueInPerc}
          />
        </ProgressViewContainer>
        <ProgressViewValueInPerc
          color={pal?.text?.primary || '#333'}
          fontSize={fontSize}
        >
          {valueInPerc}
          {unit}
        </ProgressViewValueInPerc>
      </ProgressBarContainer>
    </>
  );
};

//-----------------------------------------------------------------------------------------
export const getProgressBars = (
  sum,
  values,
  label,
  arrayColors,
  unit = '',
  fontSize = 11,
  pal
) => {
  const sumLine = values.reduce((acc, item) => acc + item, 0);
  const sumLinePerc =
    sum > 0 ? `${Math.round((sumLine / sum) * 100)}%` : `${0}%`;
  return (
    <>
      <ProgressBarsContainer>
        <ProgressBarsLabel
          color={pal?.text?.primary || '#333'}
          fontSize={fontSize}
        >
          {abbreviateName(label || '...')}
        </ProgressBarsLabel>
        <ProgressBarsViewContainer>
          {values.map((elem, ind) => (
            <ProgressBarsViewValue
              color={arrayColors[ind]}
              fontSize={fontSize}
              backgroundColor={arrayColors[ind]}
              width={`${(elem / sum) * 100}%`}
              key={arrayColors[ind]}
            />
          ))}
        </ProgressBarsViewContainer>
        <ProgressBarsViewValuePerc
          fontSize={fontSize}
          color={pal?.text?.primary || '#333'}
        >
          {sumLinePerc}
          {unit}
        </ProgressBarsViewValuePerc>
      </ProgressBarsContainer>
    </>
  );
};

//-----------------------------------------------------------------------------------------
export function rgbStringToHex(rgb) {
  if (!rgb || !rgb.startsWith('rgb')) return rgb;
  const values = rgb.split('(')[1].split(')')[0]; // contenu de la parenthèse rgb(r,g,b) => "r,g,b"
  const valuesTbl = values.split(','); // tableau de string "r,g,b" => r, g, b
  const valuesAsInt = valuesTbl.map((comp) => parseInt(comp)); // parse to number
  return rgbToHex(...valuesAsInt); // spread table as parameters
}
//-----------------------------------------------------------------------------------------
export function rgbToHex(r, g, b) {
  const res = `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)}`;
  return res;
}
//-----------------------------------------------------------------------------------------
export function confirmAction(message, callback, args) {
  if (window.confirm(message)) {
    if (args == null) {
      callback();
    } else {
      callback(args);
    }
  }
}
//-----------------------------------------------------------------------------------------
export function getTechNameFromLabel(label) {
  let res = '';
  if (label) {
    res = label.toLowerCase();
    res = res.replace(/[^0-9a-z]/gi, '');
  }
  return res;
}
//-----------------------------------------------------------------------------------------
export function getName(objFormData) {
  let res = '';
  if (objFormData) {
    const nameField = objFormData.find((a) => a.label === 'name');
    if (nameField) res = nameField.defaultValue;
  }
  return res;
}
//-----------------------------------------------------------------------------------------
export function getDateTicks(bandW, tickW, scalerXBand) {
  const nbrTick = bandW / tickW;
  const xAxis = d3
    ?.axisBottom(scalerXBand)
    ?.ticks(nbrTick)
    ?.tickFormat(d3.timeFormat('%d'));
  try {
    return xAxis
      ?.scale()
      ?.ticks(nbrTick)
      ?.map((item) => new Date(item).getTime());
  } catch (error) {
    console.log(':getDateTicks error', error);
    return [];
  }
}
//-----------------------------------------------------------------------------------------
export const getTimeRangeFromValues = (rawArray) => {
  let array = rawArray
    .filter((item) => {
      let num = parseInt(item, 10);
      return !isNaN(num);
    })
    .map((item) => parseInt(item, 10))
    .sort((a, b) => a - b);
  let timeDomainStart = new Date();
  let timeDomainEnd = new Date();
  if (array.length) {
    const minVal = parseInt(array[0]);
    const maxVal = parseInt(array[array.length - 1]);
    const spread = maxVal - minVal;
    const margin = spread * 0.2;
    timeDomainStart = d3.timeDay.offset(new Date(minVal - margin), -0);
    timeDomainEnd = d3.timeDay.offset(new Date(maxVal + margin), 0);
    console.log(`tds:${timeDomainStart}-tde:${timeDomainEnd}`);
  }
  return [timeDomainStart, timeDomainEnd];
};

//-----------------------------------------------------------------------------------------
export function isImageFormat(filename) {
  let res = false;
  if (filename) {
    res = IMAGE_FORMAT.some((format) =>
      filename.toLowerCase().endsWith(format)
    );
  }
  return res;
}
//-----------------------------------------------------------------------------------------
export function isPDF(filename) {
  let res = false;
  if (filename) {
    res = filename.toLowerCase().endsWith('pdf');
  }
  return res;
}

//-----------------------------------------------------------------------------------------
export function getFromDico(dicoNameRef, val, maxsize) {
  let res = val;
  if (dicoNameRef?.[val]) {
    let resTmp = dicoNameRef[val];
    if (maxsize) {
      resTmp = resTmp.substring(0, maxsize);
    }
    if (resTmp && resTmp.length > 0) {
      res = resTmp;
    }
  }
  return res;
}
//-----------------------------------------------------------------------------------------
export function getIconForModule(modKey) {
  const state = store.getState();
  const objectsDefList = state.objects.objectsDefList;
  let res = ``;
  if (objectsDefList && objectsDefList.length > 0) {
    const options = getOptionsForField(objectsDefList, `persp`, `module`);
    res = options.find((elem) => elem.value === modKey)?.icon;
  }
  return res;
}

//-----------------------------------------------------------------------------------------
export function getObjectDef(objectsDefList = [], key) {
  return objectsDefList?.find((odf) => odf.key.startsWith(key));
}
//-----------------------------------------------------------------------------------------
export function getObjectTypeId(objectsDefList = [], key) {
  return getObjectDef(objectsDefList, key)?.key;
}
//-----------------------------------------------------------------------------------------
export function getObjectTypeLabel(objectsDefList, objTypeName) {
  return getObjectDef(objectsDefList, objTypeName)?.label;
}
//-----------------------------------------------------------------------------------------
export function getObjectTypeLabelOrHome(objectsDefList, type) {
  let res = getObjectTypeLabel(objectsDefList, type);
  if (type.startsWith('ROOT')) res = 'HOME';
  return res;
}
//-----------------------------------------------------------------------------------------
export function getObjectDefField(objectsDefList = [], objTypeName, fldName) {
  return getObjectDef(objectsDefList, objTypeName)?.objFormData?.find(
    (fld) => fld.attrName === fldName
  );
}
export function formatNumber(n) {
  if (isNaN(n)) return n; // If not a number, return as is

  let absN = Math.abs(n); // Work with the absolute value for formatting

  if (absN < 1000) return n.toString(); // If less than 1,000, return as is

  // For numbers between 1,000 and 999,999
  if (absN < 1000000) {
    const formatted = (n / 1000).toFixed(1);
    return formatted.endsWith('.0')
      ? `${formatted.slice(0, -2)}K`
      : `${formatted}K`;
  }

  // For numbers 1 million or greater
  const formatted = (n / 1000000).toFixed(1);
  return formatted.endsWith('.0')
    ? `${formatted.slice(0, -2)}M`
    : `${formatted}M`;
}

export function formatMonetary(n) {
  return `${formatNumber(n)}€`;
}

//-----------------------------------------------------------------------------------------
export function getTypeOfField(objectsDefList = [], objTypeName, fldName) {
  return getObjectDefField(objectsDefList, objTypeName, fldName)?.type;
}
//-----------------------------------------------------------------------------------------
export function getTabOfField(objectsDefList = [], objTypeName, fldName) {
  return getObjectDefField(objectsDefList, objTypeName, fldName)?.tabName;
}
//-----------------------------------------------------------------------------------------
export function getOptionsForField(objectsDefList = [], objTypeName, fldName) {
  let options = getObjectDefField(
    objectsDefList,
    objTypeName,
    fldName
  )?.options;
  if (options) options = parseJson(options) ?? options;
  return options;
}
//-----------------------------------------------------------------------------------------
export function getListValueForField(
  objectsDefList = [],
  objTypeName,
  fldName
) {
  let options = getObjectDefField(
    objectsDefList,
    objTypeName,
    fldName
  )?.options;
  try {
    if (options) options = parseJson(options)?.map((a) => a.value);
    return options;
  } catch (error) {}
}
//-----------------------------------------------------------------------------------------
export function isNumericFormula(objectsDefList, objTypeName, fldName) {
  let isNumeric = false;
  const fldObj = getObjectDefField(objectsDefList, objTypeName, fldName);
  if (fldObj) {
    isNumeric = fldObj.formulaCat1 == null || fldObj.formulaCat1 === '';
  }
  return isNumeric;
}
//-----------------------------------------------------------------------------------------
export function getLabel(objectsDefList, objTypeName, fldName) {
  return getObjectDefField(objectsDefList, objTypeName, fldName)?.label;
}

//-----------------------------------------------------------------------------------------
export const ImageFromSVG = ({
  onClick,
  icon,
  withBorders = false,
  tooltipText = '',
}) => (
  <StyledImgContainer>
    <div
      className="xtooltip"
      style={{
        color: 'black',
        backgroundColor: 'white',
        position: 'absolute',
        left: '60px',
        top: '3px',
        opacity: 0,
        display: 'none',
      }}
    >
      {tooltipText}
    </div>
    <StyledImg
      withBorders={withBorders}
      src={`/img/icons/${icon}`}
      onClick={onClick}
    ></StyledImg>
  </StyledImgContainer>
);

//-----------------------------------------------------------------------------------------
// The two list are compared to find, which element was added or removed
export const getChangedElem = (arrayA, arrayB) => {
  let bigArray = arrayA;
  let smallArray = arrayB;
  if (arrayA.length < arrayB.length) {
    bigArray = arrayB;
    smallArray = arrayA;
  }
  const res = bigArray.filter((elem) => !smallArray.includes(elem))[0];

  return res;
};

//--------------------------------------------------------------------------------------------
export const removeTagHtml = (value) => {
  const doc = new DOMParser().parseFromString(value, 'text/html');
  const valueCleaned = doc.body.textContent || '';
  return valueCleaned;
};

//--------------------------------------------------------------------------------------------
export const isJSON = (data) => {
  try {
    const testIfJson = JSON.parse(data);
    return typeof testIfJson === 'object';
  } catch {
    return false;
  }
};
