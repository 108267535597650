import { colors } from './colors';

export const pal = [
  {
    paletteBtnColor: { from: 'indigo', to: 'cyan' },
    paletteVariant: 'gradient',
    isDark: true,
    logo: '/img/logos/logoNegative.svg',
    body: {
      backgroundColor: colors.io_white,
    },
    floatingButton: {
      backgroundColor: colors.br_turq,
    },
    pnlPersp: {
      backgroundColor: colors.br_secblue,
    },

    colIconButton: 'white',
    colBgMain: '#596068',
    colBgDisc: '#BDBFC3',
    colBgObjAll: '#4A4F58',
    colBgFilterItem: [
      '#D1D0DC',
      '#AFAEC2',
      '#9B9BB3',
      '#4A4F58',
      '#4A4F58',
      '#4A4F58',
    ],
    colBgObjMain: '#3B404A',
    colBgObjTransp: '#3B404A55',
    colBgHover: '#27496d33',
    colBgForm: '#4A4F58',
    colBgFld: '#59606855',
    colLbl: '#7FC97F',
    colLblDisc: 'red',
    colFldVal: 'white',
    colFldValOnLight: 'white',
    colFldValDisc: 'red',
    colTxt: 'white',
    colTxtHeader: 'white',
    colTxtHeaderOnLight: 'white',
    colTxtOnLight: 'white',
    colTxtTitle: 'white',
    colTxtFlash: 'red',
    colBgFlash: 'red',
    colArrow: 'white',
    colLineSeparator: '#FFFFFF11',
    colBorderDark: '#22262C77',
    colBorder: '#3E434B77',
    colBorderLight: '#FFFFFF11',
  },
  {
    paletteBtnColor: { from: 'orange', to: 'red' },
    paletteVariant: 'gradient',
    isDark: true,
    logo: '/img/logos/logoNegative.svg',
    body: {
      backgroundColor: colors.io_white,
    },
    floatingButton: {
      backgroundColor: colors.br_turq,
    },

    pnlPersp: {
      backgroundColor: colors.br_secblue,
    },
    colIconButton: 'white',
    colBgMain: 'DEDEDE',
    colBgDisc: '#EFE4D9',
    colBgObjAll: '#4A4F58',
    colBgFilterItem: [
      '#D1D0DC',
      '#AFAEC2',
      '#9B9BB3',
      '#4A4F58',
      '#4A4F58',
      '#4A4F58',
    ],
    colBgObjMain: '#545655',
    colBgObjTransp: '#54565522',
    colBgHover: '#27496d33',
    colBgForm: '#545655',
    colBgFld: '#999B9A55',
    colLbl: '#BDD1DA',
    colLblDisc: 'red',
    colFldVal: '#CDCECC',
    colFldValOnLight: '#545655',
    colFldValDisc: 'red',
    colTxt: 'white',
    colTxtHeader: 'white',
    colTxtHeaderOnLight: 'white',
    colTxtOnLight: '#545655',
    colTxtTitle: 'white',
    colTxtFlash: 'red',
    colBgFlash: 'red',
    colArrow: '#999B9A',
    colLineSeparator: '#FFFFFF11',
    colBorderDark: '#22262C77',
    colBorder: '#3E434B77',
    colBorderLight: '#FFFFFF11',
  },

  {
    paletteBtnColor: { from: 'black', to: 'black' },
    paletteVariant: 'gradient',

    isDark: false,
    logo: '/img/logos/logoPositive.svg',
    body: {
      backgroundColor: colors.io_black,
    },
    floatingButton: {
      backgroundColor: colors.br_turq,
    },

    pnlPersp: {
      backgroundColor: colors.br_softblack,
    },
    colIconButton: '#666666',
    colBgMain: '#F5F5F5',
    colBgDisc: '#BDBFC3',
    colBgObjAll: '#8A89A6',
    colBgFilterItem: [
      '#5F3DC4',
      '#5F3DC488',
      '#5F3DC455',
      '#4A4F58',
      '#4A4F58',
      '#4A4F58',
    ],
    colBgObjMain: '#FFFFFF',
    // colBgObjTransp:'#77777722',
    colBgObjTransp: '#AEAACF33',
    colBgHover: '#A9A8BE66',
    colBgForm: '#FFFFFF',
    colBgFld: '#DDDDDD22',
    colLbl: '#767687',
    colLblDisc: 'red',
    colFldVal: 'black',
    colFldValOnLight: 'black',
    colFldValDisc: 'red',
    colTxt: '#222',
    colTxtHeader: '#444',
    colTxtHeaderOnLight: 'white',
    colTxtOnLight: '#444',
    colTxtTitle: '#333',
    colTxtFlash: 'red',
    colBgFlash: 'red',
    colArrow: '#228BE6',
    colLineSeparator: '#00000022',
    colBorderDark: '#22262C11',
    colBorder: '#77777744',
    colBorderLight: '#FFFFFF22',
  },

  {
    paletteBtnColor: { from: 'white', to: 'white' },
    paletteBtnStyles: {
      backgroundColor: 'white',
      color: '#010101',
    },
    paletteVariant: 'gradient',
    isDark: false,
    logo: '/img/logos/logoPositive.svg',
    body: {
      backgroundColor: colors.io_black,
    },
    floatingButton: {
      backgroundColor: colors.br_turq,
    },

    pnlPersp: {
      backgroundColor: colors.br_softblack,
    },
    colIconButton: '#666666',
    colBgMain: '#FFF',
    colBgDisc: '#BDBFC3',
    colBgObjAll: 'red',
    colBgFilterItem: ['red', 'red', 'red', 'red', 'red', 'red'],
    colBgObjMain: '#FFFFFF',
    colBgObjTransp: '#DDDDDD55',
    colBgHover: '#27496d',
    colBgForm: '#FFFFFF',
    colBgFld: '#DDD',
    colLbl: '#5B9475',
    colLblDisc: 'red',
    colFldVal: 'black',
    colFldValOnLight: 'black',
    colFldValDisc: 'red',
    colTxt: '#444',
    colTxtHeader: '#444',
    colTxtHeaderOnLight: 'white',
    colTxtOnLight: '#444',
    colTxtTitle: '#222',
    colTxtFlash: 'red',
    colBgFlash: 'red',
    colArrow: '#33333355',
    colLineSeparator: '#00000033',
    colBorderDark: '#22262C33',
    colBorder: '#77777733',
    colBorderLight: '#FFFFFF44',
  },
];

export const paletteBtnColor = {
  dark: [
    {
      from: '#959595',
      to: '#555555',
      deg: 60,
    },
    {
      from: '#3D4A5E',
      to: '#647185',
    },
    {
      from: '#393268',
      to: '#837BB5',
      deg: 60,
    },
    {
      from: '#305541',
      to: '#67947C',
    },
  ],
  light: [
    {
      from: '#555555',
      to: '#959595',
      deg: 80,
    },
    {
      from: '#647185',
      to: '#3D4A5E',
    },
    {
      from: '#837BB5',
      to: '#393268',
      deg: 60,
    },
    {
      from: '#67947C',
      to: '#305541',
      deg: 60,
    },
  ],
};
