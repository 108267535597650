import produce from 'immer';
import { AUTH_TYPE } from './type';

const initialState = {
  idUser: null,
  idEmail: null,
  userPriv: {},
};
export const authReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case AUTH_TYPE.CHECKED_TOKEN:
      return produce(state, (draft) => {
        draft.idUser = action.objects?.userId;
        draft.idEmail = action.objects?.email;
      });

    case AUTH_TYPE.RECEIVED_USERPRIVS:
      return produce(state, (draft) => {
        draft.userPriv = action.objects;
      });
    default:
      return state;
  }
};
