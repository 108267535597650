export const fixed_field_type = [
  { label: 'Text', name: 'text', icon: 'anchor.png', predefined: true },
  { label: 'Number', name: 'number', icon: 'anchor.png', predefined: true },
  {
    label: 'Camera Recorder',
    name: 'camrec',
    icon: 'anchor.png',
    predefined: true,
  },
  { label: 'Drawing', name: 'drawing', icon: 'anchor.png', predefined: true },
  { label: 'Date', name: 'date', icon: 'anchor.png', predefined: true },
  { label: 'Paragraph', name: 'area', icon: 'anchor.png', predefined: true },
  { label: 'Formula', name: 'formula', icon: 'anchor.png', predefined: true },
  { label: 'List', name: 'list', icon: 'anchor.png', predefined: true },
  { label: 'Location', name: 'location', icon: 'anchor.png', predefined: true },
  { label: 'Address', name: 'address', icon: 'anchor.png', predefined: true },
  { label: 'File', name: 'file', icon: 'anchor.png', predefined: true },
];
