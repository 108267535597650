import styled from 'styled-components';

export const ContentActivityLog = styled.div({
  paddingTop: 12,
  backgroundColor: (props) =>
    props.currentPaletteActive.activityLog.backgroundColorBlur,
  borderRadius: 4,
  height: 'calc(100% - 90px)',
  overflow: 'hidden',
  paddingBottom: 20,
  width: 'calc(100% - 20px)',
});

export const WrapIconArrowDown = styled.div({
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '6px 0px',
});

export const WrapLoadingIcon = styled.h4({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const WrapButtonComment = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '8px 30px 0px 0px',
});

export const WrapIconSend = styled.div({
  width: 12,
  height: 12,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const WrapShowComment = styled.div({
  cursor: 'pointer',
  opacity: 0.7,
  ':hover': {
    opacity: 1,
  },
  position: 'absolute',
  right: (props) => `${props.right}px`,
  top: 0,
});

export const WrapTimeLineIcon = styled.div({
  width: 24,
  height: 24,
  borderRadius: 24,
  padding: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
});

export const WrapApplyButton = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  height: (props) => props.height,
});

export const scaleY = {
  in: { opacity: 1, transform: 'scaleY(1)' },
  out: { opacity: 0, transform: 'scaleY(0)' },
  common: { transformOrigin: 'top' },
  transitionProperty: 'transform, opacity',
};

export const groupTimelineItemStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: 'calc(100% - 80px)',
  padding: 10,
  paddingRight: 70,
  borderRadius: 5,
  height: 'auto',
  border: '0px solid green',
  overflowX: 'hidden',
  overflowY: 'hidden',
};

export const WrapActivityLogContent = styled.div({
  paddingRight: 80,
  border: '0px solid red',
  width: 'calc(100% - 10px)',
  overflowX: 'hidden',
  overflowY: 'hidden',
  height: 'auto',
});
export const timelineStyle = {
  itemBody: {
    display: 'flex',
  },
  itemContent: {
    width: '100%',
  },
  root: {},
  item: {
    marginTop: '12px !important',
  },
};
export const TimeLineInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  maxWidth: 160,
  minWidth: 120,
  paddingRight: 30,
});
export const timelineAuthorStyle = {
  fontSize: 12,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const rootStyleTextarea = {
  padding: '16px 30px 6px 0px',
};

export const CrumbBtn = styled.div({
  ':hover': {
    cursor: 'pointer',
  },
});

export const PaddingTopContentTimeLine = styled.div({
  paddingTop: 8,
});

export const WrapButtonCloseView = styled.div({
  position: 'absolute',
  top: 13,
  right: 30,
});

export const WrapIconAuthor = styled.div({
  borderRadius: 16,
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 2,
});

export const WrapButtonIconAuthor = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 6,
  padding: '2px 6px',
  border: '1px solid #ADB4BB',
  ':hover': {
    backgroundColor: '#F4F4F4',
  },
  ':active': {
    backgroundColor: '#EDEDED',
  },
  marginLeft: 4,
});

export const ActivityLogsContainer = styled.div({
  width: '100%',
  height: '100%',
});

export const ActivityLogsTitle = styled.div({
  color: (props) => props.color,
  fontSize: 27,
  fontWeight: '550',
  paddingTop: 16,
  borderBottom: '1px dashed #DDD',
  height: 80,
  width: 'calc(95% - 25px)',
});

export const headerActivityLogStyles = {
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 20,
    paddingRight: 40,
  },
  wrapInputAuthor: {
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    borderRadius: 4,
    paddingLeft: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
  },
  buttonMe: {
    color: '#ADB4BB',
    lineHeight: 'normal',
  },
};
