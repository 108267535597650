export const LIBRARY_TYPE = {
  SET_DATA_ICONS: 'SET_DATA_ICONS',
  SET_CURRENT_ICON_SELECT: 'SET_CURRENT_ICON_SELECT',
  CHANGE_STATUS_SHOW_MODAL_ICONS: 'CHANGE_STATUS_SHOW_MODAL_ICONS',
  CHANGE_SESSION_ICONS_LIBRARY: 'CHANGE_SESSION_ICONS_LIBRARY',
  SET_ACTION_ADD_RESOURCE: 'SET_ACTION_ADD_RESOURCE',
  SET_CALLBACK_FUN_SELECT_ICON: 'SET_CALLBACK_FUN_SELECT_ICON',
  SET_LOADING_LOAD_DATA_ICONS_LIBRARY: 'SET_LOADING_LOAD_DATA_ICONS_LIBRARY',
  CHANGE_MODE_LIBRARY_ICON: 'CHANGE_MODE_LIBRARY_ICON',
};
export const MODE_LIBRARY_ICON = {
  SELECT_ICON: 'SELECT_ICON',
  VIEW_AND_ADD_ICON: 'VIEW_AND_ADD_ICON',
  NONE: 'NONE',
};
