import React, { memo } from 'react';

const ProfileAuth0Content = React.lazy(() => import('./ProfileAuth0Content'));

const ProfileAuth0 = () => {
  return (
    <React.Suspense fallback={null}>
      <ProfileAuth0Content />
    </React.Suspense>
  );
};

export default memo(ProfileAuth0);
