const CONFIG = (state = DEFAULT_STATE, action, root) => {
  let res;
  switch (action.type) {
    //--------------------------------------------------------------------------------------------------
    case 'TOGGLE_DEVELOPMENT':
      res = { ...state, isDevelopment: !state.isDevelopment };
      return res;
    //--------------------------------------------------------------------------------------------------
    default:
      return state;
  }
};

//--------------------------------------------------------------------------------------------------
const DEFAULT_STATE = {
  isDevelopment: process.env.NODE_ENV === 'development',
};
//--------------------------------------------------------------------------------------------------
export default CONFIG;
