/* eslint-disable no-use-before-define */
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import {
  getObjectDef,
  getTypeOfField,
  getTabOfField,
  isImageFormat,
  isNumericFormula,
  getOptionsForField,
} from './Utils';

export function getNextType(objectsDefList, objectType, type) {
  let res = type;
  const arrayToFilter = getNameAndLabelByType(objectsDefList, objectType);
  const filtArray = filterForAlignAndSort(
    objectsDefList,
    arrayToFilter,
    objectType
  );

  if (filtArray) {
    filtArray.push(['default', 'default']);
    let indElem = filtArray.findIndex((a) => a[0] === type);
    do {
      indElem = (indElem + 1) % filtArray.length;
      res = filtArray[indElem]?.[0] || null;
    } while (res === null);
  }
  return res;
}
//-------------------------------------------------------------------------------------------------------------
export function filterForAlignAndSort(
  objectsDefList,
  arrayToFilter,
  objectType
) {
  const dropType = ['area', 'file', 'number', 'text', 'date'];
  const res = arrayToFilter?.filter((a) => {
    if (getTabOfField(objectsDefList, objectType, a[0]) === 'system')
      return false;
    const type = getTypeOfField(objectsDefList, objectType, a[0]);
    const options = getOptionsForField(objectsDefList, objectType, a[0]);

    const bKeep =
      (!dropType.includes(type) ||
        options === 'weekselector' ||
        options === 'monthselector') &&
      (type != 'formula' || isNumericFormula(objectsDefList, objectType, a[0]));
    return bKeep;
  });
  return res;
}

//-------------------------------------------------------------------------------------------------------------
export function filterForStat(objectsDefList, arrayToFilter, objectType) {
  const keepType = ['number'];
  const res = arrayToFilter?.filter((a) => {
    const type = getTypeOfField(objectsDefList, objectType, a[0]);

    const bKeep =
      keepType.includes(type) ||
      (type == 'formula' && isNumericFormula(objectsDefList, objectType, a[0]));
    return bKeep;
  });
  return res;
}

//------------------------------------------------------------------------------------------------------------
export function prepareFilteredNodes(nodes, collapsedNodes, arraySortBy) {
  let resNodes = removeCollapsed(nodes, collapsedNodes);
  resNodes = sortArray(resNodes, 0, arraySortBy);
  return resNodes;
}
//------------------------------------------------------------------------------------------------------------
export function getNameAndLabelByType(objectsDefList, type) {
  const flds = getObjectDef(objectsDefList, type);
  return flds?.objFormData?.map((elem) => [elem.attrName, elem.label]);
}
//------------------------------------------------------------------------------------------------------------
export function getFirstImage(objectsDefList, node) {
  const flds = getObjectDef(objectsDefList, node.type);
  let res = null;
  if (flds?.objFormData) {
    const fileLabels = flds.objFormData
      ?.filter((elem) => elem.type === 'file')
      .map((elem) => elem.label);
    const label = fileLabels.find(
      (label) =>
        node[label]?.length > 0 && isImageFormat(node[`${label}OrigName`])
    );
    if (label) res = node[label];
  }
  //
  return res;
}
//------------------------------------------------------------------------------------------------------------
export function getPreferedLinesByType(type, objectsDefList) {
  let res = [];
  if (objectsDefList) {
    const objDef = objectsDefList?.find((elem) => elem.key === type);

    if (objDef) {
      res = objDef.objFormData
        .filter((item) => item.displayByDefault === 'true')
        .map((item) => {
          return {
            data: item.label,
            to: type,
          };
        });
    }
  }
  return res;
}

//------------------------------------------------------------------------------------------------------------
export function getObjectTypeByLabel(label, objectsDefList) {
  const res = objectsDefList?.find((elem) => elem.label === label)?.key;
  return res;
}
//------------------------------------------------------------------------------------------------------------
export function getRealPatternFromPrefixes(pattern, objectsDefList) {
  const elems = pattern.split('_');
  for (let i = 0; i < elems.length; i++) {
    elems[i] = getObjectTypeByLabel(elems[i], objectsDefList) ?? elems[i];
  }
  return elems.join('_');
}
//------------------------------------------------------------------------------------------------------------
export function removeOrphans(nodes) {
  return nodes
    ?.slice()
    .sort((a, b) => a.depth - b.depth)
    .reduce((res, item) => {
      if (item.depth === 1) {
        res.push(item);
      } else if (res.find((elem) => elem.key === item.parent.key))
        res.push(item);
      return res;
    }, []);
}
//------------------------------------------------------------------------------------------------------------
export function sortObjectFormData(objFormData) {
  return objFormData.sort((a, b) => {
    let res = 0;
    try {
      if (a.order && !isNaN(a.order) && b.order && !isNaN(b.order)) {
        res = parseInt(a.order ?? 999) - parseInt(b.order ?? 999);
      }
    } catch (e) {}
    return res;
  });
}
//------------------------------------------------------------------------------------------------------------
export function cleanupNodes(nodes) {
  let cleanNodes = nodes;

  cleanNodes = removeOrphans(cleanNodes);
  cleanNodes = getArrayChildrenFromHierarchy(cleanNodes);
  cleanNodes = cleanNodes.filter((node) => node.depth > 0);
  cleanNodes = cleanNodes.map((node) => {
    node.level = node.depth - 1;
    return node;
  });
  return cleanNodes;
}

//------------------------------------------------------------------------------
export function removeCollapsed(nodes, collapsed) {
  if (!collapsed) return nodes;
  return nodes.reduce((res, a) => {
    if (a) {
      if (a.children && !collapsed.includes(a.key)) {
        a.children = removeCollapsed(a.children, collapsed);
      } else {
        a.children = null;
      }
      res.push(a);
    }
    return res;
  }, []);
}

//------------------------------------------------------------------------------------------------------------
export function keepNodesOnLayers(nodes, layers, hiddenLayers) {
  const cleanNodes = nodes.filter(
    (node) =>
      layers != undefined &&
      node.data.layers.filter(
        (elem) =>
          layers.map((elem) => elem.key).includes(elem) &&
          !this.props.hiddenLayers.includes(elem)
      ).length > 0
  );
  return cleanNodes;
}
//------------------------------------------------------------------------------------------------------------
export function getFieldSize(type, value) {
  let resSize = 0;
  if (type != null) {
    const fldValue = value;
    switch (type) {
      case 'area':
        resSize += 2;
        break;
      case 'text':
        resSize += 1;
        break;
      case 'list':
        resSize += 2.5;
        break;
      case 'file':
        resSize += 7;
        break;
      case 'camrec':
        resSize += 8;
        break;
      case 'drawing':
        resSize += 4;
        break;
      case 'formula':
        if (fldValue?.startsWith('[') && fldValue?.length > 2) {
          resSize += 7;
        } else if (!fldValue?.startsWith('[')) {
          resSize += 1;
        }
        break;
      default:
        resSize += 1;
        break;
    }
    // }
  }
  return resSize;
}

//------------------------------------------------------------------------------------------------------------
// les données sont filtrées selon le set de restriction passé en paramètre
export function isToShow(data, restrictions) {
  const isShowed = true;
  for (const indRest in restrictions) {
    // pour chaque restriction
    const restriction = restrictions[indRest];
    if (data.type != null && data.type.indexOf(restriction.objectType) == 0) {
      // est-ce que le current node est concerné par cette restriction (en foct du type)
      const { comparator } = restriction;
      const fieldName = Object.keys(restriction)[0];
      const valueRest = restriction[fieldName];
      const valueNode = data[fieldName];
      if (valueNode === undefined) return false;
      if (comparator == '=' && valueNode != valueRest) return false;
      if (comparator == '>' && valueNode < valueRest) return false;
      if (comparator == '<' && valueNode > valueRest) return false;
    }
  }
  return isShowed;
}
//------------------------------------------------------------------------------------------------------------
export function sortArray(paramArray, level, arraySortBy) {
  const sortKey = arraySortBy[level];
  let resArray = sortBy(paramArray, (item) => item[sortKey]);
  resArray = resArray.map((a) => {
    const newA = { ...a };
    if (a !== null && a.children !== undefined) {
      newA.children = sortArray(a.children, level + 1, arraySortBy);
    }
    return newA;
  });
  return resArray;
}
//------------------------------------------------------------------------------------------------------------
export function getArrayChildrenFromHierarchy(nodes, trgList = []) {
  if (isEmpty(nodes)) {
    return trgList;
  }
  nodes?.map((item) => {
    trgList.push(item);
    if (item.children) {
      getArrayChildrenFromHierarchy(item.children, trgList);
    }
    return null;
  });
  return trgList;
}
//------------------------------------------------------------------------------------------------------------
