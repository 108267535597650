import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultUserIconSmall } from '../../constants/icon';
import {
  onShowProfileView,
  setShowMenuMobile,
} from '../../reducers/layoutReducer/action';
import { currentPaletteActiveSelector } from '../../reducers/themeReducers/themeSelector';
import { DivFloatingButton } from '../Main/appStyles';
import { ProfileAuthIcon, ProfileAuthIconDefault } from './ProfileAuth0Css';
import { isMobileSelector } from '../../reducers/responsiveReducer/responsiveSelector';

export default function ButtonOpenProfile() {
  const dispatch = useDispatch();
  const currentPaletteActive = useSelector(currentPaletteActiveSelector);

  const isMobile = useSelector(isMobileSelector);

  const { user } = useAuth0();

  const onSetOpen = useCallback(() => {
    if (isMobile) {
      dispatch(setShowMenuMobile(false));
    }
    dispatch(onShowProfileView());
  }, []);
  return (
    <DivFloatingButton
      onClick={onSetOpen}
      onTouchEnd={onSetOpen}
      hoverBackgroundColor={currentPaletteActive?.floatingButton?.hoverColor}
      backgroundColor={currentPaletteActive?.floatingButton?.backgroundColor}
    >
      {user?.picture ? (
        <ProfileAuthIcon
          width="32"
          height="32"
          src={user?.picture}
          alt={user?.name}
        />
      ) : (
        <ProfileAuthIconDefault>
          <DefaultUserIconSmall />
        </ProfileAuthIconDefault>
      )}
    </DivFloatingButton>
  );
}
