// 'use strict';
import React from 'react';

import { Button, Group, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { widthScreen } from '../../../../utils/windowSize';
import ModalDialog from '../../ModalDialog/ModalDialog';
import DropFileObjDrawingPad from './DropFileObjDrawingPad';
import ModalSelectIconDrawing from './ModalSelectIconDrawing';

import { colorsSvgDrawing } from '../../../../constants/colors';
import { renderCursorWithAction } from '../../../../utils/functionSvgDrawing';
import ActionDrawPanel from './ActionDrawPanel';
import ButtonSaveToLibrary from './ButtonSaveToLibrary';
import GridsDrawing from './GridsDrawing';
import {
  ObjFieldDrawingPadModalContent,
  ObjFieldDrawingPadModalItem,
  ObjFieldDrawingPadModalList,
  ObjFieldDrawingPadModalPalette,
} from './ObjFieldDrawingPadCSS';
import SVGPolylineRenderer from './SVGPolylineRenderer';
import SvgArrowLineRenderer from './SvgArrowLineRenderer';
import SvgRenderer from './SvgRenderer';
import SvgTextRenderer from './SvgTextRenderer';

export default function SvgDrawing({
  editMode,
  toggleEditMode,
  onLink,
  onAddTextSvg,
  onChangeLineDrawing,
  lineDrawing,
  onChangeAction,
  action,
  keySvgSelect,
  lines,
  onResizeSvg,
  onDeleteSvg,
  onChangeTick,
  tick,
  setShowGrids,
  isShowGrids,
  sizeGrid,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  setActionDragSvg,
  setSvgFocus,
  handleSaveSvgFileToDb,
  onDeleteSvgWithId,
  idLineDrawing,
  onUpdatePointsOnMove,
  handleCleanAll,
  onGeneratePoly,
  handleUndo,
  toggleDelete,
  handleColorChange,
  color,
  onAddFile,
  onDuplicateItem,
  onChangeLayout,
  onUpdateItemPolylineOrArrowLine,
  isEditSvgLibIcon,
  updateIconSvgDrawing,
  onLoadSourceFromTemplateIcon,
}) {
  const { t } = useTranslation();

  const theme = useMantineTheme();
  return (
    <ModalDialog
      title="Drawing Panel"
      width={widthScreen > 900 ? 900 : widthScreen - 100}
      height="700"
      show={editMode}
      onClose={toggleEditMode}
      // padding
      modalPadding="0px"
      modalContentPadding="0px"
    >
      <ObjFieldDrawingPadModalContent id="pad" draggable={false}>
        <ActionDrawPanel
          color={color}
          onAddTextSvg={onAddTextSvg}
          onChangeLineDrawing={onChangeLineDrawing}
          lineDrawing={lineDrawing}
          onChangeAction={onChangeAction}
          action={action}
          keySvgSelect={keySvgSelect}
          lines={lines}
          onLink={onLink}
          onResizeSvg={onResizeSvg}
          onDeleteSvg={onDeleteSvg}
          onChangeTick={onChangeTick}
          tick={tick}
          setShowGrids={setShowGrids}
          isShowGrids={isShowGrids}
          sizeGrid={sizeGrid}
          onDuplicateItem={onDuplicateItem}
          onChangeLayout={onChangeLayout}
          onUpdateItemPolylineOrArrowLine={onUpdateItemPolylineOrArrowLine}
        />
        <div id="wrapSvgPad" style={{ width: '100%' }}>
          <svg
            id="svgPad"
            draggable={false}
            onTouchStart={handleTouchStart}
            onMouseDown={handleTouchStart}
            onTouchMove={handleTouchMove}
            onMouseMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseUp={handleTouchEnd}
            width={'100%'}
            height="640"
            style={{
              touchAction: 'none',
              cursor: renderCursorWithAction(action),
            }}
          >
            {isShowGrids && <GridsDrawing sizeGrid={sizeGrid} />}
            {lines?.map((lineSvg, index) => {
              if (lineSvg?.type === 'svg' || lineSvg?.type === 'image') {
                return (
                  <SvgRenderer
                    svg={lineSvg}
                    key={lineSvg.key}
                    setActionDragSvg={setActionDragSvg}
                    setSvgFocus={setSvgFocus}
                    keySvgSelect={keySvgSelect}
                    handleSaveSvgFileToDb={handleSaveSvgFileToDb}
                    action={action}
                    onDeleteSvgWithId={onDeleteSvgWithId}
                    sizeGrid={sizeGrid}
                    isShowGrids={isShowGrids}
                    idLineDrawing={idLineDrawing}
                  />
                );
              }
              if (lineSvg?.type === 'text') {
                return (
                  <SvgTextRenderer
                    svg={lineSvg}
                    key={lineSvg.key}
                    setActionDragSvg={setActionDragSvg}
                    setSvgFocus={setSvgFocus}
                    keySvgSelect={keySvgSelect}
                    handleSaveSvgFileToDb={handleSaveSvgFileToDb}
                    action={action}
                    onDeleteSvgWithId={onDeleteSvgWithId}
                    sizeGrid={sizeGrid}
                    isShowGrids={isShowGrids}
                  />
                );
              }
              if (
                ['bidirectional-line', 'arrow-line'].includes(lineSvg?.type)
              ) {
                return (
                  <SvgArrowLineRenderer
                    key={lineSvg.key}
                    lineData={lineSvg}
                    idLineDrawing={idLineDrawing}
                    setSvgFocus={setSvgFocus}
                    keySvgSelect={keySvgSelect}
                    action={action}
                    onDeleteSvgWithId={onDeleteSvgWithId}
                    setActionDragSvg={setActionDragSvg}
                    onUpdatePointsOnMove={onUpdatePointsOnMove}
                    sizeGrid={sizeGrid}
                    isShowGrids={isShowGrids}
                  />
                );
              }

              if (lineSvg?.type === 'polyline' || lineSvg?.type === 'line') {
                return (
                  <SVGPolylineRenderer
                    key={lineSvg.key}
                    lineData={lineSvg}
                    idLineDrawing={idLineDrawing}
                    setSvgFocus={setSvgFocus}
                    keySvgSelect={keySvgSelect}
                    action={action}
                    onDeleteSvgWithId={onDeleteSvgWithId}
                    setActionDragSvg={setActionDragSvg}
                    onUpdatePointsOnMove={onUpdatePointsOnMove}
                    sizeGrid={sizeGrid}
                    isShowGrids={isShowGrids}
                  />
                );
              }
            })}

            {onGeneratePoly()}
          </svg>
        </div>
        <ObjFieldDrawingPadModalPalette
          id="palette"
          backgroundColor={
            theme.colorScheme === 'dark'
              ? theme.colors.cool_gray[8]
              : theme.colors.blue_gray[1]
          }
        >
          <Group direction="column" position="center" spacing="xs">
            <ButtonSaveToLibrary
              isEditSvgLibIcon={isEditSvgLibIcon}
              lines={lines}
              toggleEditMode={toggleEditMode}
              updateIconSvgDrawing={updateIconSvgDrawing}
            />
            <Button
              styles={{ root: { width: 70 } }}
              compact
              onClick={handleCleanAll}
              onTouchStart={handleCleanAll}
            >
              {t('object.clear')}
            </Button>
            <Button
              styles={{ root: { width: 70 } }}
              compact
              onClick={handleUndo}
              onTouchStart={handleUndo}
            >
              {t('object.undo')}
            </Button>
            <Button
              styles={{ root: { width: 70 } }}
              compact
              color={action === 'delete' ? 'red' : 'blue'}
              onClick={toggleDelete}
              onTouchStart={toggleDelete}
            >
              {t('object.delete')}
            </Button>

            <ObjFieldDrawingPadModalList>
              {colorsSvgDrawing.map((e, index) => (
                <ObjFieldDrawingPadModalItem
                  id={`index_color_${index}`}
                  key={e}
                  onClick={handleColorChange}
                  onTouchStart={handleColorChange}
                  border={e === color ? '3px solid #1C7ED6' : '3px solid #DDD'}
                  backgroundColor={e}
                />
              ))}
            </ObjFieldDrawingPadModalList>
          </Group>
          <DropFileObjDrawingPad onAddFile={onAddFile} />

          <ModalSelectIconDrawing
            onAddFile={onAddFile}
            isEditSvgLibIcon={isEditSvgLibIcon}
            onLoadSourceFromTemplateIcon={onLoadSourceFromTemplateIcon}
          />
        </ObjFieldDrawingPadModalPalette>
      </ObjFieldDrawingPadModalContent>
    </ModalDialog>
  );
}
