import ObjectActionCreators from '../../actions';
import ObjectApi from '../../api/ObjectApi';
import { AUTH_TYPE } from './type';

export const checkToken = (idToken, provider) => {
  return (dispatch) => {
    ObjectApi.checkToken(idToken, provider).then(
      (objects) => {
        dispatch({
          type: AUTH_TYPE.CHECKED_TOKEN,
          success: true,
          objects,
        });
        dispatch(ObjectActionCreators.initApp());
      },
      (error) => dispatch({ type: AUTH_TYPE.CHECKED_TOKEN, success: false })
    );
  };
};

const getUserPrivs = () => {
  return (dispatch) => {
    dispatch({ type: 'REQUEST_USERPRIVS' });
    ObjectApi.getUserPrivs().then(
      (objects) =>
        dispatch({
          type: AUTH_TYPE.RECEIVED_USERPRIVS,
          success: true,
          objects,
        }),
      (error) =>
        dispatch({ type: AUTH_TYPE.RECEIVED_USERPRIVS, success: false })
    );
  };
};
