import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ObjectActionCreators from '../actions';
import { checkToken } from '../reducers/authReducer/action';

export const ProfileProvider = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        if (!isLoading) {
          let token = null;
          if (isAuthenticated) {
            try {
              token = await getAccessTokenSilently({
                audience: `https://p2.io.land/api`,
                redirect_uri: window.location.origin,
                scope: 'read:current_user',
                useRefreshTokens: true,
                ignoreCache: true,
              });
            } catch (e) {
              if (e.error === 'login_required') {
                loginWithRedirect();
              }
              if (e.error === 'consent_required') {
                loginWithRedirect();
              }
              // throw e;
            }
          }

          dispatch(checkToken(token, 'auth0'));

          if (token)
            sessionManagement(logout, () =>
              dispatch(ObjectActionCreators.pingServer())
            );
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isLoading]);

  if (isLoading) return null;
  return children;
};

const sessionManagement = (logout, pingServerFct) => {
  const IdealTimeOut = 60 * 15; // in seconds = 15 min => shhould be in config
  let idleSecondsTimer = null;
  let pingServerTimer = null;
  let idleSecondsCounter = 0;
  document.onclick = function () {
    idleSecondsCounter = 0;
  };
  document.onmousemove = function () {
    idleSecondsCounter = 0;
  };
  document.onkeypress = function () {
    idleSecondsCounter = 0;
  };
  idleSecondsTimer = window.setInterval(CheckIdleTime, 1000);
  pingServerTimer = window.setInterval(
    pingServerFct,
    1000 * (IdealTimeOut - 60)
  ); // ping refresh server every 14 min

  function CheckIdleTime() {
    idleSecondsCounter++;

    if (idleSecondsCounter >= IdealTimeOut) {
      window.clearInterval(idleSecondsTimer);
      window.clearInterval(pingServerTimer);
      logout({ returnTo: window.location.origin });
    }
  }
};
