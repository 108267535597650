import { createSelector } from 'reselect';

export const objFormDataSelector = (state) => state?.objects?.objFormData;

export const currObjNbrChildsSelector = (state) =>
  state?.objects?.currObjNbrChilds || -1;

export const perspectivesSelector = createSelector(
  (state) => state.objects.perspectives,
  (data) => {
    if (data) {
      return data
        .map((a) => (a == null ? '' : a))
        .sort((a, b) => (a.module + a.name).localeCompare(b.module + b.name));
    }
    return [];
  }
);

export const isLoadingObjDataSelector = (state) =>
  state?.objects?.isLoadingObjData;

export const isLoadingPerspectivesSelector = (state) =>
  state?.objects?.isLoadingPerspectives;

export const objectsDefListSelector = createSelector(
  (state) => state.objects.objectsDefList,
  (data) => data
);
