import {
  getListValueForField,
  getTypeOfField,
} from '../components/Utils/Utils.js';
import { OBJECTS_TYPE } from './objectReducers/type.js';

const initialState = {
  classifFieldType: ['', '', ''], // e.g. ['text','date','list']
  arrayClassifBy: ['default', 'default', 'default'], // e.g.['name','assignee','default']
  bandValues: [[''], [''], ['']],
  scales: [],
};

const CLASSIFIER_REDUCER = (state = initialState, action, root) => {
  let res;
  switch (action.type) {
    case 'POST_SET_CLASSIFX':
    case 'POST_RECEIVE_TREE':
    case 'POST_SET_STAT_BY':
    case 'POST_OPEN_PERSPECTIVE':
      const { pattern } = root.objects;
      const { objectsDefList } = root.objects;
      const classifFieldType = getTypeOfFieldByPanel(
        pattern,
        objectsDefList,
        root.objects.arrayClassifBy
      );
      const bandValues = getAllBandValues(
        root.objects.nodes,
        root.objects.objectsDefList,
        classifFieldType,
        root.objects.arrayClassifBy
      );
      res = {
        ...state,
        arrayClassifBy: root.objects.arrayClassifBy,
        classifFieldType,
        bandValues,
        //    scales: scales
      };
      return res;

    case OBJECTS_TYPE.RECEIVE_TREE:

    default:
      return state;
  }
};

export default CLASSIFIER_REDUCER;

function getAllBandValues(
  nodes,
  objectsDefList,
  classifFieldType,
  arrayClassifBy
) {
  const newBandValues = [];
  if (nodes) {
    const flatNodes = flattenNodes(nodes);
    for (let bandI = 0; bandI < 3; bandI++) {
      const nodes = flatNodes.filter((item) => item.depth === bandI + 1);
      if (nodes?.length > 0) {
        newBandValues[bandI] = getBandValues(
          objectsDefList,
          nodes,
          classifFieldType[bandI],
          arrayClassifBy[bandI]
        );
      } else {
        newBandValues[bandI] = [];
      }
    }
  }
  return newBandValues;
}

function flattenNodes(nodes, flatArray = []) {
  for (let nodeInd = 0; nodeInd < nodes.length; nodeInd++) {
    const node = nodes[nodeInd];
    flatArray.push(node);
    if (node.children) flattenNodes(node.children, flatArray);
  }
  return flatArray;
}

function getBandValues(objectsDefList, nodes, fldType, fldName) {
  let bandValues = [];
  if (fldType === 'list') {
    const objType = nodes[0].type;
    bandValues = getListValueForField(objectsDefList, objType, fldName);
  } else {
    bandValues = _extractFieldValues(nodes, fldName);
  }
  if (bandValues) {
    bandValues = _sortArrayByClassif(bandValues, fldType);
  }
  return bandValues;
}

const _sortArrayByClassif = (array, fldType) => {
  let sorted = array;
  if (fldType !== 'list') {
    sorted = array.sort(); // premier classement non numérique
  }
  if (fldType === 'number' || fldType === 'date' || fldType === 'formula') {
    sorted = sorted.sort((a, b) =>
      isNaN(a) || isNaN(b) ? 0 : parseInt(a) - parseInt(b)
    );
  }
  return sorted;
};

//---------------------------------------------------------------------------------------------------------
const _extractFieldValues = (nodes, fldName) => {
  const fieldValues = [];
  nodes.forEach((node) => {
    if (node[fldName]) fieldValues.push(node[fldName]);
  });
  return fieldValues;
};

function getTypeOfFieldByPanel(pattern, objectsDefList, arrayClassifBy) {
  // e.g. ['date','list','date']
  const newClassifFieldType = [];
  for (let bandI = 0; bandI < 3; bandI++) {
    const objectType = pattern.split('_')[bandI];
    const classifBy = arrayClassifBy[bandI];
    newClassifFieldType[bandI] = getTypeOfField(
      objectsDefList,
      objectType,
      classifBy
    );
  }
  return newClassifFieldType;
}
