import { Loader } from '@mantine/core';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { dotLoaderCss } from './DotLoaderCss';
import { isMobileSelector } from '../../reducers/responsiveReducer/responsiveSelector';

function DotLoader({ noSpacing, style }) {
  const isMobile = useSelector(isMobileSelector);
  return (
    <Loader
      style={{
        ...dotLoaderCss.container,
        margin: noSpacing ? 0 : 60,
        ...style,
      }}
      color="violet"
      size={isMobile ? 'lg' : 'xl'}
      variant="dots"
    />
  );
}

export default memo(DotLoader);
