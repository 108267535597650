import {
  Button,
  Group,
  InputWrapper,
  Loader,
  Select,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Photo, Upload, X } from 'tabler-icons-react';
import { XlSXIcon } from '../../constants/icon';
import { objectsDefListSelector } from '../../reducers/objectReducers/objectSelector';
import { WrapIconExcelModalImport } from './ModalImportExcelCSS';

function getIconColor(status, theme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]
    : status.rejected
    ? theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
    : theme.colorScheme === 'dark'
    ? theme.colors.dark[0]
    : theme.colors.gray[7];
}

function ImageUploadIcon({ status, ...props }) {
  if (status.accepted) {
    return <Upload {...props} />;
  }

  if (status.rejected) {
    return <X {...props} />;
  }

  return <Photo {...props} />;
}

export const dropzoneChildren = (status, theme, file) => {
  return (
    <Group
      position="center"
      spacing="xl"
      style={{ minHeight: 220, pointerEvents: 'none', height: '100%' }}
    >
      {file ? (
        <WrapIconExcelModalImport>
          <XlSXIcon />
        </WrapIconExcelModalImport>
      ) : (
        <ImageUploadIcon
          status={status}
          style={{ color: getIconColor(status, theme) }}
          size={80}
        />
      )}

      <div>
        <Text size="xl" inline>
          {file ? file?.name : 'Drag excel file here or click to select file'}
        </Text>
      </div>
    </Group>
  );
};
function UpFileTab({
  nextStep,
  valueSelect,
  setValueSelects,
  file,
  setFile,
  isLoading,
}) {
  const theme = useMantineTheme();

  const objFormData = useSelector(objectsDefListSelector);
  const isLoadingObjectsDefList = useSelector(
    (state) => state?.objects?.isLoadingObjectsDefList
  );

  const dataSelectDefList = objFormData?.reduce((accumulator, currentValue) => {
    accumulator.push({
      label: currentValue.label,
      value: currentValue.key,
    });
    return accumulator;
  }, []);
  const disableNextStep = isEmpty(file) || !valueSelect;
  return (
    <Stack style={{ height: '100%' }}>
      <InputWrapper
        required
        label="Select object"
        size="xs"
        styles={{
          root: {
            maxWidth: 300,
          },
        }}
      >
        <Select
          icon={isLoadingObjectsDefList ? <Loader size={16} /> : null}
          value={valueSelect}
          onChange={setValueSelects}
          searchable
          clearable
          placeholder="Select object perspective"
          data={dataSelectDefList}
        />
      </InputWrapper>

      <Dropzone
        styles={{
          root: {
            height: '100%',
          },
        }}
        onDrop={(files) => setFile(files[0])}
        accept={(MIME_TYPES.xls, MIME_TYPES.xlsx)}
        multiple={false}
      >
        {(status) => dropzoneChildren(status, theme, file)}
      </Dropzone>
      <Group position="right">
        <Button
          onClick={nextStep}
          disabled={disableNextStep || isLoading}
          loading={isLoading}
        >
          Next step
        </Button>
      </Group>
    </Stack>
  );
}

export default UpFileTab;
