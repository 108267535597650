import { throttle } from 'lodash';

var x = 0;
var y = 0;
var xStart = 0;
var yStart = 0;
export function makeDraggable(
  elmnt,
  onDragStart,
  onDragEnd,
  onDragMove,
  onOpenObjectDetails,
  timeDelay = 500
) {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;

  const onCallDragMove = throttle((positionElement) => {
    onDragMove(positionElement);
  }, timeDelay);

  if (elmnt !== undefined) {
    // if present, the header is where you move the DIV from:
    elmnt.onmousedown = dragMouseDown;
    elmnt.ontouchstart = dragMouseDown; // added touch event
  } else {
    // otherwise, move the DIV from anywhere inside the DIV:
    elmnt.onmousedown = dragMouseDown;
    elmnt.ontouchstart = dragMouseDown; // added touch event
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();

    // Get touch or click position
    // https://stackoverflow.com/a/41993300/5078983
    if (
      e.type == 'touchstart' ||
      e.type == 'touchmove' ||
      e.type == 'touchend' ||
      e.type == 'touchcancel'
    ) {
      let evt = typeof e.originalEvent === 'undefined' ? e : e.originalEvent;
      let touch = evt.touches[0] || evt.changedTouches[0];
      x = touch.pageX;
      y = touch.pageY;
    } else if (
      e.type == 'mousedown' ||
      e.type == 'mouseup' ||
      e.type == 'mousemove' ||
      e.type == 'mouseover' ||
      e.type == 'mouseout' ||
      e.type == 'mouseenter' ||
      e.type == 'mouseleave'
    ) {
      x = e.clientX;
      y = e.clientY;
    }

    console.log(`drag start x: ${x} y:${y}`);
    xStart = x;
    yStart = y;

    // get the mouse cursor position at startup:
    pos3 = x;
    pos4 = y;
    document.onmouseup = closeDragElement;
    document.ontouchend = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
    document.ontouchmove = elementDrag;
    if (typeof onDragStart === 'function') {
      onDragStart({ x, y });
    }
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();

    // Get touch or click position
    // https://stackoverflow.com/a/41993300/5078983
    if (
      e.type == 'touchstart' ||
      e.type == 'touchmove' ||
      e.type == 'touchend' ||
      e.type == 'touchcancel'
    ) {
      let evt = typeof e.originalEvent === 'undefined' ? e : e.originalEvent;
      let touch = evt.touches[0] || evt.changedTouches[0];
      x = touch.pageX;
      y = touch.pageY;
    } else if (
      e.type == 'mousedown' ||
      e.type == 'mouseup' ||
      e.type == 'mousemove' ||
      e.type == 'mouseover' ||
      e.type == 'mouseout' ||
      e.type == 'mouseenter' ||
      e.type == 'mouseleave'
    ) {
      x = e.clientX;
      y = e.clientY;
    }

    // calculate the new cursor position:
    pos1 = pos3 - x;
    pos2 = pos4 - y;
    pos3 = x;
    pos4 = y;
    // set the element's new position:
    elmnt.style.top = `${elmnt.offsetTop - pos2}px`;
    elmnt.style.left = `${elmnt.offsetLeft - pos1}px`;
    // console.log(`drag  x: ${pos1} y:${pos2}`);
    if (typeof onDragMove === 'function') {
      const positionElement = { x: pos3, y: pos4 };
      onCallDragMove(positionElement);
    }
  }

  function closeDragElement() {
    console.log(`drag end x: ${pos3} y:${pos4}`);
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.ontouchcancel = null; // added touch event
    document.ontouchend = null; // added touch event
    document.onmousemove = null;
    document.ontouchmove = null; // added touch event
    if (typeof onDragEnd === 'function') {
      const positionElement = { x: pos3, y: pos4 };

      onDragEnd(positionElement);
    }
    if (
      xStart === x &&
      yStart === y &&
      typeof onOpenObjectDetails === 'function'
    ) {
      onOpenObjectDetails();
      console.log('==============');
    }
  }
}
