import { MOBILE_WIDTH } from '../constants/devices';

const widthScreen =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const userAgent = navigator.userAgent.toLowerCase();
export const isTablet =
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  ) ||
  (window.matchMedia('(pointer: coarse)').matches &&
    widthScreen >= MOBILE_WIDTH);
