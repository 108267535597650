import { flextree } from 'd3-flextree';
import { getTypeOfField } from './Utils.js';
import {
  cleanupNodes,
  getFieldSize,
  removeCollapsed,
  sortArray,
} from './NodeUtils';

const initialState = {
  test: 'dede',
  nodesSize: [],
  node0: null,
};

const LAYOUT_REDUCER = (state = initialState, action, root) => {
  switch (action.type) {
    case 'XXX_OLD___RECEIVE_TREE':
      let nodes = [...action.objects];
      let nodesWithSize;
      let nodesWithPos = [];
      if (nodes.length > 0) {
        nodes = cleanupNodes(nodes);
        nodesWithSize = _calcNodesSize(
          nodes,
          root.objects.dataLines,
          root.objects.objectsDefList
        );
        nodes = removeCollapsed(nodes, root.objects.collapsedNodes);
        nodes = sortArray(nodes, 0, root.objects.arraySortBy);
        nodesWithPos = _calcNodesPosition(nodes, nodesWithSize);
      }
      return {
        ...state,
        nodesSize: nodesWithPos,
        node0: nodes[0]?.key ?? null,
      };
    case 'XXX_OLD_XXXXX_TOGGLED_ADDITIONAL_LINE':
      return {
        ...state,
        nodesSize: _calcNodesSize(
          cleanupNodes([...root.objects.nodes]),
          root.objects.dataLines,
          root.objects.objectsDefList
        ),
      };
    default:
      return state;
  }
};

export default LAYOUT_REDUCER;

//------------------------------------------------------------------------------------------------------------
const _setNodeSpacing = (a, b) => {
  return a.parent === b.parent
    ? LAYOUT_HSPACE_NODE_BROTHER
    : LAYOUT_HSPACE_NODE_NOT_BROTHER;
};
//------------------------------------------------------------------------------------------------------------
const _calcNodesPosition = (nodes, nodesSize) => {
  const nodesRes = {
    key: 'root',
    children: nodes,
    nbChild: nodes.length,
    nbRandom: 0,
  };
  const layout = flextree();

  layout.nodeSize(_getNodeSize.bind(this, nodesSize));
  layout.spacing(_setNodeSpacing);
  const treeroot = layout.hierarchy(nodesRes);
  layout(treeroot);
  treeroot.descendants().forEach((node) => {
    const { x } = node;
    const trgElem = nodesSize.find((elem) => elem.id === node.data.key);
    trgElem.x = x;
  });
  return nodesSize;
};
//------------------------------------------------------------------------------------------------------------
const _getNodeSize = (nodesSize, node) => {
  return [nodesSize.find((item) => item.id === node.data.key).size, 150];
};
//------------------------------------------------------------------------------------------------------------
const _calcNodesSize = (nodes, dataLines, objectsDefList) => {
  if (nodes?.length > 0) {
    const nodesRes = nodes.map((node) => {
      let nodeSize = 0;

      let displayedFields;
      try {
        displayedFields = dataLines[node.level].filter(
          (a) => node[a.data] != null && node.type === a.to
        );
      } catch (e) {}

      for (const displayedFieldInd in displayedFields) {
        const displayedField = displayedFields[displayedFieldInd];
        const objType = displayedField.to;
        const fldName = displayedField.data;
        const type = getTypeOfField(objectsDefList, objType, fldName);
        nodeSize += getFieldSize(type, node[fldName]);
      }
      nodeSize = 10 + (nodeSize + 1) * 30;

      return { id: node.key, size: nodeSize };
    });
    return [{ id: 'root', size: 0 }, ...nodesRes];
  }
  return null;
};

const LAYOUT_HSPACE_NODE_BROTHER = 3;
const LAYOUT_HSPACE_NODE_NOT_BROTHER = 40;
