import { getObjectTypeByLabel } from '../Utils/NodeUtils';

const getStepForArray = (arrayFields) => {
  let i = 0;
  while (i < arrayFields.length && arrayFields[i].value) i++;
  return i;
};
//------------------------------------------------------------------------------------------------------------------
export const getStep = (obj) => {
  const array = [
    { value: obj.typeSelectObject },
    { value: obj.idSelectedObject },
  ];
  return getStepForArray(array);
};

//------------------------------------------------------------------------------------------------------------------
export const getSuggestedType = (pattern, typeParent, objectsDefList) => {
  let suggestedType = getObjectTypeByLabel('perspective', objectsDefList); // by default
  const patternArray = pattern.split('_');
  if (patternArray[0] && typeParent && typeParent.startsWith(patternArray[0]))
    suggestedType = patternArray[1];
  if (patternArray[1] && typeParent && typeParent.startsWith(patternArray[1]))
    suggestedType = patternArray[2];
  return suggestedType;
};

//------------------------------------------------------------------------------------------------------------------
