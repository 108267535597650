import { THEME_TYPE } from './type';

export const setModeTheme = (themeMode) => {
  return (dispatch) => {
    dispatch({
      type: THEME_TYPE.SET_MODE_THEME,
      themeMode,
    });
  };
};

export const changePalette = (palette) => {
  return (dispatch) => {
    dispatch({
      type: THEME_TYPE.CHANGE_PALETTE,
      palette,
    });
  };
};

export const getLocalPalette = (indexPalette, themeMode, palette) => {
  return (dispatch) => {
    dispatch({
      type: THEME_TYPE.GET_LOCAL_PALETTE,
      indexPalette,
      themeMode,
      palette,
    });
  };
};
