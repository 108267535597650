import { Modal, useMantineTheme } from '@mantine/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { dataLibraryIcon } from '../../constants/fakeDataLibraryIcons';
import { sessionModalLibrarySelector } from '../../reducers/iconsLibraryReducer/iconsLibrarySelector';
import { isDarkModeSelector } from '../../reducers/themeReducers/themeSelector';
import { heightScreen } from '../../utils/windowSize';
import CategoriesLibraryIcons from './CategoriesLibraryIcons';
import ListIcons from './ListIcons';
import { ModalLibraryIconContainer } from './ModalLIbraryIconCss';

const numberItemPerPage = 42;
export default function ModalSelectIcon({
  isOpen,
  closeM0dal,
  handleSelectIcon,
}) {
  const theme = useMantineTheme();
  const sliceIcon = useRef(0);
  const refList = useRef();
  const { t } = useTranslation();

  const dataSessionLibraryIcons = useSelector(sessionModalLibrarySelector);

  const isDarkMode = useSelector(isDarkModeSelector);

  const [dataRenderIcon, setDataRenderIcon] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [currentSession, setCurrentSession] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(() => {
    setCurrentSession(dataSessionLibraryIcons[0].value);
  }, [dataSessionLibraryIcons]);

  const onChangeCategory = (category) => {
    if (category?.id === currentCategory?.id) return;
    sliceIcon.current = 0;
    setCurrentCategory(category);
    setHasMore(true);
  };

  const data = currentCategory?.icons;

  const fetchMoreData = useCallback(
    (isReset, _category) => {
      setTimeout(() => {
        const category = _category || currentCategory;

        if (!category) {
          setDataRenderIcon({});
          return;
        }

        const sliced = Object.keys(category?.icons)
          .slice(sliceIcon.current, sliceIcon.current + numberItemPerPage)
          .reduce((result, key) => {
            result[key] = category?.icons[key];
            return result;
          }, {});

        if (isReset) {
          setDataRenderIcon({
            ...sliced,
          });
          refList.current?.scrollIntoView({ behavior: 'smooth' });
        } else {
          setDataRenderIcon({
            ...dataRenderIcon,
            ...sliced,
          });
        }

        sliceIcon.current += numberItemPerPage;
        if (sliceIcon.current >= Object.keys(data).length) {
          setHasMore(false);
        }
      }, 350);
    },
    [dataRenderIcon, sliceIcon?.current, data]
  );

  useEffect(() => {
    fetchMoreData(true, currentCategory);
  }, [currentCategory]);

  const heightContainerIcons = (heightScreen / 100) * 70;

  return (
    <>
      <Modal
        zIndex={20000000090}
        title={t('assets_library')}
        size="auto"
        centered
        opened={isOpen}
        onClose={closeM0dal}
        overlayColor={isDarkMode ? theme.colors.gray[9] : theme.colors.gray[2]}
        overlayOpacity={0.55}
        overlayBlur={3}
        styles={{
          modal: {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.blue_gray[9]
                : theme.colors.blue_gray[0],
          },
          title: {
            fontWeight: 600,
          },
        }}
      >
        <ModalLibraryIconContainer height={`${heightContainerIcons}px`}>
          <CategoriesLibraryIcons
            currentSession={currentSession}
            setCurrentSession={setCurrentSession}
            currentCategory={currentCategory}
            onChangeCategory={onChangeCategory}
          />
          <ListIcons
            currentCategory={currentCategory}
            currentSession={currentSession}
            data={dataRenderIcon}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            refList={refList}
            handleSelectIcon={handleSelectIcon}
          />
        </ModalLibraryIconContainer>
      </Modal>
    </>
  );
}
