import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export default function GridsDrawing({ sizeGrid }) {
  const svgRef = useRef();

  useEffect(() => {
    const svgPad = document.getElementById('svgPad')?.getBoundingClientRect();

    const numRows = svgPad?.height / sizeGrid;
    const numCols = svgPad?.width / sizeGrid;
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();
    svg.attr('width', numCols * sizeGrid).attr('height', numRows * sizeGrid);

    // Create a group for the grid cells
    const gridGroup = svg.append('g');

    // Create grid rows
    const rows = gridGroup
      .selectAll('.row')
      .data(d3.range(numRows))
      .enter()
      .append('g')
      .attr('class', 'row data-html-to-image-ignore')
      .attr('transform', (d, i) => `translate(0, ${i * sizeGrid})`);
    // .attr('data-html2canvas-ignore', true);

    // Create grid columns within each row
    const cells = rows
      .selectAll('.cell')
      .data((d, i) => d3.range(numCols))
      .enter()
      .append('rect')
      .attr('class', 'cell data-html-to-image-ignore')
      .attr('x', (d, i) => i * sizeGrid)
      .attr('width', sizeGrid)
      .attr('height', sizeGrid)
      .style('stroke', '#f6f6f6')
      .style('fill', 'white');
    // .attr('data-html2canvas-ignore', true);
  }, [sizeGrid]);
  return <svg y="0" ref={svgRef}></svg>;
}
