import { Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { memo, useState } from 'react';
import { Pencil, TrashX } from 'tabler-icons-react';
import ModalUpdateResource from './ModalUpdateResource';
import { ipServer } from '../../api/ObjectApi';

function ActionCategory({
  onClickDeleteCategory,
  category,
  handleUpdateCategory,
}) {
  const [opened, handlers] = useDisclosure(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const onCloseModalEdit = () => {
    setShowModalEdit(false);
  };
  const getDataImageUploadAndUpdateCategory = ({
    url,
    newUrl,
    name,
    disableLoading,
  }) => {
    handleUpdateCategory({
      name,
      icon: newUrl || url,
      categoryUpdate: category,
      callBack: () => {
        setShowModalEdit(false);
        if (typeof disableLoading === 'function') disableLoading();
      },
    });
  };
  return (
    <>
      <Menu
        placement="center"
        withArrow
        zIndex={20000000091}
        opened={opened}
        onOpen={handlers.open}
        onClose={handlers.close}
        trigger="hover"
        position="right"
        delay={150}
        size="sm"
        shadow="lg"
      >
        <Menu.Item
          onClick={() => setShowModalEdit(true)}
          icon={<Pencil size={14} />}
        >
          Edit
        </Menu.Item>
        <Menu.Item onClick={onClickDeleteCategory} icon={<TrashX size={14} />}>
          Delete
        </Menu.Item>
      </Menu>
      <ModalUpdateResource
        opened={showModalEdit}
        defaultValue={{
          // url: 'https://devp3.io.land/guerillaPM3/DownloadFile/download/libasset_1701145671884/Screen%20Shot%202023-11-28%20at%2010.07.19.png',
          categoryName: category?.name || '',
          // url: `${ipServer + category?.icon}`,
          url: category?.icon,
        }}
        onCloseModal={onCloseModalEdit}
        getDataImageUploadAndUpdateCategory={
          getDataImageUploadAndUpdateCategory
        }
      />
    </>
  );
}

export default memo(ActionCategory);
