import produce from 'immer';
import { THEME_TYPE } from './type';

export const initialStateTheme = {
  themeMode: 'light',
  currentPalette: 'palette1',
  currentPaletteIndex: 1,
};

const getCurrentPaletteIndex = (pallet, isDark) => {
  switch (pallet) {
    case 'palette1': {
      if (isDark) return 0;
      return 1;
    }
    case 'palette2': {
      if (isDark) return 2;
      return 3;
    }
    case 'palette3': {
      if (isDark) return 4;
      return 5;
    }
    case 'palette4': {
      if (isDark) return 6;
      return 7;
    }
    default:
      return 0;
  }
};
const onSaveCurrentPaletteToLocal = (indexPalette, themeMode, palette) => {
  localStorage.setItem(
    'currentPalette',
    JSON.stringify({ indexPalette, themeMode, palette })
  );
};

const themeReducer = (state = initialStateTheme, action) => {
  switch (action.type) {
    case THEME_TYPE.SET_MODE_THEME: {
      const currentPaletteIndex = getCurrentPaletteIndex(
        state?.currentPalette,
        action.themeMode === 'dark'
      );
      onSaveCurrentPaletteToLocal(
        currentPaletteIndex,
        action?.themeMode,
        state.currentPalette
      );
      return produce(state, (draft) => {
        draft.themeMode = action?.themeMode;
        draft.currentPaletteIndex = currentPaletteIndex;
      });
    }
    case THEME_TYPE.CHANGE_PALETTE: {
      const currentPaletteIndex = getCurrentPaletteIndex(
        action?.palette,
        state.themeMode === 'dark'
      );
      onSaveCurrentPaletteToLocal(
        currentPaletteIndex,
        state.themeMode,
        action?.palette
      );
      return produce(state, (draft) => {
        draft.currentPalette = action?.palette;
        draft.currentPaletteIndex = currentPaletteIndex;
      });
    }
    case THEME_TYPE.GET_LOCAL_PALETTE: {
      return produce(state, (draft) => {
        draft.currentPaletteIndex = action?.indexPalette;
        draft.currentPalette = action?.palette;
        draft.themeMode = action?.themeMode;
      });
    }
    default:
      return state;
  }
};
export default themeReducer;
