import { Transition } from '@mantine/core';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ObjectActionCreators from '../../../actions';
import { AddPerspIcon, CloseIcon } from '../../../constants/icon';
import { setShowMenuMobile } from '../../../reducers/layoutReducer/action';
import { isShowMenuMobileSelector } from '../../../reducers/layoutReducer/layoutSelector';
import {
  isMobileSelector,
  isShowNodeContentSelector,
} from '../../../reducers/responsiveReducer/responsiveSelector';
import {
  currentPaletteActiveSelector,
  isDarkModeSelector,
} from '../../../reducers/themeReducers/themeSelector';
import { ToggleTheme } from '../../Button/ToggleTheme';
import {
  DivFloatingButton,
  WrapIconFloatingButton,
} from '../../Main/appStyles';
import ButtonOpenProfile from '../../user-management/ButtonOpenProfile';
import ProfileAuth0Content from '../../user-management/ProfileAuth0Content';
import {
  WrapCloseIconMenuMobile,
  WrapContentMenuMobile,
  WrapProfileAuth0Content,
} from './SwipeMenuCSS';

function MenuMobile() {
  const isMobile = useSelector(isMobileSelector);
  const isShowNodeContent = useSelector(isShowNodeContentSelector);
  const isShowMenuMobile = useSelector(isShowMenuMobileSelector);
  const dispatch = useDispatch();

  const isDarkMode = useSelector(isDarkModeSelector);
  const currentPaletteActive = useSelector(currentPaletteActiveSelector);

  const onCloseMenu = () => {
    dispatch(setShowMenuMobile(false));
  };
  const handleCreateNewPerspective = () => {
    onCloseMenu();
    dispatch(ObjectActionCreators.createNewPerspective());
  };

  return (
    <div>
      {isMobile && (
        <WrapProfileAuth0Content>
          <ProfileAuth0Content onCloseMenuMobile={onCloseMenu} />
        </WrapProfileAuth0Content>
      )}
      <Transition
        mounted={isMobile && isShowNodeContent && isShowMenuMobile}
        transition="slide-left"
        duration={250}
        timingFunction="ease"
      >
        {(styles) => (
          <WrapContentMenuMobile
            backgroundColor={currentPaletteActive?.body?.borderContent}
            style={{
              ...styles,
            }}
          >
            <WrapCloseIconMenuMobile onClick={onCloseMenu}>
              <CloseIcon fill={isDarkMode ? '#ffffff' : '#000000'} />
            </WrapCloseIconMenuMobile>
            <ButtonOpenProfile />
            <DivFloatingButton
              pal={currentPaletteActive}
              hoverBackgroundColor={
                currentPaletteActive?.floatingButton?.hoverColor
              }
              backgroundColor={
                currentPaletteActive?.floatingButton?.backgroundColor
              }
            >
              <ToggleTheme />
            </DivFloatingButton>
            <DivFloatingButton
              pal={currentPaletteActive}
              onClick={handleCreateNewPerspective}
              hoverBackgroundColor={
                currentPaletteActive?.floatingButton?.hoverColor
              }
              backgroundColor={
                currentPaletteActive?.floatingButton?.backgroundColor
              }
            >
              <WrapIconFloatingButton>
                <AddPerspIcon />
              </WrapIconFloatingButton>
            </DivFloatingButton>
          </WrapContentMenuMobile>
        )}
      </Transition>
    </div>
  );
}

export default memo(MenuMobile);
