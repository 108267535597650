import styled from 'styled-components';
import { buildCursorSvgPadWithAction } from '../../../../utils/buildCursorSvgPadWithAction';

export const ObjFieldDrawingPadContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

export const ObjFieldDrawingPadContent = styled.div({
  borderRadius: '7px',
  backgroundColor: 'white',
  margin: '10px',
  height: 130,
});

export const ObjFieldDrawingPadModalContent = styled.div({
  backgroundColor: '#FFF',
  color: 'black',
  width: 900,
  height: 640,
  display: 'flex',
  // width: '100%',
  position: 'relative',
});
export const ObjFieldDrawingPadModalPalette = styled.div({
  backgroundColor: (props) => props?.backgroundColor,
  height: '100%',
  paddingTop: '10px',
  width: '100px',
  position: 'absolute',
  right: 0,
});
export const ObjFieldDrawingPadModalTick = styled.div({
  backgroundColor: '#1C7ED6',
  borderRadius: '5px',
  color: 'white',
  cursor: 'pointer',
  // padding: '3',
  textAlign: 'center',
  width: 16,
  height: 16,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});
export const ObjFieldDrawingPadModalPlus = styled.div({
  backgroundColor: '#1C7ED6',
  borderRadius: '5px',
  color: 'white',
  cursor: 'pointer',
  // padding: '3',
  textAlign: 'center',
  width: 16,
  height: 16,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
});
export const ObjFieldDrawingPadModalList = styled.div({
  backgroundColor: '#FFF',
  border: '1px solid #CCC',
  borderRadius: '5px',
  width: '70px',
});
export const ObjFieldDrawingPadModalItem = styled.div({
  backgroundColor: (props) => props.backgroundColor,
  border: (props) => props.border,
  borderRadius: '50%',
  boxShadow: 'inset 3px 3px #44444433',
  cursor: 'pointer',
  display: 'inline-block',
  height: '25px',
  margin: '4px',
  width: '25px',
});

export const ObjFieldDrawingPadStyles = {
  svgTick: {
    backgroundColor: '',
    height: '30px',
    textAlign: 'center',
    width: '15px',
  },
};

export const ObjFieldDrawingPadSvgStyles = styled.svg({
  ':hover': {
    cursor: (props) =>
      buildCursorSvgPadWithAction(
        props.isDisableAction,
        props.action,
        props.link
      ),
  },
});

export const ObjFieldDrawingPadImageStyles = styled.image({
  ':hover': {
    cursor: (props) =>
      buildCursorSvgPadWithAction(
        props.isDisableAction,
        props.action,
        props.link
      ),
  },
});

export const ObjFieldDrawingSVGText = styled.span({
  ':hover': {
    cursor: (props) =>
      buildCursorSvgPadWithAction(
        props.isDisableAction,
        props.action,
        props.link
      ),
  },
  // whiteSpace: 'pre-line',
  fontSize: (props) => `${props.fontSize}px`,
  fontWeight: (props) => props.fontWeight,
  color: (props) => props.color,
  whiteSpace: 'pre-line',
  textAnchor: 'middle',
  alignmentBaseline: 'middle',
  margin: 0,
  display: 'inline-block',
  backgroundColor: (props) => props?.backgroundColor || '',
});

export const ObjFieldWrapListColor = styled.div({
  backgroundColor: '#FFF',
  border: '1px solid #CCC',
  borderRadius: '5px',
  width: '100%',
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '6px 0px',
  margin: '12px 0px',
  // marginBottom: 12,
});
export const ActionListDrawingContainer = styled.div({
  width: 'calc(100% - 100px)',
  height: 40,
  position: 'absolute',
  boxShadow: '0px 5px 10px 3px rgba(181,181,181,0.15)',
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  paddingLeft: 8,
  backgroundColor: (props) => props.backgroundColor,
  zIndex: 10000000000,
});

export const ActionListDrawingContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  columnGap: 8,
  alignItems: 'center',
  height: '100%',
});
