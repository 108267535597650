import { ActionIcon, useMantineTheme } from '@mantine/core';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isArray } from 'lodash';
import { SvgIcon } from '../../../../constants/icon';
import {
  changeModeLibrary,
  changeStatusShowModalIconsLibrary,
  setCallbackFuncIconsLibrary,
} from '../../../../reducers/iconsLibraryReducer/action';
import { MODE_LIBRARY_ICON } from '../../../../reducers/iconsLibraryReducer/type';
import { IMAGE_SVG_ICONS_TYPE } from '../../../../constants/libraryIcons';
import ModalSelectIcon from '../../../ModalSelectIcon';

export default function ModalSelectIconDrawing({
  onAddFile,
  isEditSvgLibIcon,
  onLoadSourceFromTemplateIcon,
}) {
  const [showModal, setShowModal] = useState(false);
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(changeStatusShowModalIconsLibrary(false));
  }, []);

  const closeModalSelectEditIcon = () => {
    setShowModal(false);
  };
  const handleOpenModal = useCallback(() => {
    if (isEditSvgLibIcon) {
      setShowModal(true);
      return;
    }

    dispatch(changeStatusShowModalIconsLibrary(true));
    dispatch(changeModeLibrary(MODE_LIBRARY_ICON.SELECT_ICON));
    dispatch(
      setCallbackFuncIconsLibrary((icon) => {
        if (isArray(icon?.lines) && icon?.lines?.length > 0) {
          onLoadSourceFromTemplateIcon(icon?.lines);
        } else {
          onAddFile(
            'image',
            icon?.icon,
            IMAGE_SVG_ICONS_TYPE.ACCESS_FROM_ICON_LIB,
            icon?.currentSession === 'svg' ? { width: 900, height: 640 } : null
          );
        }

        handleCloseModal();
        dispatch(changeModeLibrary(MODE_LIBRARY_ICON.NONE));
        dispatch(setCallbackFuncIconsLibrary(null));
      })
    );

    // setShowModal(true);
  }, []);

  const handleSelectIcon = (icon) => {
    onAddFile('image', icon?.icon, IMAGE_SVG_ICONS_TYPE.ACCESS_FROM_ICON_LIB);
    closeModalSelectEditIcon();
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 12,
        }}
      >
        <ActionIcon
          styles={{
            root: {
              width: 70,
              height: 70,
              border: '1px dashed #CBD5E1',
              ':hover': {
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.blue_gray[8]
                    : theme.colors.blue_gray[2],
              },
            },
          }}
          onClick={handleOpenModal}
          variant="outline"
        >
          <SvgIcon />
        </ActionIcon>
      </div>
      <ModalSelectIcon
        isOpen={showModal}
        closeM0dal={closeModalSelectEditIcon}
        handleSelectIcon={handleSelectIcon}
      />
    </div>
  );
}
