import { Group, Notification as NotificationManTine } from '@mantine/core';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { notificationsSelector } from '../../reducers/notificationReducer/notificationSelector';

function Notification() {
  const notifications = useSelector(notificationsSelector);
  return (
    <Group direction="column" spacing="sm">
      {notifications?.map((notification) => (
        <NotificationItem key={notification?.id} notification={notification} />
      ))}
    </Group>
  );
}

export default memo(Notification);

const NotificationItem = memo(({ notification }) => {
  const { isLoading, title, disallowClose, description, id } = notification;
  return (
    <NotificationManTine
      key={id}
      loading={isLoading}
      title={title}
      disallowClose={disallowClose}
      color="indigo"
      styles={{ body: { minWidth: 250 } }}
    >
      {description}
    </NotificationManTine>
  );
});
