// 'use strict';
import { cloneDeep } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ActionIcon } from '@mantine/core';
import { range } from 'd3';
import { Download, Edit, Eye } from 'tabler-icons-react';
import produce from 'immer';
import ObjectActionCreators from '../../../../actions';

import ObjectApi from '../../../../api/ObjectApi';
import { colorsSvgDrawing } from '../../../../constants/colors';
import { IMAGE_SVG_ICONS_TYPE } from '../../../../constants/libraryIcons';
import {
  onTogglePreviewSvgSketch,
  setPreviewSvgSketchData,
} from '../../../../reducers/layoutReducer/action';
import {
  changeIndexElement,
  generateNewPointsItemDuplicate,
} from '../../../../utils/functionSvgDrawing';
import { ObjFieldDrawingPadContainer } from './ObjFieldDrawingPadCSS';
import SvgDrawing from './SvgDrawing';
import SvgPreviewContent from './SvgPreviewContent';
import SvgPreviewDownload from './SvgPreviewDownload';
import { parseJson } from '../../../../utils/parseJson';
// lineDrawing ='polyline'||'line'
// action = 'delete' ||'ZoomIn'|'ZoomOut'|'move'|'mouse'|'drawing-polyline'|'drawing-line'|'drawing-arrow-line'

class ObjFieldDrawingPad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: 'mouse',
      editMode: false,
      color: colorsSvgDrawing[0],
      opacity: 1,
      drawing: false,
      tick: 6,
      lines: [],
      points: [],
      svgFile: [],
      keySvgSelect: null,
      lineDrawing: 'line',
      idLineDrawing: null,
      isShowGrids: false,
      sizeGrid: 50,
      // previewMode: false,
      exportMode: false,
    };

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleCleanAll = this.handleCleanAll.bind(this);
    // this.handleTickLower = this.handleTickLower.bind(this);
    // this.handleTickUp = this.handleTickUp.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.handleClickSVG = this.handleClickSVG.bind(this);
    this.onAddFile = this.onAddFile.bind(this);
    this.setActionDragSvg = this.setActionDragSvg.bind(this);
    this.setSvgFocus = this.setSvgFocus.bind(this);
    this.onDeleteSvg = this.onDeleteSvg.bind(this);
    this.handleSaveSvgFileToDb = this.handleSaveSvgFileToDb.bind(this);
    this.onResizeSvg = this.onResizeSvg.bind(this);
    this.onAddTextSvg = this.onAddTextSvg.bind(this);
    this.getTopZIndex = this.getTopZIndex.bind(this);
    this.onChangeLineDrawing = this.onChangeLineDrawing.bind(this);
    this.onChangeAction = this.onChangeAction.bind(this);
    this.onChangeTick = this.onChangeTick.bind(this);
    this.onDeleteSvgWithId = this.onDeleteSvgWithId.bind(this);
    this.setShowGrids = this.setShowGrids.bind(this);
    this.onUpdateSVG = this.onUpdateSVG.bind(this);
    this.onUpdatePointsOnMove = this.onUpdatePointsOnMove.bind(this);
    this.onGeneratePoly = this.onGeneratePoly.bind(this);
    this.onUpdateTextSvg = this.onUpdateTextSvg.bind(this);
    this.onDuplicateItem = this.onDuplicateItem.bind(this);
    this.onChangeLayout = this.onChangeLayout.bind(this);
    this.onLink = this.onLink.bind(this);
    this.onUpdateItemPolylineOrArrowLine =
      this.onUpdateItemPolylineOrArrowLine.bind(this);
    this.togglePreviewMode = this.togglePreviewMode.bind(this);
    this.toggleExportMode = this.toggleExportMode.bind(this);
    this.onLoadSourceFromTemplateIcon =
      this.onLoadSourceFromTemplateIcon.bind(this);
    // this.removeSvgSinglePoints = this.removeSvgSinglePoints.bind(this);
    // this.handleEnterKeyPress = this.handleEnterKeyPress.bind(this);
  }

  onLoadSourceFromTemplateIcon(dataTemp) {
    let topZIndex = 0;
    this.state.lines?.map((line) => {
      if (line?.zIndex > topZIndex) {
        topZIndex = line?.zIndex;
      }
    });
    topZIndex + 1;
    const newLines = [];
    this.state.lines?.map((line) => {
      newLines.push({
        ...line,
        // zIndex: line?.zIndex + topZIndex,
      });
    });
    const newLinesTemp = [];

    dataTemp.map((item) => {
      newLinesTemp.push({
        ...item,
        key: `clone_temp_${uuidv4()}`,
        isCloneTemp: true,
        zIndex: item?.zIndex + topZIndex,
      });
    });
    const allLineData = [...newLines, ...newLinesTemp]?.sort(function (a, b) {
      return a?.zIndex - b?.zIndex;
    });
    this.setState({ lines: allLineData });
    this.onUpdateSVG(allLineData);
  }

  onUpdatePointsOnMove(id, newPoints) {
    const newLines = cloneDeep(this.state.lines);
    newLines.map((line) => {
      if (line.key === id) {
        line.points = newPoints;
      }
      return line;
    });
    this.setState({ lines: newLines });
    this.onUpdateSVG(newLines);
  }

  onChangeLayout(type) {
    const topZIndex = this.getTopZIndex();
    const newLines = changeIndexElement(
      type,
      this.state.lines,
      this.state.keySvgSelect,
      topZIndex
    );
    this.setState({ lines: newLines });
    this.onUpdateSVG(newLines);
  }

  onUpdateItemPolylineOrArrowLine(itemUpdate) {
    const newLinesClone = cloneDeep(this.state.lines);
    const newLines = newLinesClone.map((line) => {
      if (line.key === itemUpdate.key) {
        return itemUpdate;
      }
      return line;
    });
    this.setState({ lines: newLines });
    this.onUpdateSVG(newLines);
  }

  onDuplicateItem() {
    const newLines = cloneDeep(this.state.lines);
    const itemDuplicate = newLines.find(
      (line) => line.key === this.state.keySvgSelect
    );
    const type = itemDuplicate.type;
    switch (type) {
      case 'svg':
        return this.onAddFile('svg', itemDuplicate.points);

      case 'image': {
        const keyImage = `illustration_file${uuidv4()}`;
        return this.onAddFile(
          'image',
          itemDuplicate.points,
          itemDuplicate?.keyImage === IMAGE_SVG_ICONS_TYPE.ACCESS_FROM_ICON_LIB
            ? itemDuplicate?.keyImage
            : keyImage
        );
      }
      case 'text':
        return this.onAddTextSvg({
          ...itemDuplicate,
          text: itemDuplicate.points,
        });
      case 'line':
      case 'arrow-line':
      case 'bidirectional-line':
      case 'polyline': {
        const key = `${type}_${uuidv4()}`;
        const svgBoundingPad = document
          .getElementById('svgPad')
          .getBoundingClientRect();

        const svgItemBounding = document
          .getElementById(itemDuplicate.key)
          ?.getBoundingClientRect();

        this.setState(
          {
            lines: [
              ...this.state.lines,
              {
                ...itemDuplicate,
                key,
                type,
                points: generateNewPointsItemDuplicate(
                  itemDuplicate.points,
                  svgBoundingPad,
                  svgItemBounding
                ),
                tick: itemDuplicate.tick,
                color: itemDuplicate.color,
                zIndex: this.getTopZIndex(),
              },
            ],
          },
          () => this.onUpdateSVG()
        );
      }
      default:
        break;
    }
  }

  setShowGrids(status, sizeGrid = 50) {
    this.setState({ isShowGrids: status, sizeGrid });
  }

  onChangeTick(tick) {
    let currentElementSelect = this.state.lines.find(
      (svg) => svg.key === this.state.keySvgSelect
    );
    let newData = [];
    if (this.state.keySvgSelect) {
      newData = produce(this.state.lines, (draffState) => {
        draffState.map((line) => {
          if (line?.key === this.state.keySvgSelect) {
            line.tick = tick;
          }
        });
      });
    }
    this.setState(
      {
        tick,
        idLineDrawing: null,
        ...(this.state.keySvgSelect ? { lines: newData } : {}),
      },
      () => {
        if (
          ['polyline', 'line', 'bidirectional-line', 'arrow-line'].includes(
            currentElementSelect?.type
          )
        ) {
          this.onUpdateSVG();
        }
      }
    );
  }

  onChangeAction(action, resetSvgSelect = true) {
    if (resetSvgSelect) {
      const newLineData = this.removeSvgSinglePoints();
      return this.setState(
        {
          action,
          idLineDrawing: null,
          keySvgSelect: null,
          lines: newLineData,
        },
        () => {
          if (this.state.lines.length !== newLineData.length) {
            this.onUpdateSVG();
          }
        }
      );
    }

    this.setState({
      action,
    });
  }

  onChangeLineDrawing(lineDrawing) {
    this.setState({
      lineDrawing,
      drawing: false,
      idLineDrawing: null,
      keySvgSelect: null,
    });
  }

  getTopZIndex() {
    let topZIndex = 0;
    this.state.lines?.map((line) => {
      if (line?.zIndex > topZIndex) {
        topZIndex = line?.zIndex;
      }
    });
    return topZIndex + 1;
  }

  onUpdateSVG(newData) {
    this.props.handleChangeInputDrawing(
      null,
      JSON.stringify(newData || this.state.lines),
      this.props.label
    );
  }

  onAddFile(type, file, keyImage, size) {
    // type  = 'svg' or'image'

    const svgBox = document.getElementById('svgPad')?.getBoundingClientRect();
    this.setState(
      {
        lines: [
          ...this.state.lines,
          {
            key: `${type}_${uuidv4()}`,
            type,
            points: file,
            x: size ? 0 : svgBox?.width / 2 - 50,
            y: size ? 0 : svgBox?.height / 2 - 100,
            width: size ? size?.width : 100,
            height: size ? size?.height : 100,
            zIndex: this.getTopZIndex(),
            ...(type === 'image' ? { keyImage } : {}),
          },
        ],
      },
      () => this.onUpdateSVG()
    );
  }

  onUpdateTextSvg({ text, color, fontWeight, fontSize, backgroundColor }) {
    const newLine = cloneDeep(this.state.lines);
    const currentElementSelect = newLine.map((svg) => {
      if (svg.key === this.state.keySvgSelect) {
        svg.points = text;
        svg.fontWeight = fontWeight;
        svg.color = color;
        svg.fontSize = fontSize;
        svg.backgroundColor = backgroundColor;
      }
      return svg;
    });
    this.setState(
      {
        lines: currentElementSelect,
      },
      () => this.onUpdateSVG()
    );
  }

  onAddTextSvg(
    { text, color, fontWeight, fontSize, backgroundColor },
    isEditText
  ) {
    const svgBox = document.getElementById('svgPad')?.getBoundingClientRect();
    if (isEditText) {
      return this.onUpdateTextSvg({
        text,
        color,
        fontWeight,
        fontSize,
        backgroundColor,
      });
    }
    this.setState(
      {
        lines: [
          ...this.state.lines,
          {
            key: `text_${uuidv4()}`,
            type: 'text',
            points: text,
            x: svgBox?.width / 2 - 50,
            y: svgBox?.height / 2 - 50,
            fontWeight,
            color,
            fontSize,
            width: 500,
            zIndex: this.getTopZIndex(),
            backgroundColor,
          },
        ],
      },
      () => this.onUpdateSVG()
    );
  }

  onLink() {
    let currentElementSelect = this.state.lines.find(
      (svg) => svg.key === this.state.keySvgSelect
    );

    this.props.setDrawingItemSelected(
      this.props.label,
      this.state.keySvgSelect,
      currentElementSelect.type
    );
    this.props.openObjectSelector('link');
  }

  onResizeSvg(action) {
    const newLines = [...this.state.lines];
    newLines.map((svg) => {
      if (svg?.key === this.state.keySvgSelect) {
        // svg.zIndex = this.getTopZIndex();
        if (action === 'ZoomIn') {
          if (svg?.type === 'text') {
            if (svg.fontSize >= 82) return;
            svg.fontSize += 2;
            return;
          }
          svg.width += 14;
          svg.height += 14;
        } else {
          if (svg?.type === 'text') {
            if (svg.fontSize <= 16) return;
            svg.fontSize -= 2;
            return;
          }
          svg.width -= 14;
          svg.height -= 14;
        }
      }
    });
    this.setState({ lines: newLines });
  }

  setSvgFocus(keySvgSelect) {
    this.setState({ keySvgSelect });
  }

  onDeleteSvg() {
    let itemDelete = null;

    const newSvgFiles = this.state.lines.filter((access) => {
      if (access?.key !== this.state.keySvgSelect) return access;
      itemDelete = access;
    });
    if (
      itemDelete?.keyImage !== IMAGE_SVG_ICONS_TYPE.ACCESS_FROM_ICON_LIB &&
      !itemDelete?.isCloneTemp
    ) {
      ObjectApi.postFile(this.props?.objId, null, null, itemDelete?.keyImage);
    }
    this.setState({ lines: newSvgFiles, keySvgSelect: null });

    this.onUpdateSVG(newSvgFiles);
  }

  handleTouchMove(e) {
    e.preventDefault();
    if (this.state.action !== 'drawing-line') return;
    if (!this.state.drawing || this.state.lineDrawing !== 'line') return;
    let x;
    let y;
    const svgPad = document.getElementById('svgPad');
    const xoff = svgPad.getBoundingClientRect().left;
    const yoff = svgPad.getBoundingClientRect().top;
    if (e.touches) {
      x = e.touches[0].clientX - xoff;
      y = e.touches[0].clientY - yoff;
    } else {
      x = e.clientX - xoff;
      y = e.clientY - yoff;
    }

    this.setState({ points: [...this.state.points, { x, y }], opacity: 1 });
  }

  toggleEditMode(e) {
    if (typeof this.props.onToggleEditMode === 'function') {
      return this.props.onToggleEditMode();
    }
    this.setState({ editMode: !this.state.editMode, keySvgSelect: null });
  }

  togglePreviewMode(e) {
    this.props.showPreviewSvgSketch();
    this.props.setPreviewSvgSketchData(this.state.lines);
  }

  async toggleExportMode(e) {
    this.setState({ exportMode: !this.state.exportMode, keySvgSelect: null });
  }

  toggleDelete(e) {
    this.setState({
      action: 'delete',
      drawing: false,
      keySvgSelect: null,
      idLineDrawing: null,
    });
  }

  handleClickSVG(e) {
    const idTarget = e.target.id;
    if (this.state.action !== 'delete') {
      return this.setSvgFocus(idTarget);
    }

    if (this.state.action === 'delete') {
      this.onDeleteSvgWithId(idTarget);
    }
  }

  onDeleteSvgWithId(idTarget) {
    let itemDelete = null;
    this.setState({
      lines: this.state.lines.filter((e) => {
        if (e.key != idTarget) return e;
        itemDelete = e;
      }),
    });
    if (
      itemDelete?.keyImage !== IMAGE_SVG_ICONS_TYPE.ACCESS_FROM_ICON_LIB &&
      !itemDelete?.isCloneTemp
    ) {
      ObjectApi.postFile(this.props?.objId, null, null, itemDelete?.keyImage);
    }
    this.setState(
      {
        lines: this.state.lines.filter((e) => e.key != idTarget),
        keySvgSelect: null,
        idLineDrawing: null,
      },
      () => this.onUpdateSVG()
    );
  }

  setActionDragSvg(action) {
    // if (this.state.action === 'delete') return;
    this.setState({ action });
  }

  handleTouchEnd(e) {
    const id = `id_${uuidv4()}`;

    if (
      ['arrow-line', 'bidirectional-line'].includes(this.state.lineDrawing) &&
      this.state.drawing &&
      ['drawing-arrow-line', 'drawing-bidirectional-line'].includes(
        this.state.action
      )
    ) {
      let x;
      let y;
      const svgPad = document.getElementById('svgPad');
      const xoff = svgPad.getBoundingClientRect().left;
      const yoff = svgPad.getBoundingClientRect().top;
      if (e.touches) {
        x = e.touches[0].clientX - xoff;
        y = e.touches[0].clientY - yoff;
      } else {
        x = e.clientX - xoff;
        y = e.clientY - yoff;
      }
      const idLineDrawing = `arrow-line_${uuidv4()}`;
      if (this.state.idLineDrawing) {
        const cloneDeepLines = cloneDeep(this.state.lines);
        const currentPolylineEdit = cloneDeepLines?.map((svg) => {
          if (svg.key === this.state.idLineDrawing) {
            svg.points.push({
              ...this.calculatePositionPolylineDrawing({
                x,
                y,
              }),
              id,
            });
            if (svg.points?.length === 2) {
              this.setState({ idLineDrawing: null });
            }
          }
          return svg;
        });
        this.setState({
          lines: currentPolylineEdit,
        });

        this.onUpdateSVG(currentPolylineEdit);
        return;
      }
      this.setState(
        {
          lines: [
            ...this.state.lines,
            {
              key: idLineDrawing,
              type: this.state.lineDrawing,
              points: [
                {
                  ...this.calculatePositionPolylineDrawing({
                    x,
                    y,
                  }),
                  id,
                },
              ],
              tick: this.state.tick,
              color: this.state.color,
              zIndex: this.getTopZIndex(),
            },
          ],
          idLineDrawing,
        },
        () => this.onUpdateSVG()
      );
      return;
    }
    if (
      this.state.lineDrawing === 'polyline' &&
      this.state.drawing &&
      this.state.action === 'drawing-polyline'
    ) {
      let x;
      let y;
      const svgPad = document.getElementById('svgPad');
      const xoff = svgPad.getBoundingClientRect().left;
      const yoff = svgPad.getBoundingClientRect().top;
      if (e.touches) {
        x = e.touches[0].clientX - xoff;
        y = e.touches[0].clientY - yoff;
      } else {
        x = e.clientX - xoff;
        y = e.clientY - yoff;
      }
      const idLineDrawing = `polyline_${uuidv4()}`;
      if (this.state.idLineDrawing) {
        const cloneDeepLines = cloneDeep(this.state.lines);

        const currentPolylineEdit = cloneDeepLines?.map((svg) => {
          if (svg.key === this.state.idLineDrawing) {
            this.calculatePositionPolylineDrawing({
              x,
              y,
            });

            svg.points.push({
              ...this.calculatePositionPolylineDrawing({
                x,
                y,
              }),
              id,
            });
          }
          return svg;
        });
        this.setState({
          lines: currentPolylineEdit,
        });
        this.props.handleChangeInputDrawing(
          null,
          JSON.stringify(currentPolylineEdit),
          this.props.label
        );
        return;
      }
      this.setState(
        {
          lines: [
            ...this.state.lines,
            {
              key: idLineDrawing,
              type: 'polyline',
              points: [
                {
                  ...this.calculatePositionPolylineDrawing({
                    x,
                    y,
                  }),
                  id,
                },
              ],
              tick: this.state.tick,
              color: this.state.color,
              zIndex: this.getTopZIndex(),
            },
          ],
          idLineDrawing,
        },
        () => this.onUpdateSVG()
      );
      return;
    }
    if (this.state.action === 'drawing-line') {
      this.setState(
        {
          drawing: false,
          lines: [
            ...this.state.lines,
            {
              key: `line_${uuidv4()}`,
              opacity: this.state.opacity,
              color: this.state.color,
              tick: this.state.tick,
              points: this.state.points,
              type: 'line',
              // zIndex: this.getTopZIndex(),
            },
          ],
          points: [],
        },
        () => this.onUpdateSVG()
      );
    }
  }

  handleSaveSvgFileToDb(keySvg, position) {
    const newLines = cloneDeep(this.state.lines);
    newLines.map((svg) => {
      if (svg?.key === keySvg) {
        svg.x = position.x;
        svg.y = position.y;
        // svg.zIndex = this.getTopZIndex();
      }
    });

    this.setState({ lines: newLines });
    this.onUpdateSVG(newLines);
  }

  handleTouchStart(e) {
    if (
      this.state.action !== 'delete' ||
      this.state.action === 'drawing-line' ||
      this.state.action === 'drawing-polyline'
    ) {
      this.setState({
        drawing: true,
        polyKey: uuidv4(),
      });
      this.handleTouchMove(e);
    }
  }

  handleCleanAll(e) {
    const listIdImageDelete = this.state.lines.filter(
      (line) => line?.type === 'image'
    );
    const listPromiseDeleteImage = [];
    listIdImageDelete.map((image) => {
      console.log('image', image);
      if (
        image?.keyImage !== IMAGE_SVG_ICONS_TYPE.ACCESS_FROM_ICON_LIB &&
        !image?.isCloneTemp
      ) {
        listPromiseDeleteImage.push(
          ObjectApi.postFile(this.props?.objId, null, null, image?.keyImage)
        );
      }
    });

    this.setState({
      lines: [],
      points: [],
      idLineDrawing: null,
      keySvgSelect: null,
      action: 'mouse',
    });
    this.props.handleChangeInputDrawing(
      null,
      JSON.stringify([]),
      this.props.label
    );
    try {
      Promise.all(listPromiseDeleteImage);
    } catch (error) {}
  }

  handleUndo(e) {
    const newTab = [...this.state.lines];
    newTab.pop();
    this.setState({
      lines: newTab,
      idLineDrawing: null,
      keySvgSelect: null,
      action: 'mouse',
    });
  }

  handleColorChange(e) {
    const indColor = e.target.id.split('index_color_')[1];
    let newData = [];
    const itemSelected = this.state.lines.find(
      (line) => line.key === this.state.keySvgSelect
    );
    console.log('itemSelected', itemSelected);
    if (
      itemSelected &&
      ['bidirectional-line', 'arrow-line', 'text', 'polyline', 'line'].includes(
        itemSelected?.type
      )
    ) {
      newData = produce(this.state.lines, (draffState) => {
        draffState.map((line) => {
          if (line?.key === this.state.keySvgSelect) {
            line.color = colorsSvgDrawing[indColor];
          }
        });
      });
    }
    this.setState(
      {
        color: colorsSvgDrawing[indColor],
        drawing: false,
        idLineDrawing: null,
        ...(this.state.keySvgSelect ? { lines: newData } : {}),
      },
      () => {
        if (itemSelected) {
          this.onUpdateSVG();
        }
      }
    );
  }

  generatePoly(points = [], tick, color, opacity, key) {
    let resStr = '';
    points.map((poi) => {
      resStr += `${poi.x},${poi.y} `;
    });
    return (
      <polyline
        onClick={this.handleClickSVG}
        id={key}
        key={key}
        points={resStr}
        strokeWidth={tick}
        stroke={color}
        fill="none"
      />
    );
  }

  onGeneratePoly() {
    return this.generatePoly(
      this.state.points,
      this.state.tick,
      this.state.color,
      this.state.opacity,
      this.state.polyKey
    );
  }

  calculatePositionPolylineDrawing(position) {
    if (!this.state.isShowGrids) return position;
    const { sizeGrid } = this.state;
    const svgPad = document.getElementById('svgPad')?.getBoundingClientRect();

    const numRows = svgPad?.height / sizeGrid + 1;
    const numCols = svgPad?.width / sizeGrid + 1;

    const arrWidth = range(numCols).map((i) => i * sizeGrid);
    const arrHeight = range(numRows).map((i) => i * sizeGrid);

    let y = this.state.sizeGrid;
    let x = this.state.sizeGrid;

    const indexPos = { x: 0, y: 0 };
    arrWidth.map((posX, index) => {
      if (Math.abs(posX - position?.x) < x) {
        indexPos.x = posX;
        x = Math.abs(posX - position?.x);
      }
      if (Math.abs(arrWidth[index + 1] - position?.x) < x) {
        indexPos.x = arrWidth[index + 1];
        x = Math.abs(arrWidth[index + 1] - position?.x);
      }
    });
    arrHeight.map((posY, index) => {
      if (Math.abs(posY - position?.y) < y) {
        indexPos.y = posY;
        y = Math.abs(posY - position?.y);
      }
      if (Math.abs(arrHeight[index + 1] - position?.y) < y) {
        indexPos.y = arrHeight[index + 1];
        y = Math.abs(arrHeight[index + 1] - position?.y);
      }
    });
    return indexPos;
  }

  render() {
    const {
      editMode,
      lineDrawing,
      action,
      keySvgSelect,
      lines,
      tick,
      isShowGrids,
      sizeGrid,
      idLineDrawing,
      color,
    } = this.state;
    this.calculatePositionPolylineDrawing();

    return (
      <ObjFieldDrawingPadContainer>
        {lines?.length > 0 && !this.props.isEditSvgLibIcon && (
          <SvgPreviewContent
            onClickElement={this.props.onClickElement}
            lines={lines}
          />
        )}

        {!this.props.readOnly && (
          <>
            {!this.props.isEditSvgLibIcon && (
              <div
                style={{ display: 'flex', flexDirection: 'row', columnGap: 10 }}
              >
                <ActionIcon id="btn_edit_drawing" onClick={this.toggleEditMode}>
                  <Edit />
                </ActionIcon>
                {this.state.lines.length > 0 && (
                  <>
                    <ActionIcon onClick={this.togglePreviewMode}>
                      <Eye />
                    </ActionIcon>
                    <ActionIcon onClick={this.toggleExportMode}>
                      <Download />
                    </ActionIcon>
                  </>
                )}
              </div>
            )}
            <SvgPreviewDownload
              lines={this.state.lines}
              exportMode={this.state.exportMode}
              togglePreviewMode={this.togglePreviewMode}
              toggleExportMode={this.toggleExportMode}
              onClickElement={this.props.onClickElement}
            />

            <SvgDrawing
              onLoadSourceFromTemplateIcon={this.onLoadSourceFromTemplateIcon}
              exportMode={this.state.exportMode}
              onUpdateSVG={this.onUpdateSVG}
              editMode={this.props?.editMode || editMode}
              onLink={this.onLink}
              toggleEditMode={this.toggleEditMode}
              onAddTextSvg={this.onAddTextSvg}
              lineDrawing={lineDrawing}
              onChangeAction={this.onChangeAction}
              onChangeLineDrawing={this.onChangeLineDrawing}
              action={action}
              keySvgSelect={keySvgSelect}
              lines={lines}
              onResizeSvg={this.onResizeSvg}
              onDeleteSvg={this.onDeleteSvg}
              onChangeTick={this.onChangeTick}
              tick={tick}
              setShowGrids={this.setShowGrids}
              isShowGrids={isShowGrids}
              sizeGrid={sizeGrid}
              handleTouchStart={this.handleTouchStart}
              handleTouchMove={this.handleTouchMove}
              handleTouchEnd={this.handleTouchEnd}
              setActionDragSvg={this.setActionDragSvg}
              setSvgFocus={this.setSvgFocus}
              handleSaveSvgFileToDb={this.handleSaveSvgFileToDb}
              onDeleteSvgWithId={this.onDeleteSvgWithId}
              idLineDrawing={idLineDrawing}
              onUpdatePointsOnMove={this.onUpdatePointsOnMove}
              handleCleanAll={this.handleCleanAll}
              onGeneratePoly={this.onGeneratePoly}
              handleUndo={this.handleUndo}
              toggleDelete={this.toggleDelete}
              handleColorChange={this.handleColorChange}
              color={color}
              onAddFile={this.onAddFile}
              onDuplicateItem={this.onDuplicateItem}
              onChangeLayout={this.onChangeLayout}
              onUpdateItemPolylineOrArrowLine={
                this.onUpdateItemPolylineOrArrowLine
              }
              isEditSvgLibIcon={this.props.isEditSvgLibIcon}
              updateIconSvgDrawing={this.props.updateIconSvgDrawing}
            />
          </>
        )}
      </ObjFieldDrawingPadContainer>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      try {
        this.setState({
          lines: this.sortLine(this.props.value),
        });
      } catch (error) {
        console.log(
          '🚀 ~ file: ObjFieldDrawingPad.js:596 ~ ObjFieldDrawingPad ~ componentDidUpdate ~ error:',
          error
        );
      }
    }
  }

  removeSvgSinglePoints() {
    const newLines = produce(this.state.lines, (draffState) => {
      return draffState.filter((svg) => {
        const isLines = [
          'line',
          'polyline',
          'arrow-line',
          'bidirectional-line',
        ].includes(svg?.type);
        if (isLines) {
          if (svg?.points.length > 1) return svg;
        }
        if (!isLines) return svg;
      });
    });

    return newLines;
  }

  handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.setState({ idLineDrawing: null });
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleEnterKeyPress);
    if (
      this.props.value !== null &&
      this.props.value !== undefined &&
      this.props.value !== 'null' &&
      this.props.value.length > 0
    ) {
      try {
        this.setState({
          lines: this.sortLine(this.props.value),
        });
      } catch (error) {
        console.log(
          '🚀 ~ file: ObjFieldDrawingPad.js:616 ~ ObjFieldDrawingPad ~ componentDidMount ~ error:',
          error
        );
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEnterKeyPress);
  }

  sortLine(valueLine) {
    try {
      const lines = valueLine ? parseJson(valueLine) : '';
      lines?.sort(function (a, b) {
        return a?.zIndex - b?.zIndex;
      });
      console.log('lines', lines);
      return lines;
    } catch (error) {
      console.log(
        '🚀 ~ file: ObjFieldDrawingPad.js:633 ~ ObjFieldDrawingPad ~ sortLine ~ error:',
        error
      );
      return [];
    }
  }
}

// export default withTranslation()(ObjFieldDrawingPad);
const mapDispatchToProps = (dispatch) => {
  return {
    openObjectSelector: (action) =>
      dispatch(ObjectActionCreators.openObjectSelector(action)),
    setDrawingItemSelected: (labelSelected, selectedDrawingItem, typeElement) =>
      dispatch(
        ObjectActionCreators.setDrawingItemSelected(
          labelSelected,
          selectedDrawingItem,
          typeElement
        )
      ),
    showPreviewSvgSketch: () => dispatch(onTogglePreviewSvgSketch(true)),
    setPreviewSvgSketchData: (data) => dispatch(setPreviewSvgSketchData(data)),
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    objId: state.objects.objId,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ObjFieldDrawingPad));
