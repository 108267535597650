export const colors = {
  br_blue: '#4874B4',
  br_blue2: '#4D77B6',
  br_blue3: '#5186BD',
  br_blue4: '#6496CF', // blue from logo on dark above
  br_blue5: '#5EB2DD', // blue from logo on dark below
  br_turq: '#46AEC8',
  br_darkgrey: '#555555',
  br_softblack: '#333333',
  br_secblue: '#A7B9D2',
  br_secblue2: '#ADBFD9',
  br_secblue3: '#B3C7E0',
  br_secblue4: '#E0E7EC',
  br_orange: '#F3692A',
  pr_yellow: '#F3B118',
  io_black: '#000000',
  io_grey: '#DDDDDD',
  io_grey_100: '#F3F4F6',
  io_grey_200: '#CCCCCC88',
  io_grey_300: '#DCDCDC',
  io_grey_400: '#AAA',
  io_grey_500: '#D4D4D4',
  io_grey_600: '#6F6F6F',
  io_white: '#FFFFFF',
  io_white_1: '#F6F6F6',
  io_white2: '#F2F4F7EE',
  io_white4: '#F2F3F5',
  //
  io_blue_blur: '#5E889E',
  io_blue_stone_100: '#102748',
  io_sky_blue: '#2975E2',
  io_sky_blue100: '#406AB5',
  io_blue_cornflower_100: '#6593D6',
  io_steel_blue: '#5D82B8',
  io_cornflower_blue: '#4D54E4',
  io_martinique: '#423C67',
  io_martinique_200: '#4B4858',
  io_martinique_300: '#2B2A30',
  io_danger: '#A51A1A',

  // palette 2
  // dark
  io_clay: '#1B2028',
  io_clay_100: '#28293C',
  io_clay_200: '#242C38',
  io_clay_300: '#3D4A5E',
  io_clay_400: '#2F3947',
  io_clay_500: '#414C5B',
  io_clay_600: '#7284A0',
  io_clay_700: '#647185',
  io_clay_800: '#3C4F6B',
  io_clay_900: '#1A212C',
  // light
  io_haze: '#EBEFF4',
  io_haze_100: '#647388',
  // palette 3
  // dark
  io_violet: '#413269',
  io_violet_100: '#393268',
  io_violet_200: '#1D164A',
  io_violet_300: '#251C5C',
  io_violet_400: '#3A3077',
  io_violet_500: '#6659B6',
  io_violet_600: '#372D72',
  io_violet_700: '#1D154E',
  io_violet_800: '#150E40',
  io_violet_900: '#5747BD',

  io_violet_blur_100: '#F4F2F9',
  io_violet_blur_200: '#DDD6EF',
  io_violet_blur_300: '#4419BB',
  io_violet_blur_400: '#F1EDFB',
  io_violet_blur_500: '#DFD9EF',
  io_violet_blur_600: '#534A8E',
  io_violet_blur_700: '#443794',

  //  palette 4
  io_green_100: '#141E18',
  io_green_200: '#162D1E',
  io_green_300: '#305541',
  io_green_400: '#173120',
  io_green_500: '#56886D',
  io_green_600: '#20402B',
  io_green_700: '#668F79',
  io_green_800: '#355741',
  io_green_900: '#39694A',
  io_green_1000: '#94AB9C',
  io_green_1100: '#0B2715',
  io_green_1200: '#F3FEF8',
  io_green_1300: '#25974E',
  io_green_1400: '#CCEAD8',
  io_green_1500: '#5B7263',
  io_green_1600: '#F0FEF6',
};

export const palette1 = {
  dark: {
    primary: [colors.io_clay_100, colors.br_darkgrey, colors.io_grey_600],
    secondary: [
      colors.io_martinique_300,
      colors.br_softblack,
      colors.io_grey_400,
    ],
    tertiary: [colors.io_martinique, colors.br_secblue, colors.br_secblue4],
  },
  light: {
    primary: [colors.io_white2, colors.br_secblue4, colors.io_sky_blue100],
    secondary: [colors.br_secblue4, colors.io_grey_500, colors.br_blue4],
    tertiary: [colors.io_martinique, colors.io_clay_100, colors.io_black],
  },
};

export const palette2 = {
  dark: {
    primary: [colors.io_clay_100, colors.br_darkgrey, colors.io_grey_600],
    secondary: [colors.io_clay, colors.io_clay_300, colors.io_clay_700],
    tertiary: [colors.io_steel_blue, colors.io_blue_blur, colors.br_secblue],
  },
  light: {
    primary: [colors.io_white2, colors.br_secblue4, colors.io_sky_blue100],
    secondary: [colors.br_secblue4, colors.io_grey_500, colors.br_blue2],
    tertiary: [
      colors.io_violet_blur_400,
      colors.br_secblue,
      colors.io_blue_cornflower_100,
    ],
  },
};
export const palette3 = {
  dark: {
    primary: [colors.io_violet_200, colors.io_violet_400, colors.io_violet_500],
    secondary: [
      colors.io_violet_blur_600,
      colors.io_violet_blur_200,
      colors.io_white4,
    ],
    tertiary: [
      colors.io_martinique_200,
      colors.io_violet_blur_500,
      colors.io_violet_blur_100,
    ],
  },
  light: {
    primary: [
      colors.io_white2,
      colors.io_violet_blur_200,
      colors.io_violet_500,
    ],
    secondary: [
      colors.io_violet_blur_100,
      colors.br_secblue4,
      colors.io_cornflower_blue,
    ],
    tertiary: [colors.io_haze, colors.io_violet_blur_600, colors.io_violet_900],
  },
};
export const palette4 = {
  dark: {
    primary: [colors.io_green_100, colors.io_green_200, colors.io_green_300],
    secondary: [colors.io_green_600, colors.io_green_500, colors.io_green_1200],
    tertiary: [
      colors.io_green_1000,
      colors.io_green_1400,
      colors.io_green_1600,
    ],
  },
  light: {
    primary: [colors.io_green_1600, colors.io_green_1400, colors.io_green_1000],
    secondary: [colors.io_white4, colors.io_green_700, colors.io_green_1500],
    tertiary: [colors.io_green_1200, colors.io_green_500, colors.io_green_800],
  },
};

export const colorsSvgDrawing = [
  '#000000',
  '#333333',
  '#FF9AA2',
  '#FFB7B2',
  '#FFDAC1',
  '#E2F0CB',
  '#B5EAD7',
  '#C7CEEA',
  '#5B4DB7',
  '#42ADC7',
  '#81D152',
  '#F5F263',
  '#ffffff',
];
