import produce from 'immer';
import { isEmpty } from 'lodash';
import { LIBRARY_TYPE, MODE_LIBRARY_ICON } from './type';

export const dataSession = [
  { value: 'images', label: 'Images' },
  { value: 'icons', label: 'Icons' },
  { value: 'svg', label: 'Template' },
];
export const iconsLibrarySession = { value: 'svg', label: 'Svg' };

const initialState = {
  icons: {
    images: { categories: {} },
    icons: { categories: {} },
    // svg: {
    //   categories: {
    //     all: {
    //       icon: '',
    //       icons: {},
    //       id: 'facef505-145f-47f5-9cca-5d39eef2f86a',
    //       name: 'All',
    //     },
    //   },
    // },
  },
  isLoadingPostData: false,
  isLoadingGetData: true,
  currentIconSelect: null,
  isShowModalIconsLibrary: false,
  dataSessionLibraryIcons: dataSession,
  isShowActionAddResource: true,
  callback: null,
  mode: MODE_LIBRARY_ICON.NONE,
};
export const iconsLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIBRARY_TYPE.SET_DATA_ICONS: {
      return produce(state, (draft) => {
        draft.icons = isEmpty(action.data) ? initialState.icons : action.data;
      });
    }

    case LIBRARY_TYPE.CHANGE_MODE_LIBRARY_ICON:
      return produce(state, (draft) => {
        draft.mode = action.mode;
      });

    case LIBRARY_TYPE.SET_LOADING_LOAD_DATA_ICONS_LIBRARY:
      return produce(state, (draft) => {
        draft.isLoadingGetData = action.status;
      });

    case LIBRARY_TYPE.SET_CURRENT_ICON_SELECT:
      return produce(state, (draft) => {
        draft.currentIconSelect = action.icon;
      });

    case LIBRARY_TYPE.CHANGE_STATUS_SHOW_MODAL_ICONS:
      return produce(state, (draft) => {
        draft.isShowModalIconsLibrary = action.status;
      });

    case LIBRARY_TYPE.CHANGE_SESSION_ICONS_LIBRARY:
      return produce(state, (draft) => {
        draft.dataSessionLibraryIcons = action.data;
      });

    case LIBRARY_TYPE.SET_ACTION_ADD_RESOURCE:
      return produce(state, (draft) => {
        draft.isShowActionAddResource = action.status;
      });
    case LIBRARY_TYPE.SET_CALLBACK_FUN_SELECT_ICON:
      return produce(state, (draft) => {
        draft.callback = action.callback;
      });

    default:
      return state;
  }
};
