export const isShowNodeContentSelector = (state) =>
  state?.responsive?.isShowNodeContent;

export const isMobileSelector = (state) => state?.responsive?.isMobile;
export const isTabletSelector = (state) => state?.responsive?.isTablet;
export const isMobileOrTabletSelector = (state) =>
  isMobileSelector(state) || isTabletSelector(state);
export const isScrollHorizontalSelector = (state) =>
  state?.responsive?.isScrollHorizontal;

export const directionScrollSelector = (state) => state?.responsive?.direction;
export const nodeFocusSelector = (state) => state?.responsive?.nodeFocus;
export const currentColumnSelector = (state) =>
  state?.responsive?.currentColumn;

export const isDragTabletSelector = (state) => state?.responsive?.isDragTablet;

export const arrayClassifTabletSelector = (state) =>
  state?.responsive?.arrayClassifTablet;
export const positionNodeDragSelector = (state) =>
  state?.responsive?.positionNodeDrag;

export const oldPositionNodeDragSelector = (state) =>
  state?.responsive?.oldPositionNodeDrag;

export const currentBandActiveSelector = (state) =>
  state?.responsive?.currentBandActive;

export const idOldNodeActiveSelector = (state) => state?.responsive?.idOldNode;
export const levelNodeAndBandActiveSelector = (state) =>
  state?.responsive?.levelNodeAndBandActive;

export const isResizePaneSelector = (state) => state?.responsive?.isResizePane;
export const nodeDragSelector = (state) => state?.responsive?.nodeDrag;
export const parentNodeDragIdSelector = (state) =>
  state?.responsive?.parentNodeDragId;
